import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { DefaultLayoutModule } from './layout/default/default.module';
import { MainLayoutModule } from './layout/main/main.module';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { GraphQLModule } from './modules/graphql/graphql.module';
import { HelperModule } from './modules/helper/helper.module';
import { LoadingModule } from './modules/loading/loading.module';
import { AppRoutingModule } from './modules/router/app-routing.module';
import { AlertModule } from './modules/ui-kit/alert/alert.module';
import { AcceptInviteModule } from './pages/accept-invite/accept-invite.module';
import { SlkAdminModule } from './pages/slk-admin/slk-admin.module';

import { ApiModule } from './pages/api/api.module';
import { CarsModule } from './pages/cars/cars.module';
import { ChatModule } from './pages/chat/chat.module';
import { FourZeroFourModule } from './pages/four-zero-four/four-zero-four.module';
import { LoginModule } from './pages/login/login.module';
import { LogsModule } from './pages/logs/logs.module';
import { ManageUsersModule } from './pages/manage-users/manage-users.module';
import { ProfileModule } from './pages/profile/profile.module';
import { MyScheduleModule } from './pages/schedule/pages/my-schedule/my-schedule.module';
import { ScheduleModule } from './pages/schedule/pages/schedule/schedule.module';
import { SettingsModule } from './pages/settings/settings.module';
import { SlkHrModule } from './pages/slk-hr/slk-hr.module';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    AuthorizationModule,
    LoadingModule,
    BrowserAnimationsModule,
    HelperModule,
    AcceptInviteModule,
    ChatModule,
    ManageUsersModule,
    LoginModule,
    ApiModule,
    ProfileModule,
    SettingsModule,
    LogsModule,
    CarsModule,
    AlertModule,
    MainLayoutModule,
    DefaultLayoutModule,
    SlkHrModule,
    SlkAdminModule,
    ScheduleModule,
    MyScheduleModule,
    FourZeroFourModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  declarations: [AppComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
