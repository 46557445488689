import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';

@Injectable()
export class AuthGuard {
  constructor(public auth: AuthorizationService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {
      const protection: ProtectionLevels = route.data['protection'];
      const error_href: string = route.data['error_href'];

      this.auth.getCurrentUser().subscribe({
        next: () => {
          if (protection === ProtectionLevels.AUTHORIZED) {
            observer.next(true);
          } else {
            this.router.navigate([error_href])
            observer.next(false);
          }
          observer.complete();
        },
        error: () => {
          if (protection === ProtectionLevels.UNAUTHORIZED) {
            observer.next(true);
          } else {
            this.router.navigate([error_href])
            observer.next(false);
          }
          observer.complete();
        }
      });
    })
  }
}
