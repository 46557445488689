import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewBlockList } from '../../constructor.abstract.service';
import { FormTemplates } from '../fields-control/fields-control.form.template';

interface BlocksModalData {
  createBlock: (type: FormTemplates) => void;
  blocks_preview: PreviewBlockList[];
}

@Component({
  selector: 'blocks-modal',
  templateUrl: './blocks-modal.component.html',
  styleUrls: ['./blocks-modal.component.scss'],
})
export class DialogModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BlocksModalData
  ) {}

  public close() {
    this.dialogRef.close();
  }

  public blocks: PreviewBlockList[] = this.data.blocks_preview;

  public createBlock({ type }: PreviewBlockList) {
    this.data.createBlock(type);
    this.close();
  }
}
