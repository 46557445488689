import { Pipe, PipeTransform } from '@angular/core';
import { ISchedulePicker } from '../components/day/time-picker/time-picker.component';
import { TimeTemplate } from '../../../entity/time-template.entity';

@Pipe({ name: 'check_time_period_range' })
export class CheckTimePeriodRangePipe implements PipeTransform {
  transform(time_period: TimeTemplate, working_hours: ISchedulePicker[]) {
    const customTime: ISchedulePicker[] = JSON.parse(time_period.value);

    const check_range = customTime.reduce((check: boolean[], time) => {
      const exist_range = working_hours.some((selected_time) => selected_time.work_from === time.work_from && selected_time.work_to === time.work_to);
      check.push(exist_range);
      return check;
    }, []);
    return !check_range.includes(false);
  }
}

