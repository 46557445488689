<div class="toolBar" *ngIf="selected_users_ids.length">
  <!-- <div class="pair blue Lexend-16" (click)="onTrash()">
    <edit-icon />
    <span>Edit</span>
  </div> -->

  <div class="pair red Lexend-16" (click)="deleteItem()">
    <delete-icon />
    <span>Delete</span>
  </div>
</div>
