import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Schedule } from 'src/app/entity/schedule.entity';
import { TimeComponent } from './time/time.component';

@Component({
  selector: 'day-of-week',
  templateUrl: './day-item.component.html',
  styleUrls: ['./day-item.component.scss'],
})
export class DayItemComponent {
  @Input() day: AbstractControl<Schedule, Schedule>;
  @Input() index: number;
  @Input() form: FormGroup;

  @ViewChild('open_at_ref') open_at_ref: TimeComponent;
  @ViewChild('close_at_ref') close_at_ref: TimeComponent;

  get name() {
    return this.day.value.name;
  }

  get open_at() {
    return this.day.value.open_at;
  }

  get close_at() {
    return this.day.value.close_at;
  }

  get is_schedule() {
    return !!this.open_at && !!this.close_at;
  }

  get current_day() {
    return (this.form.get('schedules') as FormArray).at(this.index);
  }

  public unsetDay() {
    this.current_day.setValue({
      ...this.day.value,
      open_at: '',
      close_at: '',
    });
  }

  public outsideOpenAt() {
    this.open_at_ref.open = false;
    this.close_at_ref.open = false;

    if (
      (!!this.open_at || !!this.close_at_ref) &&
      (this.open_at_ref.open || this.close_at_ref.open)
    ) {
      this.unsetDay();
    }
    setTimeout(() => {
      if (this.open_at && !this.close_at && !this.close_at_ref.open) {
        this.unsetDay();
      }
    });
  }

  public handleChange(e: MatSlideToggleChange) {
    e.source.checked = !!this.open_at && !!this.close_at;
  }

  public includeDay() {
    if (!(!!this.open_at && !!this.close_at)) {
      if (this.open_at_ref.open) {
        this.open_at_ref.open = false;
      } else {
        setTimeout(() => {
          this.open_at_ref.open = true;
        });
      }
    } else {
      this.unsetDay();
    }
  }

  public setOpenAt(open_at?: string) {
    setTimeout(() => {
      this.close_at_ref.open = true;
      this.current_day.setValue({
        ...this.day.value,
        open_at,
      });
      this.open_at_ref.open = false;
    });
  }

  public setCloseAt(close_at: string) {
    setTimeout(() => {
      this.current_day.setValue({
        ...this.day.value,
        close_at,
      });
      this.close_at_ref.open = false;
    });
  }
}
