import { NgModule } from '@angular/core';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MainLayoutComponent } from './main.component';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BurgerButtonComponent } from './side-bar/burger-button/burger-button.component';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { AccordionComponent } from './side-bar/accordion/accordion.component';

@NgModule({
  imports: [IconsModule, CommonModule, RouterModule, DirectiveModule],
  declarations: [
    SideBarComponent,
    MainLayoutComponent,
    BurgerButtonComponent,
    AccordionComponent,
  ],
  exports: [MainLayoutComponent],
})
export class MainLayoutModule {}
