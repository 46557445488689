import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { LoadingModule } from 'src/app/modules/loading/loading.module';

import { ConstructorComponent } from '../../../common/constructor/component/constructor.component';
import { ConstructorModule } from '../../../common/constructor/constructor.module';
import { AllowedTypeConstructedPageGuard } from '../../../common/constructor/guard/allowed-type-constructed-page.guard';
import { ConstructedPageService } from './services/constructed-page.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    LoadingModule,
    ConstructorModule.inversion(ConstructedPageService),
    RouterModule.forChild([
      {
        path: 'slk-admin/:company-id/:type/create-new',
        loadChildren: () =>
          import('./create-constructed-page.module').then(m => m.CreateConstructedPageModule)
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConstructorComponent,
            data: { headline: 'Create new ' },
          },
        ],
        canActivate: [AuthGuard, RoleGuard, AllowedTypeConstructedPageGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [],
  providers: [],
})
export class CreateConstructedPageModule { }
