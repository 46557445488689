import { gql } from "apollo-angular";

export const CREATE_REQUEST_FOR_CAR_ITEMS = gql`
  mutation createRequestForCarItems($createUpdateCarDto: [CreateUpdateCarDto!]!) {
    createRequestForCarItems(createUpdateCarDto: $createUpdateCarDto) {
      id
      item_template_id
      approved_quantity
      requested_quantity
      submitted_quantity
    }
  }
`

export interface ICreateRequestForCarItemsDto {
  createUpdateCarDto: {
    item_template_id: string;
    requested_quantity: number;
  }[]
}

export interface ICreateRequestForCarItemsResponse {
  createRequestForCarItems: {
    id: string;
    item_template_id: string;
    requested_quantity: number;
    submitted_quantity: number;
    approved_quantity: number;
  }[];
}
