<div class="wrap">
  <div class="title_wrap">
    <p class="Lexend-12 grey light">Name:</p>

    <div class="details" (click)="open = !open">
      <p class="Lexend-14 regular details blue">Details</p>

      <blue-arrow class="arrow" [attr.data-open]="open" />
    </div>
  </div>

  <p class="Lexend-18 company_name">{{ company.name }}</p>

  <div class="link" *ngIf="company.url">
    <a
      [href]="'https://surelockkey.com/find-a-locksmith/' + company.url"
      target="_blank"
      class="Lexend-18 blue"
      >id link</a
    >
    <link-icon />
  </div>

  <full-info class="ful_info" [attr.data-open]="open" [company]="company" />

  <div class="btn_wrap">
    <a [href]="routerLink">
      <button mat-raised-button>{{ btn_name }}</button>
    </a>
    <button mat-button color="warn" class="red-color" (click)="openDialog()">
      Delete
    </button>
  </div>
</div>
