<form (ngSubmit)="onSubmit()" class="form" [formGroup]="form">
  <invite-roles />

  <mat-form-field *ngIf="!editUser">
    <mat-label>Email address</mat-label>
    <input
      matInput
      type="text"
      placeholder="Type email address here"
      formControlName="email"
      [matAutocomplete]="auto"
    />

    <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
      Please enter a valid email address
    </mat-error>
    <mat-error *ngIf="email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let user of filteredWorkizUsers | async"
        [value]="user.email"
        (onSelectionChange)="onAutocompleteChange(user)"
      >
        {{ user.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Workiz ID
      <span class="optional">(optional)</span>
    </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type workiz_id here"
      formControlName="workiz_id"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Full Name
      <span class="optional">(optional)</span>
    </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type name here"
      formControlName="name"
    />
  </mat-form-field>

  <app-states *ngIf="openStateList" [role]="role.value" />

  <button mat-raised-button type="submit" [disabled]="form.invalid || loading">
    {{ !editUser ? "Invite" : "Save" }}
  </button>
</form>
