import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { IGetAllJobsWorkizResponse } from '../../../apollo/query/get-all-jobs-workiz.query';

@Component({
  selector: 'workiz-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class WorkizTableComponent implements OnInit {
  public technician: IGetAllJobsWorkizResponse['getAllJobsWorkiz'] = { items: [], has_more: true };

  public displayedColumns: string[] = [
    'Job ID',
    'Dispatcher',
    'Name',
    'Address',
    'Phone',
    'Job Type',
    'Tech',
    'Date',
  ];

  constructor(private readonly apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService
      .getAllJobsWorkiz({ offset: 1, records: 50 })
      .subscribe((res) => (this.technician = res));
  }

  public loadMore() {
    this.apiService
      .getAllJobsWorkiz({
        offset: (this.technician.items.length % 49) + 1,
        records: 50,
      })
      .subscribe(({ items, has_more }) => {
        this.technician = {
          items: [...this.technician.items, ...items],
          has_more,
        };
      });
  }
}
