<div class="wrap">
  <table mat-table [dataSource]="items" class="mat-elevation-z8">
    <caption class="caption Lexend-18 darck-blue start">
      {{
        date | date : "EEEE, MMMM d, y, h:mm:ss a"
      }}
    </caption>

    <ng-container matColumnDef="uhs_sku">
      <th mat-header-cell *matHeaderCellDef>UHS SKU</th>
      <td mat-cell *matCellDef="let element" data-name="UHS SKU">
        {{ element.item_template.uhs_sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef>S.L.K. SKU</th>
      <td mat-cell *matCellDef="let element" data-name="S.L.K. SKU">
        {{ element.item_template.sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="requested_quantity">
      <th mat-header-cell *matHeaderCellDef>Requested quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Requested quantity">
        <p
          [ngClass]="{
        correct: element.approved_quantity === element.requested_quantity,
        incorrect: element.approved_quantity !== element.requested_quantity,
      }"
        >
          {{ element.requested_quantity }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="approved_quantity">
      <th mat-header-cell *matHeaderCellDef>Approved quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Approved quantity">
        <p
          [ngClass]="{
          correct: element.approved_quantity === element.requested_quantity,
          incorrect: element.approved_quantity !== element.requested_quantity,
        }"
        >
          {{ element.approved_quantity }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="waiting for consideration">
      <th mat-header-cell *matHeaderCellDef>Submitted quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Submitted quantity">
        <mat-form-field>
          <input
            matInput
            type="number"
            [defaultValue]="element.submitted_quantity"
            placeholder="Type quantity here"
            ariaAutoComplete="off"
          />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="submitted">
      <th mat-header-cell *matHeaderCellDef>Submitted quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Submitted quantity">
        {{ element.submitted_quantity }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <button *ngIf="!isSubmitted" mat-raised-button (click)="onSubmit()">
    Submit
  </button>
</div>
