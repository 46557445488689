import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'block-with-image-fields',
  templateUrl: './block-with-image-fields.component.html',
  styleUrls: ['./block-with-image-fields.component.scss'],
})
export class BlockWithImageFieldsComponent {

  constructor(
    public readonly controlContainer: ControlContainer
  ) { }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }
}
