import { Component } from '@angular/core';
import { NavigationAbstractService } from './navigation.abstract.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  constructor(
    public readonly service: NavigationAbstractService
  ) { }

  public setNextWeekIndex(week_index: number) {
    this.service.setWeekIndex(week_index);
  }

  public get disabled() {
    return this.service.disabled
  }
}
