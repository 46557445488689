<div class="wrap">
  <a [routerLink]="templateDto.edit_path + templateDto.id">
    <div class="pen" data-button>
      <edit-icon />
    </div>
  </a>
  <div class="cross" data-button (click)="removeItem(templateDto.id)">
    <red-cross />
  </div>

  <div>
    <img class="img" src="{{ templateDto.url }}" alt="" />
  </div>
  <div class="text_wrap">
    <p class="Lexend-16 semi-bold title">
      {{ templateDto.name }}
    </p>
    <p class="Lexend-16 description" [innerHTML]="templateDto.description"></p>
  </div>
  <button
    mat-raised-button
    class="button"
    *ngIf="!templateDto.is_posted; else unpublish"
    (click)="onPublish(true)"
    data-button
  >
    <upload-without-outline />
    Publish
  </button>
  <ng-template #unpublish>
    <button
      data-button
      mat-raised-button
      class="button"
      (click)="onPublish(false)"
    >
      <lock-icon />
      Unpublish
    </button>
  </ng-template>
</div>
