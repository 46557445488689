import { gql } from "apollo-angular";
import { TimeTemplate } from "src/app/entity/time-template.entity";

export const UPDATE_TIME_TEMPLATE = gql`
  mutation updateTimeTemplate($time_template: UpdateTimeTemplateDto!) {
    updateTimeTemplate(time_template: $time_template) {
      id
      name
      value
      color
    }
  }
`


export interface IUpdateTimeTemplateDto {
  time_template: TimeTemplate
}

export interface IUpdateTimeTemplateResponse {
  updateTimeTemplate: TimeTemplate
}
