<div
  class="backdrop"
  [attr.data-is-open]="is_open"
  (click)="handleClose()"
></div>

<div class="dialog" [attr.data-is-open]="is_open">
  <double-arrow-icon class="closeArrow" (click)="handleClose()" />
  <div class="menu_head">
    <p class="back Lexend-16 blue" (click)="handleOnBack()" *ngIf="show_back">
      <arrow-icon class="arrow-icon" />
      Back
    </p>

    <h2 class="Lexend-24 regular dark-blue">{{ headline }}</h2>
  </div>

  <div class="content">
    <ng-content />
  </div>
</div>
