<a id="ourServices" class="anchor"></a>
<div class="services">
  <div class="servicesBody">
    <p class="text" [innerHTML]="data.description"></p>
    <ul class="coverListServices">
      <li class="listItem" *ngFor="let item of list">
        {{ item }}
      </li>
    </ul>
  </div>
</div>
