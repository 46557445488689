<div class="wrap">
  <h1>Review technician approve</h1>
  <mat-tab-group animationDuration="0">
    <mat-tab label="Waiting for consideration">
      <div class="wrap-tables">
        <approved-inventory-table
          *ngFor="let request of formatted_request | keyvalue"
          [date]="+request.key * 1000"
          [items]="request.value"
          [tab]="'waiting for consideration'"
        />
      </div>
    </mat-tab>
    <mat-tab label="Submitted">
      <div class="wrap-tables">
        <approved-inventory-table
          *ngFor="let request of formatted_submitted | keyvalue"
          [date]="+request.key * 1000"
          [items]="request.value"
          [tab]="'submitted'"
        />
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
