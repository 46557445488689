import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CtmCall } from 'src/app/entity/ctm-call.entity';
import { ApiService } from 'src/app/pages/api/api.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  public text: string;
  public showSpeech: boolean = false;
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<TabsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { call: CtmCall },
    private readonly apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.apiService.findTranscription(this.data.call.id).subscribe({
      next: ({ text }) => {
        this.text = text
      },
      error: () => { }
    })
  }

  public speechToText() {
    this.apiService.speechToText(this.data.call.id)
      .subscribe(({ data, loading }) => {
        this.loading = loading;

        if (data) {
          this.text = data?.speechToText.text!;
        }
      })
  }

  public onClickBack() {
    this.showSpeech = false
  }

  public onClose() {
    this.dialogRef.close();
  }
}
