import { Component, Input } from '@angular/core';
import { Reviews } from 'src/app/entity/reviews.entity';
import { ReviewsStatus } from 'src/app/enum/reviews-status.enum';

@Component({
  selector: './reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent {
  @Input() reviews: Reviews[];

  public loading: boolean;
  public reviewsUnderConsideration: Reviews[];
  public reviewsApproved: Reviews[];

  get quantitiReviewsWaiting() {
    return this.reviewsUnderConsideration?.length || 0;
  }

  get quantitiReviewsPublished() {
    return this.reviewsApproved?.length || 0;
  }

  ngOnChanges(): void {
    this.reviewsUnderConsideration = this.reviews.filter(
      (r) => r.status === ReviewsStatus.UNDER_CONSIDERATION
    );
    this.reviewsApproved = this.reviews.filter(
      (r) => r.status === ReviewsStatus.APPROVED
    );
  }
}
