import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  ILoginDto,
  ILoginResponse,
  LOGIN,
} from './apollo/mutation/login.mutation';
import {
  GET_CURRENT_USER,
  IGetCurrentResponse,
} from './apollo/query/get-current-user.query';
import {
  ILogoutDto,
  ILogoutResponse,
  LOGOUT,
} from './apollo/mutation/logout.mutation';
import { Router } from '@angular/router';
import {
  ACCEPT_INVITE,
  IAcceptInviteDto,
  IAcceptInviteResponse,
} from './apollo/mutation/accept-invite.mutation';
import { AlertService } from '../ui-kit/alert/alert.service';

@Injectable()
export class AuthorizationService {
  constructor(
    private readonly apollo: Apollo,
    private readonly router: Router,
    private alertService: AlertService
  ) { }

  public getCurrentUser() {
    return this.apollo.query<IGetCurrentResponse, {}>({ query: GET_CURRENT_USER });
  }

  public login(email: string, password: string) {
    return this.apollo
      .mutate<ILoginResponse, ILoginDto>({
        mutation: LOGIN,
        variables: {
          login_dto: {
            email,
            password,
          },
        },
      })
      .subscribe({
        next: ({ data, loading }) => {
          if (data && !loading) {
            localStorage.setItem('access_token', data.login.access_token);
            localStorage.setItem('refresh_token', data.login.refresh_token);
            this.router.navigate(['profile']);
          }
        },
        complete: () => this.alertService.alert({
          message: 'Welcome',
          type: 'success',
        }),
        error: () => {
          this.alertService.alert({
            message: 'Oops wrong email or password',
            type: 'error',
          });
        },
      });
  }

  public logout() {
    const refresh_token = localStorage.getItem('refresh_token');
    if (refresh_token) {
      this.apollo
        .mutate<ILogoutResponse, ILogoutDto>({
          mutation: LOGOUT,
          variables: { refresh_token },
        })
        .subscribe((r) => {
          if (r.data) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            this.apollo.client.clearStore();
            this.router.navigate(['']);
          }
        });
    }
  }

  public acceptInvitation(
    accept_invite_dto: IAcceptInviteDto['accept_invite_dto']
  ) {
    this.apollo
      .mutate<IAcceptInviteResponse, IAcceptInviteDto>({
        mutation: ACCEPT_INVITE,
        variables: {
          accept_invite_dto,
        },
      })
      .subscribe({
        next: ({ data, loading }) => {
          if (data && !loading) {
            this.login(data!.acceptInvite.email, accept_invite_dto.password);
          }
        },
        error: () => {
          this.alertService.alert({
            message: 'Oops something went wrong',
            type: 'error',
          });
        },
      });
  }
}
