import { Component, Input, OnInit } from '@angular/core';
import { CarsService } from '../../../cars.service';
import { IFindUpdateCarRequests } from '../../../apollo/query/find-update-car-requests.query';

@Component({
  selector: 'approved-inventory-table',
  templateUrl: './approved-inventory-table.component.html',
  styleUrls: ['./approved-inventory-table.component.scss']
})
export class ApprovedInventoryTableComponent implements OnInit {
  public displayedColumns: string[] = ['sku', 'uhs_sku', 'requested_quantity', 'approved_quantity'];

  @Input() items: IFindUpdateCarRequests[];
  @Input() date: number;
  @Input() tab: 'submitted' | 'waiting for consideration';

  constructor(
    private readonly carsService: CarsService,
  ) { }

  ngOnInit(): void {
    this.displayedColumns.push(this.tab)
  }
  get isSubmitted() {
    return this.tab === 'submitted'
  }

  public onSubmit() {
    const submit_data = this.items.map(({ id, submitted_quantity }) => ({ id, submitted_quantity }));
    this.carsService.submitRequestForCarItems(submit_data)
  }

}
