<wrapper-loading [loading]="loading">
  <div class="content">
    <div class="title_wrap">
      <p class="Lexend-24">{{ headline }} {{ type | lowercase }}</p>
      <constructor-save-icon (click)="onSave()" class="pointer" />
    </div>
    <div class="content">
      <fields-control />
      <app-blocks />
    </div>
  </div>
</wrapper-loading>
