import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'prices-block-fields',
  templateUrl: './prices-block-fields.component.html',
  styleUrls: ['./prices-block-fields.component.scss'],
})
export class PricesBlockFieldsComponent {

  constructor(
    public readonly controlContainer: ControlContainer
  ) { }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }

}
