<div class="delete-user">
  <p class="Lexend-18 medium dark-blue">
    Are you sure you want to delete this {{ data.title || "information" }}?
  </p>

  <p class="Lexend-14 regular grey">
    Once deleted, this {{ data.title || "information" }} no longer be
    accessible.
  </p>

  <cross-icon class="cross" (click)="onClose()" />

  <div class="buttons">
    <button mat-raised-button type="button" class="red" (click)="onDelete()">
      Delete
    </button>
    <button mat-button type="button" class="cancel" (click)="onClose()">
      Cancel
    </button>
  </div>
</div>
