<div class="head">
  <h1>My schedule</h1>
  <navigation />
  <schedule-calendar />
</div>

<mat-tab-group animationDuration="0">
  <mat-tab label="Current">
    <wrapper-loading [loading]="loading">
      <div class="days" *ngIf="user_with_schedule">
        <div
          class="day"
          *ngFor="let day of days_name | keyvalue"
          [ngClass]="day.key"
        >
          <p class="Lexend-24 day-name">{{ day.key }}</p>

          <group-ranges [day]="schedules_formatted?.[day.key] || []" />
        </div>
      </div>
    </wrapper-loading>
  </mat-tab>
  <mat-tab label="Requested">
    <wrapper-loading [loading]="loading">
      <div class="days" *ngIf="user_with_schedule">
        <div
          class="day"
          *ngFor="let day of days_name | keyvalue"
          [ngClass]="day.key"
        >
          <p class="Lexend-24 day-name">{{ day.key }}</p>

          <day
            [title_name]="day.key"
            [dataName]="day.key"
            [day]="schedule_requests_formatted?.[day.key] || []"
            [dayIndex]="day.value"
            [user]="user_with_schedule"
          />
        </div>
      </div>
    </wrapper-loading>
  </mat-tab>
</mat-tab-group>
