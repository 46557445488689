import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Deep Omit declaration

/** Deeply omit members of an array of interface or array of type. */

declare global {
  type Primitive = string | Function | number | boolean | Symbol | undefined | null;

  type DeepOmitArray<T extends any[], K> = {
    [P in keyof T]: DeepOmit<T[P], K>;
  };

  type DeepOmit<T, K> = T extends Primitive ? T : {
    [P in Exclude<keyof T, K>]: //extra level of indirection needed to trigger homomorhic behavior
    T[P] extends infer TP ? // distribute over unions
    TP extends Primitive ? TP : // leave primitives and functions alone
    TP extends any[] ? DeepOmitArray<TP, K> : // Array special handling
    DeepOmit<TP, K>
    : never
  };
}
