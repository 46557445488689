import { Component, Input } from '@angular/core';
import { ControlContainer, FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'address-inp',
  templateUrl: './address-inp.component.html',
  styleUrls: ['./address-inp.component.css'],
})
export class addressInpComponent {
  @Input() form: FormGroup;
  constructor(public readonly controlContainer: ControlContainer) {}

  addressError(index: number) {
    return (this.form.get('address') as FormArray)
      .at(index)
      .hasError('required');
  }

  get addresses() {
    return (<FormArray>this.form.get('address')).controls;
  }

  public deleteAddress(i: number) {
    (<FormArray>this.form.get('address')).removeAt(i);
  }
}
