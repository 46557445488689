<div class="styles-wrap" [formGroup]="$any(control)">
  <p class="title">Styles</p>

  <div class="styles">
    <div class="styles">
      <mat-slide-toggle formControlName="add_top_need_more_info_button">
        add_top_need_more_info_button
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="add_bottom_need_more_info_button">
        add_bottom_need_more_info_button
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="first_list_number_list">
        first_list_number_list
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="main_list_number_list">
        main_list_number_list
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="last_list_number_list">
        last_list_number_list
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="body_grid_revers">
        body_grid_revers
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="turn_padding_top">
        turn_padding_top
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="turn_padding_bottom">
        turn_padding_bottom
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="first_text_grid_revers">
        first_text_grid_revers
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="body_text_grid_revers">
        body_text_grid_revers
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="last_text_grid_revers">
        last_text_grid_revers
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="background_exists">
        background_exists
      </mat-slide-toggle>
    </div>

    <div class="styles number-input">
      <mat-form-field>
        <mat-label> first_list_grid_quantity_columns </mat-label>
        <input
          matInput
          type="number"
          placeholder="Type here"
          formControlName="first_list_grid_quantity_columns"
        />
      </mat-form-field>
    </div>

    <div class="styles number-input">
      <mat-form-field>
        <mat-label> main_list_grid_quantity_columns </mat-label>
        <input
          matInput
          type="number"
          placeholder="Type here"
          formControlName="main_list_grid_quantity_columns"
        />
      </mat-form-field>
    </div>

    <div class="styles number-input">
      <mat-form-field>
        <mat-label> last_list_grid_quantity_columns </mat-label>
        <input
          matInput
          type="number"
          placeholder="Type here"
          formControlName="last_list_grid_quantity_columns"
        />
      </mat-form-field>
    </div>
  </div>
</div>
