<div class="wrap">
  <div class="cars-head">
    <h1 class="Lexend-32 regular">Cars</h1>
    <button
      mat-raised-button
      (click)="handleCreateTemplate()"
      *ngIf="current_tab === 'Templates'"
    >
      Create template
    </button>
  </div>

  <mat-tab-group
    animationDuration="0"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab label="Containers">
      <wrapper-loading [loading]="loading">
        <app-containers />
      </wrapper-loading>
    </mat-tab>
    <mat-tab label="Templates">
      <app-templates (onUpdateTemplate)="update_template = $event" />
    </mat-tab>
  </mat-tab-group>

  <app-side-menu
    [is_open]="create_template || !!update_template"
    headline="Create Template"
    (onClose)="handleCreateTemplate()"
  >
    <app-template-modal
      [update_template]="update_template"
      (onClose)="handleCreateTemplate()"
    />
  </app-side-menu>

  <app-bulk-action *ngIf="show_bulk_action" />
</div>
