import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IFindUpdateCarRequests } from '../../../apollo/query/find-update-car-requests.query';
import { ConfirmInventoryTabs } from '../confirm-inventory.component';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { Apollo } from 'apollo-angular';
import { CarsService } from '../../../cars.service';

@Component({
  selector: 'technician-approve-inventory-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, OnInit {
  public displayedColumns: string[] = ['slk_sku', 'uhs_sku', 'difference'];
  @Input() items: IFindUpdateCarRequests[];
  @Input() date: number;
  @Output() itemsChange = new EventEmitter<IFindUpdateCarRequests[]>();
  @Input() column: ConfirmInventoryTabs;

  public filtered_items: IFindUpdateCarRequests[];

  public id: string | undefined;

  constructor(
    private readonly alertService: AlertService,
    private readonly carsService: CarsService,

  ) { }

  ngOnChanges(): void {
    this.filtered_items = this.items.filter(item => item.tab === this.column)
  }

  ngOnInit(): void {
    this.displayedColumns.push(this.column)
  }

  public changeValue(id: string, el: HTMLElement) {
    if (id !== this.id) return

    const { valueAsNumber: requested_quantity } = el as HTMLInputElement;
    this.carsService.setQuantityForUpdateCarRequest(id, requested_quantity);
    this.id = undefined;

  }

  public focus(id: string) {
    this.id = id
  }

  public onDelete(id: string) {
    this.carsService.setTabForUpdateCarRequest(id, ConfirmInventoryTabs.WAITING_FOR_CONSIDERATION);

    this.alertService.alert({ message: "This key have sent to tab 'waiting for consideration'", type: "success" })
  }

  public onApprove(id: string) {
    this.carsService.setTabForUpdateCarRequest(id, ConfirmInventoryTabs.APPROVED);

    this.alertService.alert({ message: "This key have sent to tab 'Approved'", type: "success" });
  }
}
