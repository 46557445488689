import { Component, OnInit } from '@angular/core';
import { CarsService } from '../../cars.service';
import { IFormatUpdateCarRequest } from '../../apollo/query/find-update-car-requests.query';

@Component({
  selector: 'app-approved-inventory',
  templateUrl: './approved-inventory.component.html',
  styleUrls: ['./approved-inventory.component.scss']
})
export class ApprovedInventoryComponent implements OnInit {
  public formatted_request: IFormatUpdateCarRequest;
  public formatted_submitted: IFormatUpdateCarRequest;

  constructor(
    private readonly carsService: CarsService,
  ) { }

  ngOnInit(): void {
    this.carsService.findApprovedUpdateCarRequests().subscribe((formatted_request) => {

      this.formatted_request = formatted_request
    })

    this.carsService.findSubmittedUpdateCarRequests().subscribe((formatted_submitted) => {
      this.formatted_submitted = formatted_submitted
    })
  }


}
