import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { OpenAiFineTune } from "src/app/entity/open-ai-fine-tune.entity";
import { TeachingService } from "src/app/pages/chat/teaching.service";


@Component({
  selector: 'last-teaching-dialog',
  templateUrl: './last-teaching-dialog.html',
  styleUrls: ['./last-teaching-dialog.scss'],
})
export class LastTeachingDialog implements OnInit {
  public fullLastFineTune: OpenAiFineTune | undefined;

  constructor(
    private readonly teachingService: TeachingService,
    private dialogRef: MatDialogRef<void>,
  ) {}

  ngOnInit(): void {
    this.teachingService.getFullLastFineTune().subscribe({
      next: (value) => {
        this.fullLastFineTune = value;
      },
    });
  }

  public onClose() {
    this.dialogRef.close();
  }
}
