import { gql } from "apollo-angular";
import { ConstructorBlog } from "src/app/entity/blog/constructor-blog.entity";

export const GET_BLOG_BY_META_BLOG_URL = gql`
query getBlogsFilterByMetaBlogUrl($metaBlogUrl: String!) {
  getBlogsFilterByMetaBlogUrl(metaBlogUrl: $metaBlogUrl) {
    id
    is_posted
    metaInfo {
      url_for_blog
    }
    preview_info {
      photo {
        link
        alt
        title
      }
      title
      description
      date
    }
  }
}
`

export interface IGetBlogsFilterByMetaBlogUrlResponse {
  getBlogsFilterByMetaBlogUrl: ConstructorBlog;
}

export interface IGetBlogsFilterByMetaBlogUrlDto {
  metaBlogUrl: string;
}
