import { Component } from '@angular/core';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { MyScheduleService } from './services/my-schedule.service';

@Component({
  selector: 'app-my-schedule',
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.scss']
})
export class MyScheduleComponent {
  public user_with_schedule: UserWithSchedule;
  public loading = true;
  public open_calendar = false;

  constructor(
    private readonly myScheduleService: MyScheduleService
  ) {
    this.myScheduleService.getMySchedule().subscribe(({data, networkStatus}) => {
      this.user_with_schedule = data;
      this.myScheduleService.disabled = !(networkStatus === 7);
      this.loading = false;
    });
  }

  public get days_name() {
    return this.myScheduleService.days_name
  }

  public get schedules_formatted() {
    return this.user_with_schedule?.schedules_formatted
  }

  public get schedule_requests_formatted() {
    return this.user_with_schedule?.schedule_requests_formatted
  }

  public get startWeek() {
    return this.myScheduleService.startWeek
  }
  public get endWeek() {
    return this.myScheduleService.endWeek
  }
  public get weekStartDate() {
    return this.myScheduleService.weekStartDate
  }
  public get weekEndDate() {
    return this.myScheduleService.weekEndDate
  }

  public setNextWeekIndex(week_index: number) {
    this.myScheduleService.setWeekIndex(week_index);
  }

  public setCalendarIndex({ start }: { start: moment.Moment, end: moment.Moment }) {
    const calc_current_week = start.clone().startOf('isoWeek').week() - this.myScheduleService.currentWeek.clone().startOf('isoWeek').week();

    this.setNextWeekIndex(calc_current_week);
    this.toggleOpenCalendar(false)
  }

  public toggleOpenCalendar(state: boolean) {
    this.open_calendar = state
  }
}
