<!-- <dropdown
  name="About city"
  [show_remove_icon]="true"
  (removeItem)="removeBlock()"
> -->
<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Headline </mat-label>
    <input matInput type="text" placeholder="Type headline here" formControlName="headline" />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Description </mat-label>
    <textarea cdkTextareaAutosize matInput placeholder="Type description here" formControlName="description"></textarea>
  </mat-form-field>
</form>
<!-- </dropdown> -->