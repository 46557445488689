import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { IconsModule } from "../../../../modules/icons/icons.module";
import { CalendarScheduleModule } from '../../components/calendar/calendar.module';
import { DayModule } from '../../components/day/day.module';
import { NavigationModule } from '../../components/navigation/navigation.module';
import { DropDownComponent } from '../../components/search-bar/filters/drop-down/drop-down.component';
import { FiltersComponent } from '../../components/search-bar/filters/filters.component';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { InfoComponent } from '../../components/table/info/info.component';
import { NoteComponent } from '../../components/table/note/note.component';
import { TableComponent } from '../../components/table/table.component';
import { ScheduleComponent } from './schedule.component';
import { ChangeDateService } from './services/change-date.service';
import { DayScheduleService } from './services/day-schedule.service';
import { ScheduleService } from './services/schedule.service';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatTabsModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    SideMenuModule,
    MatInputModule,
    MatButtonModule,
    DirectiveModule,
    MatSlideToggleModule,
    MatMenuModule,
    LoadingModule,
    DayModule.inversion(DayScheduleService),
    NavigationModule.inversion(ChangeDateService),
    CalendarScheduleModule.inversion(ChangeDateService),
    RouterModule.forChild([
      {
        path: 'schedule',
        loadChildren: async () => (await import('./schedule.module')).ScheduleModule,
      },
      {
        path: '',
        title: 'Schedules | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ScheduleComponent
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.schedule,
        },
      },
    ])
  ],
  declarations: [
    ScheduleComponent,
    TableComponent,
    InfoComponent,
    SearchBarComponent,
    FiltersComponent,
    NoteComponent,
    DropDownComponent,
  ],
  providers: [
    ScheduleService,
    // DayScheduleService,
    ChangeDateService,
    // {
    //   provide: DayAbstractService,
    //   useClass: DayScheduleService,
    // }
  ],
})

export class ScheduleModule {}
