import { gql } from 'apollo-angular';

export const DELETE_CONSTRUCTED_PAGE_COMPANY = gql`
  mutation deleteConstructedPageCompany($id: ID!) {
    deleteConstructedPageCompany(id: $id)
  }
`;

export interface IDeleteConstructedPageCompanyDto {
  id: string;
}
export interface IDeleteConstructedPageCompanyResponse {
  deleteConstructedPageCompany: string;
}
