import { gql } from 'apollo-angular';

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($user: UpdateCurrentUserDto!) {
    updateCurrentUser(user: $user) {
      email
      id
      name
      role
    }
  }
`;

export interface IUpdateCurrentUserDto {
  user: {
    name: string;
  };
}
export interface IUpdateCurrentUserResponse {
  updateCurrentUser: {
    name: string;
  };
}
