<button
  mat-raised-button
  name="name"
  [disabled]="is_parsing"
  (click)="onParseData()"
>
  Parse data
</button>

<app-side-menu
  headline="parse data"
  [is_open]="is_open"
  (onClose)="onParseData()"
>
  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <mat-label>Select account</mat-label>
      <mat-select formControlName="account">
        <mat-option value="main">Main</mat-option>
        <mat-option value="arizona">Arizona</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Month and Year</mat-label>
      <input
        matInput
        [matDatepicker]="dp"
        formControlName="year_month"
        min="2018-01-01"
        [max]="max"
      />
      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="year"
        (monthSelected)="setMonthAndYear($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>

    <button [disabled]="false" mat-raised-button type="submit">Parse</button>
  </form>
</app-side-menu>
