import { gql } from "apollo-angular";

export const SUBMIT_REQUEST_FOR_CAR_ITEMS = gql`
  mutation submitRequestForCarItems($submitUpdateCarDto: [SubmitUpdateCarDto!]!) {
    submitRequestForCarItems(submitUpdateCarDto: $submitUpdateCarDto) {
      id
    }
  }
`;


export interface ISubmitRequestForCarItemsDto {
  submitUpdateCarDto: {
    id: string;
    submitted_quantity: number;
  }[]
}

export interface ISubmitRequestForCarItemsResponse {
  submitRequestForCarItems: {
    id: string;
    requested_at: number;
    requested_quantity: number;
    submitted_quantity: number;
    approved_quantity: number;
  }[]
}
