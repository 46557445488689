import { Component, Input } from '@angular/core';
import { UserWithScheduleFormatted } from 'src/app/entity/schedule/user-formatted.entity';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { DayAbstractService } from '../day-abstract.service';

@Component({
  selector: 'group-ranges',
  templateUrl: './group-ranges.component.html',
  styleUrls: ['./group-ranges.component.scss']
})
export class GroupRangesComponent {
  @Input() day: UserWithScheduleFormatted[''];
  public time_periods: TimeTemplate[] = [];

  constructor(
    private readonly scheduleService: DayAbstractService,
  ) {
    this.scheduleService.getAllTimeTemplates()
      .subscribe((time_periods) => {
        this.time_periods = time_periods;
      });
  }

}
