import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-switch-list',
  templateUrl: './switch-list.component.html',
  styleUrls: ['./switch-list.component.scss'],
})
export class SwitchListComponent {
  @Input() list: string;
  @Input() quantity_columns: number;
  @Input() switch_to_number: boolean;
  @Input() grid_revers: boolean;

  get split_list() {
    return this.list.split('\n');
  }
}
