import { Component, OnInit } from '@angular/core';
import { CarsService } from '../cars.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Template } from 'src/app/entity/template.entity';
import { Container } from 'src/app/entity/container.entity';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss']
})
export class CarsComponent implements OnInit {
  public containers: Container[] = [];
  public create_template: boolean = false;
  public update_template?: Template;
  public loading = true;

  constructor(private readonly carsService: CarsService) { }

  ngOnInit(): void {
    this.carsService.getAllContainers().subscribe((r) => {
      this.containers = r
      this.loading = false
    })
  }

  get current_tab() {
    return this.carsService.current_tab;
  }

  get show_bulk_action() {
    return this.containers.some((container) => container.selected)
  }

  public selectedTabChange(e: MatTabChangeEvent): void {
    this.carsService.current_tab = e.tab.textLabel as 'Containers' | 'Templates';
    if (this.show_bulk_action) {
      this.carsService.unselectAllContainers();
    }
  }

  public handleCreateTemplate() {
    if (this.update_template) {
      this.update_template = undefined;
    } else {
      this.create_template = !this.create_template;
    }

  }
}
