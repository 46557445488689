import { Component, Input } from '@angular/core';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { ScheduleService } from '../../pages/schedule/services/schedule.service';

@Component({
  selector: 'table-schedule',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() users: UserWithSchedule[];
  @Input() loading: boolean;
  @Input() tab: string;
  public time_templates: TimeTemplate[];
  public displayedColumns: string[] = ['Name', 'Location', 'Info', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Note'];
  constructor(private readonly scheduleService: ScheduleService) { }

  ngOnInit(): void {
    this.scheduleService.getAllTimeTemplates().subscribe((data) => {
      this.time_templates = data;
    })
  }
}
