import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
import { password_pattern } from 'src/app/modules/ui-kit/password-input/component/password-input.component';

@Component({
  selector: 'accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss'],
})
export class AcceptInviteComponent {
  public registerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(password_pattern),
    ]),
  });

  constructor(
    private authService: AuthorizationService,
    private route: ActivatedRoute
  ) { }

  public get name() {
    return this.registerForm.get('name')!;
  }
  public get password() {
    return this.registerForm.get('password')!;
  }

  public onSubmit() {
    const key = this.route.snapshot.queryParamMap.get('key');
    const { name, password } = this.registerForm.value;
    if (key && name && password) {
      this.authService.acceptInvitation({ name, password, key });
    }
  }
}
