import { gql } from "apollo-angular";
import { Message } from "src/app/entity/message.entity";

export const GET_ALL_MESSAGES = gql`
query getMessages($pagination: FindPaginationDto!) {
  getMessages(pagination: $pagination) {
    items {
      id
      text
      type
      prompt
      database_result
      database_query
    }
    total
  }
}
`

export type IGetAllMessagesDto = {
  pagination: {
    skip: number;
    take: number;
  }
}

export type IGetAllMessagesResponse = {
  getMessages: {
    items: Message[];
    total: number;
  }
}
