<div class="wrap">
  <div class="head">
    <h2 class="Lexend-32 regular">Approve inventory</h2>
    <button
      (click)="approveRequestForCarItems()"
      [disabled]="!consideration_exist"
      mat-raised-button
    >
      Submit
    </button>
  </div>

  <mat-tab-group animationDuration="0" class="wrap-tables">
    <mat-tab label="Waiting for consideration">
      <technician-approve-inventory-table
        class="keys-table"
        *ngFor="let inventory of consideration | keyvalue"
        [(items)]="inventory.value"
        [column]="tab_consideration"
        [date]="+inventory.key * 1000"
      />
    </mat-tab>
    <mat-tab label="Approved">
      <technician-approve-inventory-table
        class="keys-table"
        *ngFor="let inventory of submitted | keyvalue"
        [(items)]="inventory.value"
        [column]="tab_approved"
        [date]="+inventory.key * 1000"
      />
    </mat-tab>
  </mat-tab-group>
</div>
