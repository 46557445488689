import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { IconsModule } from '../../modules/icons/icons.module';
import { ChatService } from './chat.service';
import { AnswerComponent } from './component/chat/answers/answer/answer.component';
import { DataBaseTableComponent } from './component/chat/answers/answer/data-base-table/data-base-table.component';
import { AnswersComponent } from './component/chat/answers/answers.component';
import { ChatComponent } from './component/chat/chat.component';
import { QuestionBarComponent } from './component/chat/question-bar/question-bar.component';
import { DatabaseTableComponent } from './component/database-table/database-table.component';
import { ParseDataComponent } from './component/parse-data/parse-data.component';
import { TabsComponent } from './component/tabs/tabs.component';
import { LastTeachingDialog } from './component/teaching/component/last-teaching-dialog/last-teaching-dialog';
import { TeachingBlockComponent } from './component/teaching/component/teaching-block/teaching-block.component';
import { TeachingComponent } from './component/teaching/component/teaching.component';
import { TeachingService } from './teaching.service';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    IconsModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    SideMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // MatMomentDateModule,
    DirectiveModule,
    RouterModule.forChild([
      {
        path: 'chat',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            title: 'Chat | SLK CRM',
            component: TabsComponent,
          },
        ],
        canActivate: [RoleGuard, AuthGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.chat,
        },
      },
    ]),
    HighlightModule,
  ],
  declarations: [
    ChatComponent,
    QuestionBarComponent,
    AnswersComponent,
    AnswerComponent,

    TeachingComponent,
    TeachingBlockComponent,
    LastTeachingDialog,
    TabsComponent,

    DatabaseTableComponent,
    DataBaseTableComponent,
    ParseDataComponent
  ],
  providers: [ChatService, TeachingService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      }
    }
  ],
})
export class ChatModule { }
