/// <reference types="@types/googlemaps" />
import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  Input,
} from '@angular/core';
import { FormGroup, ControlContainer, FormArray } from '@angular/forms';

@Component({
  selector: 'AutocompleteComponent',
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() i: number;
  @Input() addressType: string;
  @ViewChild('addressRef') addressRef: any;

  queryWait: boolean;

  constructor(public readonly controlContainer: ControlContainer) {}

  addressError(i: number) {
    return (this.form.get('address') as FormArray).at(i).hasError('required');
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressRef.nativeElement,
      {
        componentRestrictions: { country: ['us'] },
        fields: ['formatted_address', 'address_components', 'name'],
        types: ['address'],
      }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    (this.form.get('address') as FormArray)
      .at(this.i)
      .patchValue(place.formatted_address);
  }
}
