import {
  Component,
  DoCheck,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { permissions } from '../permissions';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements DoCheck {
  public current_route: string;
  public is_open_mobile: boolean = false;

  public get getPermissions(): typeof permissions {
    return permissions;
  }

  constructor(
    private readonly router: Router,
    private readonly authService: AuthorizationService
  ) {}

  ngDoCheck(): void {
    this.current_route = this.router.url;
  }

  public toggleIsOpen() {
    this.is_open_mobile = !this.is_open_mobile;
  }

  public logout(): void {
    this.authService.logout();
  }
}
