import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CarsService } from '../../cars.service';
import { Template } from 'src/app/entity/template.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  public $templates: Observable<Template[]>;
  @Output() onUpdateTemplate = new EventEmitter<Template>();

  constructor(
    private readonly carsService: CarsService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.$templates = this.carsService.getAllTemplates();
  }

  public deleteTemplate(id: string) {
    this.carsService.deleteManyTemplates([id]).subscribe({
      complete: () => this.alertService.alert({ message: 'Template successfully deleted', type: 'success' }),
      error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
    })
  }
}
