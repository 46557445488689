<div class="head">
  <div
    class="back blue Lexend-14"
    *ngIf="showBack"
    (click)="onBack($event)"
    [mat-dialog-close]
  >
    <arrow-icon />
    Back
  </div>
  <cross-icon class="cross" (click)="onCross()" />
  <p class="Lexend-18 medium grey center">{{ title }}</p>
</div>
