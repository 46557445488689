import { Component, Input } from '@angular/core';

interface BreadCrumbsRoute {
  name: string;
  href: string;
}

export interface BreadCrumbsOptions {
  routes: BreadCrumbsRoute[];
  current_route: BreadCrumbsRoute;
};

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent {
  @Input() options: BreadCrumbsOptions;
}
