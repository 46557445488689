import { gql } from 'apollo-angular';
import { FineTune } from 'src/app/entity/fine-tune.entity';

export const START_FINE_TUNE = gql`
  mutation startFineTune {
    startFineTune {
      id
      created_at
    }
  }
`;
export interface IStartFineTuneResponse {
  startFineTune: FineTune;
}
