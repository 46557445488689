import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { AllLocksmithModule } from '../../common/all-locksmith/all-locksmith.module';
import { ConfirmedComponent } from './component/confirmed.component';
import { ConfirmedService } from './confirmed.service';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    AllLocksmithModule.inversion(ConfirmedService),
    RouterModule.forChild([
      {
        path: 'slk-admin/confirmed',
        loadChildren: async () =>
          (await import('./confirmed.module')).ConfirmedModule,
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConfirmedComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [ConfirmedComponent],
  providers: [ConfirmedService],
})
export class ConfirmedModule {}
