import { gql } from "apollo-angular";

export const ACCEPT_INVITE = gql`
mutation acceptInvite ($accept_invite_dto: AcceptInviteDto!) {
  acceptInvite(accept_invite_dto: $accept_invite_dto) {
    id
    name
    email
  }
}`;

export interface IAcceptInviteDto {
    accept_invite_dto: {
      name: string;
      key: string;
      password: string;
    }
}

export interface IAcceptInviteResponse {
  acceptInvite: {
    id: string;
    email: string;
    name: string;
  }
}
