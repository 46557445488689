<ng-container [formGroup]="$any(controlContainer.control)">
  <mat-form-field>
    <mat-label> ID link </mat-label>
    <input
      [value]="urlLink"
      matInput
      disabled="true"
      type="text"
      placeholder="ID link"
    />
  </mat-form-field>
</ng-container>
