import { NgModule } from '@angular/core';
import { SpinnerLoadingComponent } from './spinner/spinner.component';
import { WrapperLoadingComponent } from './wrapper/wrapper.component';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../icons/icons.module';
import { LogoLoadingComponent } from './logo/logo.component';
import { MainLayoutModule } from "../../layout/main/main.module";

const components = [
  SpinnerLoadingComponent,
  WrapperLoadingComponent,
  LogoLoadingComponent,
]

@NgModule({
    declarations: components,
    exports: components,
    imports: [CommonModule, IconsModule]
})
export class LoadingModule { }
