import { gql } from "apollo-angular";
import { ItemTemplate } from "src/app/entity/item_template.entity";

export const UPDATE_ITEM_TEMPLATE = gql`
mutation updateItemTemplate ($updateItemTemplateDto: UpdateItemTemplateDto!) {
  updateItemTemplate(updateItemTemplateDto: $updateItemTemplateDto) {
    id
    quantity
    sku
    template_id
    uhs_sku
  }
}`;

export interface IUpdateItemTemplateDto {
  updateItemTemplateDto: Omit<ItemTemplate, 'template_id'>;
}

export interface IUpdateItemTemplateResponse {
  updateItemTemplate: ItemTemplate;
}
