import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { DeleteDialogModule } from 'src/app/modules/ui-kit/delete-item-popup/delete-dialog.module';
import { CompanyItemModule } from '../company-item/company-item.module';
import { AllLocksmithAbstractService } from '../services/all-locksmith-abstract.service';
import { AllLocksmithComponent } from './component/all-locksmith.component';

@NgModule({
  imports: [
    CommonModule,
    DeleteDialogModule,
    CommonModule,
    MatInputModule,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    IconsModule,
    MatPaginatorModule,
    CompanyItemModule,
    LoadingModule,
    MatTabsModule,
  ],
  declarations: [AllLocksmithComponent],
  providers: [],
  exports: [AllLocksmithComponent],
})
export class AllLocksmithModule {
  static inversion(
    useClass: Type<AllLocksmithAbstractService>
  ): ModuleWithProviders<AllLocksmithModule> {
    return {
      ngModule: AllLocksmithModule,
      providers: [
        {
          provide: AllLocksmithAbstractService,
          useClass,
        },
      ],
    };
  }
}
