<group-ranges [day]="day" (click)="setOpenMenu(true)" />

<ng-template [ifRoles]="acceptable_edit_roles">
  <app-side-menu
    *ngIf="open"
    [is_open]="open"
    (onClose)="setOpenMenu(false)"
    [show_back]="step === 'create-period' || step === 'requests'"
    (onBack)="setTimePeriod('picker')"
    [headline]="titleByStep"
  >
    <time-picker
      (onSavePicker)="setOpenMenu(false)"
      *ngIf="step === 'picker'"
      [day]="day"
      [dayIndex]="dayIndex"
      [dayName]="dataName"
      [user]="user"
      (createPeriod)="setTimePeriod('create-period')"
      (requestedTime)="setTimePeriod('requests')"
      [(editTemplate)]="editDate"
    />
    <periods
      [(editDate)]="editDate"
      *ngIf="step === 'create-period'"
      (changeStep)="setTimePeriod('picker')"
    />
    <requests
      *ngIf="step === 'requests'"
      [dataName]="dataName"
      [user]="user"
      [day]="request_schedules"
    />
  </app-side-menu>
</ng-template>
