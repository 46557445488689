import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'data-base-table',
  templateUrl: './data-base-table.component.html',
  styleUrls: ['./data-base-table.component.scss']
})
export class DataBaseTableComponent implements OnInit {
  public readonly QUANTITY_TABLE_COLUMNS_NAME = 1
  @Input() database_result: string | null;
  public formatted_database_result: any[] = [];
  public displayed_columns: string[] = [];

  @HostBinding("style.--rows-quantity") rows_quantity: string = '';

  ngOnInit(): void {
    const parse_string: any[] | null = JSON.parse(this.database_result || 'null');

    if (!!parse_string && parse_string.length > 0) {
      this.displayed_columns = Object.keys(parse_string[0]);

      this.formatted_database_result = parse_string.map(item => {
        Object.entries(item).map(([key, value]) => {
          if (typeof value === 'object') {
            return item[key] = JSON.stringify(value)
          } else return item[key] = value
        })
        return item
      })

      this.rows_quantity = `${this.formatted_database_result.length + this.QUANTITY_TABLE_COLUMNS_NAME}`
    }
  }

}
