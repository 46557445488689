import { Component } from '@angular/core';
import { ItemTemplate } from 'src/app/entity/item_template.entity';
import { CarsService } from '../../../cars.service';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'app-specific-template',
  templateUrl: './specific-template.component.html',
  styleUrls: ['./specific-template.component.scss']
})
export class SpecificTemplateComponent {
  public template_id: string;
  public template_items: ItemTemplate[];
  public template_name: string;
  public open_side_menu: boolean = false;
  public edit_item?: Omit<ItemTemplate, 'template_id'>;

  constructor(
    private readonly carsService: CarsService,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.template_id = this.route.snapshot.paramMap.get('template_id')!;
    this.carsService.getTemplateById(this.template_id).subscribe((r) => {
      this.template_items = r.items;
      this.template_name = r.name;
    });
  }

  public setEditItem(edit_item?: Omit<ItemTemplate, 'template_id'>) {
    this.edit_item = edit_item;

    if (!this.open_side_menu) {
      this.open_side_menu = true;
    }
  }

  public toggleSideMenu() {
    this.open_side_menu = !this.open_side_menu;

    if (this.edit_item) {
      this.edit_item = undefined;
    }
  }

  public onUploadItems(files: FileList | null) {
    if (files) {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(files[0]);
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        this.carsService.createManyItemsTemplate(data.map((item: any) => ({
          sku: String(item['S.L.K SKU'] || ''),
          uhs_sku: item['UHS SKU'],
          quantity: item['Quantity'],
          template_id: this.template_id,
        }))).subscribe({
          complete: () => this.alertService.alert({ type: 'success', message: 'Item successfully updated' }),
          error: (e) => this.alertService.alert({ type: 'error', message: e.message })
        });
      };
    }
  }
 }
