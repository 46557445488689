import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-meta-infos-fields',
  templateUrl: './meta-infos-fields.component.html',
  styleUrls: ['./meta-infos-fields.component.scss']
})
export class MetaInfosFieldsComponent implements OnInit {
  @Output() removeItem = new EventEmitter<number>();

  constructor(
    public readonly controlContainer: ControlContainer
  ) { }
  ngOnInit(): void {
    this.control?.get('name')?.valueChanges.subscribe(val => {
      this.control?.get('url')?.patchValue(val)
    })
  }

  public removeBlock() {
    this.removeItem.emit()
  }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }
}
