import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { DefaultLayoutComponent } from 'src/app/layout/default/default.component';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { InventoryTechGuard } from 'src/app/modules/authorization/guard/inventory-tech.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { BreadCrumbsModule } from 'src/app/modules/ui-kit/bread-crumbs/bread-crumbs.module';
import { DeleteDialogModule } from 'src/app/modules/ui-kit/delete-item-popup/delete-dialog.module';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { UploadModule } from 'src/app/modules/ui-kit/upload/upload.module';
import { CarsService } from './cars.service';
import { ApprovedInventoryTableComponent } from './component/approved-inventory/approved-inventory-table/approved-inventory-table.component';
import { ApprovedInventoryComponent } from './component/approved-inventory/approved-inventory.component';
import { BulkActionComponent } from './component/bulk-action/bulk-action.component';
import { CarsComponent } from './component/cars.component';
import { ConfirmInventoryComponent } from './component/confirm-inventory/confirm-inventory.component';
import { TableComponent } from './component/confirm-inventory/table/table.component';
import { ContainersComponent } from './component/containers/containers.component';
import { SendToUpdateComponent } from './component/containers/send-to-update/send-to-update.component';
import { DifferenceTableComponent } from './component/containers/specific-container/difference-table/difference-table.component';
import { SpecificContainerComponent } from './component/containers/specific-container/specific-container.component';
import { CarsTableComponent } from './component/containers/specific-container/table/table.component';
import { SpecificTemplateComponent } from './component/templates/specific-template/specific-template.component';
import { TemplateTableComponent } from './component/templates/specific-template/table/table.component';
import { TemplateItemComponent } from './component/templates/specific-template/template-item/template-item.component';
import { TemplateModalComponent } from './component/templates/template-modal/template-modal.component';
import { TemplatesComponent } from './component/templates/templates.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatCheckboxModule,
    IconsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    BreadCrumbsModule,
    SideMenuModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    UploadModule,
    LoadingModule,
    DeleteDialogModule,
    DirectiveModule,
    RouterModule.forChild([
      {
        path: 'cars',
        component: MainLayoutComponent,
        title: 'Inventory | SLK CRM',
        children: [
          {
            path: 'containers',
            component: CarsComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions.cars,
            },
          },
          {
            path: 'templates',
            component: CarsComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions.cars,
            },
          },
          {
            path: 'templates/:template_id',
            title: 'Inventory template | SLK CRM',
            component: SpecificTemplateComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions.cars,
            },
          },
          {
            path: 'containers/:container_id',
            title: 'Inventory container | SLK CRM',
            component: SpecificContainerComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions.cars,
            },
          },
          {
            path: 'approved-inventory',
            title: 'Approved inventory | SLK CRM',
            component: ApprovedInventoryComponent,
            canActivate: [AuthGuard, RoleGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions.cars,
            },
          },
        ],
      },
      {
        path: 'cars',
        component: DefaultLayoutComponent,
        title: 'Inventory | SLK CRM',
        children: [
          {
            path: 'confirm-inventory',
            title: 'Confirm inventory | SLK CRM',
            component: ConfirmInventoryComponent,
            canActivate: [AuthGuard, RoleGuard, InventoryTechGuard],
            data: {
              protection: ProtectionLevels.AUTHORIZED,
              error_href: '/',
              acceptable_roles: permissions['cars-confirm-inventory'],
            },
          },
        ],
      },
    ]),
  ],
  declarations: [
    ContainersComponent,
    TemplatesComponent,
    SpecificTemplateComponent,
    SpecificContainerComponent,
    CarsComponent,
    CarsTableComponent,
    TemplateTableComponent,
    TemplateItemComponent,
    TemplateModalComponent,
    BulkActionComponent,
    DifferenceTableComponent,
    SendToUpdateComponent,
    ConfirmInventoryComponent,
    TableComponent,
    ApprovedInventoryComponent,
    ApprovedInventoryTableComponent,
  ],
  providers: [CarsService],
  exports: []
})
export class CarsModule { }
