import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { UserRole } from "../enum/user-role.enum";
import { AuthorizationService } from "../modules/authorization/authorization.service";

@Directive({
  selector: '[ifRoles]'
})
export class IfRolesDirective implements OnInit, OnDestroy {
  @Input() public ifRoles: Array<UserRole>;
  private current_user$: Subscription;


  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private readonly authService: AuthorizationService,
  ) { }

  public ngOnInit() {
    this.current_user$ = this.authService.getCurrentUser().subscribe(({ data, loading }) => {
      if (this.ifRoles.length === 0) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        if (!loading) {
          if (this.ifRoles.includes(data.getCurrentUser.role)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainerRef.clear();
          }
        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.current_user$.unsubscribe()
  }
}
