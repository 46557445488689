import { Component, Input } from '@angular/core';
import { ScheduleService } from '../../pages/schedule/services/schedule.service';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @Input() tab: string;
  constructor(private readonly scheduleService: ScheduleService) { }

  public setSearchValue(e: Event) {
    const { value } = (e.target as HTMLInputElement)
    this.scheduleService.setSearchValue = value
  }
}
