import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';

@Component({
  selector: 'side-forma',
  templateUrl: './side-forma.component.html',
  styleUrls: ['./side-forma.component.scss'],
})
export class SideFormaComponent implements OnInit {
  @Input() type: 'Create' | 'Update' = 'Create';

  @Input() is_open = false;
  @Output() is_openChange = new EventEmitter<boolean>();

  @Input() company: ConstructedCompany;
  @Output() submitForm = new EventEmitter<{ name: string }>();

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor() {}

  ngOnInit(): void {
    if (!this.company) return;
    this.form.setValue({ name: this.company.name });
  }

  public onSubmit() {
    if (!this.form.valid) return;
    this.submitForm.emit({ name: this.form.value.name || '' });
    this.is_openChange.emit(false);
  }
}
