import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  @Input() accept: string[];
  @Input() label: string;
  @Output() onUpload = new EventEmitter<FileList | null>();

  public onChange(e: Event) {
    const input = e.target as HTMLInputElement;
    this.onUpload.emit(input.files);
    input.value = '';
  }
}
