import { APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { authLink } from './links/auth.link';
import { errorLink } from './links/error.link';
import { environment } from 'src/environments/environment';
import { cache } from './links/cache';

const uri = environment.HTTP_BACKEND_URL + '/graphql'; // <-- add the URL of the GraphQL server here

export const httpLinkWithUpload = createUploadLink({
  uri,
});

export function createApollo(): ApolloClientOptions<any> {
  return {
    link: ApolloLink.from([authLink, errorLink, httpLinkWithUpload]),
    cache,
    connectToDevTools: true,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useMutationLoading: true,
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
