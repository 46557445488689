import { Component, ViewEncapsulation } from '@angular/core';
import { ChatService } from '../../../chat.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatType } from '../../../apollo/mutation/send-message.mutation';

@Component({
  selector: 'question-bar',
  templateUrl: './question-bar.component.html',
  styleUrls: ['./question-bar.component.scss']

})
export class QuestionBarComponent {
  public loading: boolean = false;
  public form = new FormGroup({
    prompt: new FormControl('', [Validators.required]),
    chat_type: new FormControl(true),
  })

  constructor(private readonly chatService: ChatService) { }

  public sendMessage() {
    const { prompt, chat_type } = this.form.value

    if (this.form.valid && prompt!.trim().length > 0) {
      const handleChatType = chat_type ? ChatType.WITH_API : ChatType.DEFAULT;

      this.chatService.sendMessage(prompt!, handleChatType)
        .subscribe(({ loading }) => {
          this.loading = loading
        });

      this.form.patchValue({ prompt: '' });
    }
  }

  public onKeyDown(e: Event) {
    e.preventDefault();
    this.sendMessage()
  }
}
