import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  @Input() headline: string;
  @Input() is_open: boolean = false;
  @Input() show_back: boolean = false;

  @Output() onClose = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();

  public handleClose() {
    this.onClose.emit();
  }

  public handleOnBack() {
    this.onBack.emit();
  }
}
