import { gql } from "apollo-angular";

export const DELETE_TIME_TEMPLATE = gql`
  mutation deleteTimeTemplate($id: ID!) {
      deleteTimeTemplate(id: $id)
  }
`


export type IDeleteTimeTemplateDto = {
  id: string;
}

export type IDeleteTimeTemplateResponse = {
  deleteTimeTemplate: string;
}
