export enum UserRole {
  ADMIN = 'ADMIN',
  MAIN_DISPATCHER = 'MAIN_DISPATCHER',
  DISPATCHER = 'DISPATCHER',
  TECHNICIAN = 'TECHNICIAN',
  LOGISTIC = 'LOGISTIC',
  HR = 'HR',
  MANAGER = 'MANAGER',
  MARKETING = 'MARKETING',
}

export const roles = {
  Admin: UserRole.ADMIN,
  'Main dispatcher': UserRole.MAIN_DISPATCHER,
  Dispatcher: UserRole.DISPATCHER,
  Technician: UserRole.TECHNICIAN,
  Logistic: UserRole.LOGISTIC,
  Marketing: UserRole.MARKETING,
  HR: UserRole.HR,
};

export const workiz_user_role = {
  admin: UserRole.ADMIN,
  'owner ': UserRole.ADMIN,
  tech: UserRole.TECHNICIAN,
  moran: UserRole.TECHNICIAN,
  'dispatch ': UserRole.DISPATCHER,
  logistics: UserRole.LOGISTIC,
  accountant: UserRole.ADMIN,
  'manager ': UserRole.ADMIN,
  'office manger ': UserRole.ADMIN,
  'ct main c': UserRole.MAIN_DISPATCHER,
  'tx main c': UserRole.MAIN_DISPATCHER,
  chicago: UserRole.MAIN_DISPATCHER,
  'ct manager': UserRole.MAIN_DISPATCHER,
  'manager 2': UserRole.MAIN_DISPATCHER,
  'a1 for daniel': UserRole.ADMIN,
};

export const reversed_roles = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.MAIN_DISPATCHER]: 'Main dispatcher',
  [UserRole.DISPATCHER]: 'Dispatcher',
  [UserRole.TECHNICIAN]: 'Technician',
  [UserRole.LOGISTIC]: 'Logistic',
  [UserRole.HR]: 'HR',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.MARKETING]: 'Marketing',
};
