import { Component } from '@angular/core';

@Component({
  selector: 'ctm',
  templateUrl: './ctm.component.html',
  styleUrls: ['./ctm.component.scss']
})
export class CtmComponent {

}
