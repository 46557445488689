<div class="wrap">
  <h1 class="Lexend-32 regular">Profile</h1>
  <div>
    <mat-tab-group animationDuration="0">
      <mat-tab label="Personal Info">
        <app-personal-info />
      </mat-tab>

      <mat-tab label="Password">
        <app-password />
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
