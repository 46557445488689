<mat-tab-group class="wrap">
  <mat-tab
    [label]="'Waiting for consideration (' + quantitiReviewsWaiting + ')'"
  >
    <review-card
      *ngFor="let review of reviewsUnderConsideration"
      [review]="review"
    />
  </mat-tab>

  <mat-tab [label]="'Published (' + quantitiReviewsPublished + ')'"
    ><review-card *ngFor="let review of reviewsApproved" [review]="review" />
  </mat-tab>
</mat-tab-group>
