import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    max_tokens: new FormControl<number | null>(null, [
      Validators.required,
      Validators.min(1),
      Validators.max(4000),
    ]),
    workiz_cookie: new FormControl<string>('', [Validators.required]),
    workiz_arizona_cookie: new FormControl<string>('', [Validators.required]),
  });

  constructor(
    public readonly settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService
      .getSystemSettings()
      .subscribe(({ max_tokens, workiz_cookie, workiz_arizona_cookie }) =>
        this.form.setValue({ max_tokens, workiz_cookie, workiz_arizona_cookie })
      );
  }

  get max_tokens() {
    return this.form.get('max_tokens');
  }

  get workiz_cookie() {
    return this.form.get('workiz_cookie')!;
  }
  get workiz_arizona_cookie() {
    return this.form.get('workiz_arizona_cookie')!;
  }

  public onSubmit() {
    if (this.form.valid) {
      const system_settings = this.form.value;
      this.settingsService.updateSettings({ system_settings });
    }
  }
}
