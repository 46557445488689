import { NgModule } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { AuthGuard } from './guard/auth.guard';
import { RoleGuard } from './guard/role.guard';
import { InventoryTechGuard } from './guard/inventory-tech.guard';
import { TechnicianGuard } from './guard/technician.guard';

@NgModule({
  providers: [AuthorizationService, AuthGuard, RoleGuard, InventoryTechGuard, TechnicianGuard],
})
export class AuthorizationModule { }
