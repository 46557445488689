import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { CalendarModule } from 'src/app/modules/ui-kit/calendar/calendar.module';
import { CalendarScheduleAbstractService } from './calendar.abstract.service';
import { CalendarComponent } from './calendar.component';



@NgModule({
  declarations: [
    CalendarComponent
  ],
  imports: [
    CommonModule,
    CalendarModule,
    DirectiveModule,
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarScheduleModule {
  static inversion(useClass: Type<CalendarScheduleAbstractService>): ModuleWithProviders<CalendarScheduleModule> {
    return {
      ngModule: CalendarScheduleModule,
      providers: [
        {
          provide: CalendarScheduleAbstractService,
          useClass
        }
      ]
    };
  }
}
