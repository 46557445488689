import { gql } from "apollo-angular";
import { ItemTemplate } from "src/app/entity/item_template.entity";

export const CREATE_MANY_ITEMS_TEMPLATE = gql`
mutation createManyItemTemplates($createItemTemplateDto: [CreateItemTemplateDto!]!) {
  createManyItemTemplates(createItemTemplateDto: $createItemTemplateDto) {
    id
    quantity
    sku
    template_id
    uhs_sku
  }
}
`;

export interface ICreateManyItemsTemplateDto {
  createItemTemplateDto: Omit<ItemTemplate, 'id'>[];
}

export interface ICreateManyItemsTemplateResponse {
  createManyItemTemplates: ItemTemplate[];
}
