import { gql } from 'apollo-angular';

export const DELETE_FINE_TUNE_ITEM = gql`
  mutation deleteFineTuneItem($id: ID!) {
    deleteFineTuneItem(id: $id)
  }
`;

export interface IDeleteFineTuneItemDto {
  id: string;
}

export interface IDeleteFineTuneItemResponse {
  deleteFineTuneItem: string;
}
