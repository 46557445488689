import { gql } from "apollo-angular";
import { UserWorkiz } from "src/app/entity/workiz-user.entity";

export const GET_ALL_USERS_WORKIZ = gql`
  query getAllUsersWorkiz {
    getAllUsersWorkiz {
      id
      active
      created
      email
      fieldTech
      name
      role
      serviceAreas
      skills
    }
  }
`

export interface IGetAllUsersWorkizResponse {
  getAllUsersWorkiz: UserWorkiz[];
}
