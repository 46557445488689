import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { QueryRef } from 'apollo-angular/query-ref';
import { map } from 'rxjs';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import {
  DELETE_LOCKSMITH,
  IDeleteLocksmithDto,
  IDeleteLocksmithResponse,
} from '../../apollo/mutation/delete-locksmith';
import {
  UpdateLocksmithDto,
  UpdateLocksmithResponse,
  UPDATE_LOCKSMITH,
} from '../../apollo/mutation/updateLocksmith';
import {
  GET_LOCKSMITHS,
  IGetLocksmithDto,
  IGetLocksmithResponse,
} from '../../apollo/query/get-locksmiths';
import { Reference } from '@apollo/client/cache/inmemory/types';

@Injectable()
export class AllLocksmithService {
  private skip: number = 0;
  private search_value: string = '';
  public page_size: number = 5;
  public page_index: number = 0;
  public confirmed_locksmith: Locksmith[];
  public loading: boolean = true;
  public total_length: number;
  public locksmith: Locksmith;
  private searchLocksmithSub: QueryRef<IGetLocksmithResponse, IGetLocksmithDto>;

  private get variables() {
    return {
      pagination: {
        skip: this.skip,
        take: this.page_size,
      },
      search_value: this.search_value,
    };
  }

  constructor(
    public apollo: Apollo,
    public alertService: AlertService,
    readonly router: Router
  ) {
    // this.getAllLocksmith(true);
    // this.getAllLocksmith(false);
  }

  setSearchValue(value: string, confirmed: boolean) {
    this.search_value = value;
    this.page_index = 0;
    this.skip = 0;
    this.searchLocksmithSub.refetch({ ...this.variables, confirmed });
  }

  public handlePageChange(event: PageEvent, confirmed: boolean) {
    this.page_index = event.pageIndex;
    this.page_size = event.pageSize;
    this.skip = event.pageIndex * this.page_size;
    this.searchLocksmithSub.refetch({ ...this.variables, confirmed });
  }

  public updateLocksmith(new_locksmith: Locksmith) {
    this.apollo
      .mutate<UpdateLocksmithResponse, UpdateLocksmithDto>({
        mutation: UPDATE_LOCKSMITH,
        variables: {
          locksmith: new_locksmith,
        },
        context: { hasUpload: true },
      })
      .subscribe({
        complete: () => {
          this.router && this.router.navigate(['/slk-admin/confirmed']);
        },
      });
  }

  getAllLocksmith(confirmed: boolean): void {
    this.searchLocksmithSub = this.apollo.watchQuery<
      IGetLocksmithResponse,
      IGetLocksmithDto
    >({
      query: GET_LOCKSMITHS,
      variables: { ...this.variables, confirmed },
    });
    this.searchLocksmithSub.valueChanges
      .pipe(
        map(({ data, loading }) => {
          this.loading = loading;
          return data.getLocksmiths;
        })
      )
      .subscribe({
        next: (locksmiths) => {
          this.confirmed_locksmith = locksmiths.items;
          this.total_length = locksmiths.total;
        },
      });
  }

  public deleteItem(id: string, confirmed: boolean) {
    return this.apollo
      .mutate<IDeleteLocksmithResponse, IDeleteLocksmithDto>({
        mutation: DELETE_LOCKSMITH,
        variables: { id },
        update: (cache) => {
          cache.modify({
            fields: {
              searchLocksmiths: (existing) => {
                const exist = existing as { items: Reference[]; total: number };

                return {
                  count: exist.total - 1,
                  items: exist.items.filter((f_e) => !f_e.__ref.includes(id)),
                };
              },
            },
          });
        },
      })
      .subscribe({
        complete: () => {
          this.searchLocksmithSub.refetch({ ...this.variables, confirmed });

          this.alertService.alert({
            type: 'success',
            message: 'The company was successfully deleted',
          });
        },
        error: (e) => {
          this.alertService.alert({ type: 'error', message: e.message });
        },
      });
  }
}
