import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import {
  ConstructedPageType,
  reversed_lover_case_constructed_page_type,
} from 'src/app/enum/constructed-page-type.enum';
import {
  CREATE_CONSTRUCTED_PAGE,
  ICreateConstructedPageDto,
  ICreateConstructedPageResponse,
} from 'src/app/pages/slk-admin/apollo/mutation/create-constructed-page.mutation';
import {
  DELETE_CONSTRUCTED_PAGE_BY_ID,
  IDeleteConstructedPageByIdDto,
  IDeleteConstructedPageByIdResponse,
} from 'src/app/pages/slk-admin/apollo/mutation/delete-constructed-page-by-id.mutation';
import {
  IUpdateConstructedPageDto,
  IUpdateConstructedPageResponse,
  UPDATE_CONSTRUCTED_PAGE,
} from 'src/app/pages/slk-admin/apollo/mutation/update-constructed-page.mutation';
import {
  GET_CONSTRUCTED_PAGE_BY_ID,
  IGetConstructedPageByIdDto,
  IGetConstructedPageByIdResponse,
} from 'src/app/pages/slk-admin/apollo/query/get-constructed-page-by-id.query';
import {
  GET_CONSTRUCTED_PAGES,
  IGetConstructedPagesDto,
  IGetConstructedPagesResponse,
} from 'src/app/pages/slk-admin/apollo/query/get-constructed-pages';
import { ConstructorService } from 'src/app/pages/slk-admin/common/constructor/constructor.service';
import { FormTemplates } from '../../../../common/constructor/component/fields-control/fields-control.form.template';
import {
  ConstructorAbstractService,
  PreviewBlockList,
} from '../../../../common/constructor/constructor.abstract.service';

@Injectable()
export class ConstructedPageService implements ConstructorAbstractService {
  public loading = false;
  public type: ConstructedPageType = ConstructedPageType.BLOG;
  public id: string;
  public blocks_preview: PreviewBlockList[] = [];
  public company_id: string;

  public form: FormGroup[] = [];

  constructor(
    protected readonly apollo: Apollo,
    protected readonly constructorService: ConstructorService,
    protected readonly router: Router
  ) { }

  public configPageOnLoad(
    id: string,
    type: keyof typeof reversed_lover_case_constructed_page_type,
    company_id: string
  ) {
    if (type) {
      this.type = reversed_lover_case_constructed_page_type[type];
    }
    if (company_id) this.company_id = company_id;

    this.handleInitialBlocks(type);
  }

  public handleInitialBlocks(
    type: keyof typeof reversed_lover_case_constructed_page_type
  ) {

    this.blocks_preview = this.constructorService.blocks[reversed_lover_case_constructed_page_type[type]];

    if (type === 'blog') {
      this.form = [this.constructorService.addBlock(FormTemplates.META_INFOS)];
    } else if (type === 'location') {
      this.form = [
        this.constructorService.addBlock(FormTemplates.META_INFOS_LOCATION),
      ];
    }
  }

  public onSave() {
    this.loading = true;

    const form_array = new FormArray([...this.form]);

    if (!form_array.valid) return;

    const template = this.constructorService.parseFormData(this.form);

    this.apollo
      .mutate<ICreateConstructedPageResponse, ICreateConstructedPageDto>({
        mutation: CREATE_CONSTRUCTED_PAGE,
        variables: {
          constructed_page: {
            ...template,
            type: this.type,
            constructed_page_company_id: this.company_id,
          },
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getConstructedPages: (exist: readonly { __ref: string }[]) => {
                return [{ __ref: `ConstructedPage:${data?.createConstructedPage.id}` }, ...exist];
              },
            },
          });
        },
      })
      .subscribe({
        complete: () => {
          this.router.navigate([
            'slk-admin',
            this.company_id,
            this.type.toLowerCase(),
          ]);
        },
      });
  }

  public getAllItems(variables: IGetConstructedPagesDto) {
    return this.apollo.watchQuery<
      IGetConstructedPagesResponse,
      IGetConstructedPagesDto
    >({
      query: GET_CONSTRUCTED_PAGES,
      variables,
    });
  }

  public publish(is_posted: boolean, id: string): void {
    this.apollo
      .mutate<IUpdateConstructedPageResponse, IUpdateConstructedPageDto>({
        mutation: UPDATE_CONSTRUCTED_PAGE,
        variables: { constructedPage: { is_posted, id } },
      })
      .subscribe();
  }

  public removeItem(id: string) {
    this.apollo
      .mutate<
        IDeleteConstructedPageByIdResponse,
        IDeleteConstructedPageByIdDto
      >({
        mutation: DELETE_CONSTRUCTED_PAGE_BY_ID,
        variables: { id },
        update: (cache, { data }) => {
          // cache.modify({
          //   fields: {
          //     getConstructedPages: (exist: readonly { __ref: string }[]) => {
          //       return exist.filter(
          //         ({ __ref }) => !__ref.includes(data!.deleteConstructedPageById)
          //       );
          //     },
          //   },
          // });
        },
      })
      .subscribe();
  }

  public getPageById(id: string) {
    return this.apollo.query<
      IGetConstructedPageByIdResponse,
      IGetConstructedPageByIdDto
    >({
      query: GET_CONSTRUCTED_PAGE_BY_ID,
      variables: { id },
      fetchPolicy: 'network-only'
    });
  }
}

export const omitObject = <T>(object: T, fields: string[]): T => {
  const omit = JSON.parse(JSON.stringify(object), (key, value) =>
    fields.includes(key) ? undefined : value
  );
  return omit;
};
