<div *ngIf="!switch_to_number; else withoutSwitch">
  <ul class="coverListBullet list_bullet_or_number">
    <li *ngFor="let text of split_list" class="listItem" [innerHTML]="text">
      {{ text }}
    </li>
  </ul>
</div>

<ng-template #withoutSwitch>
  <ol class="coverListNumbers list_bullet_or_number">
    <li
      *ngFor="let text of split_list"
      class="orderedListItem"
      [innerHTML]="text"
    ></li>
  </ol>
</ng-template>
