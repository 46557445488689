<div class="wrap">
  <div *ngFor="let template of $templates | async" class="template">
    <dots-icon class="dots" mat-icon-button [matMenuTriggerFor]="menu" />

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onUpdateTemplate.emit(template)">
        <span>Update</span>
      </button>
      <button mat-menu-item (click)="deleteTemplate(template.id)">
        <span class="delete">Delete</span>
      </button>
    </mat-menu>

    <a [routerLink]="'/cars/templates/' + template.id" class="template-block">
      <div class="icons">
        <file-icon />
      </div>
      <p class="Lexend-14 medium dark-blue">{{ template.name }}</p>
    </a>
  </div>
</div>
