import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/entity/user.entity';
import { ManageUsersService, SelectUserArgs } from '../../manage-users.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';

interface PartialSelectUserArgs extends Omit<SelectUserArgs, 'query_name'> { };

@Component({
  selector: 'app-users-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class UsersTableComponent {
  @Input() users: User[];
  @Output() handleChangeCheckbox = new EventEmitter<PartialSelectUserArgs>();

  public displayedColumns: string[] = ['checkbox', 'name', 'workiz_id', 'location', 'email', 'role', 'action'];

  constructor(
    private readonly manageUsersService: ManageUsersService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  public handleChange(selected: boolean, select_user_id?: string) {
    this.handleChangeCheckbox.emit({
      users: this.users,
      selected,
      select_user_id
    })
  }

  public isEveryUserSelected() {
    return this.users.every((user) => user.selected)
  }
  public get getCurrentTab() {
    return this.manageUsersService.getCurrentTab
  }

  public onEdit(user: User) {
    this.manageUsersService.setEditUser = user
  }

  public onDelete(id: string) {

    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'User',
        onDelete: () => {
          this.manageUsersService.deleteManyUsers([id]).subscribe({
            next: ({ loading }) => !loading && this.alertService.alert({ message: 'User successfully deleted', type: 'success' }),
            error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
          });
        }
      },
      maxWidth: '590px',
      width: '100%'
    });
  }
}
