<div class="wrap">
  <delete-icon class="delete" (click)="deleteFineTuneItem()" />
  <h2>Question</h2>
  <p>{{ fineTune.prompt }}</p>
  <div>
    <h2>
      Answer
      <edit-icon (click)="is_editing ? cancelEditing() : startEditing()" />
    </h2>
    <p
      (input)="handleInput($event)"
      [contentEditable]="is_editing"
      [ngClass]="{ editing: is_editing }"
    >
      {{ fineTune.text }}
    </p>
    <div class="buttonsWrap" *ngIf="is_editing">
      <button (click)="updateFineTuneItem()">Save <check-icon /></button>
      <hr />
      <button (click)="cancelEditing()">Cancel</button>
    </div>
  </div>
</div>
