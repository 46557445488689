import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfirmedLocksmithService } from '../confirmed-locksmith.service';

@Component({
  templateUrl: './confirmed-locksmith.component.html',
  styleUrls: ['./confirmed-locksmith.component.scss'],
})
export class ConfirmedLocksmithComponent implements OnInit {
  get loading() {
    if (this.confirmedLocksmithService.loading) {
      return this.confirmedLocksmithService.loading;
    } else return false;
  }
  public get company() {
    return this.confirmedLocksmithService.locksmith;
  }

  constructor(
    private readonly confirmedLocksmithService: ConfirmedLocksmithService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.confirmedLocksmithService.getLocksmithById({
      id: this.route.snapshot.paramMap.get('locksmith-id') as string,
    });
    // console.log(this.confirmedLocksmithService.loading);

    // .valueChanges.subscribe(({ data, loading }) => {
    //   this.loading = loading;
    //   if (data) {
    //     this.locksmith = data.getLocksmithById;
    //   }
    // });
  }
}
