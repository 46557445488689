<wrapper-loading [loading]="loading">
  <div class="wrap" scrollEndRoot>
    <table mat-table [dataSource]="data.items || []" class="mat-elevation-z8">
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index" data-name="#">
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" data-name="Action">
          {{ element.action }}
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element" data-name="Message">
          {{ element.message || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef style="min-width: 130px">
          User Name
        </th>
        <td mat-cell *matCellDef="let element" data-name="User Name">
          {{ element?.user?.name || "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element" data-name="Type">
          <p class="type" [ngClass]="element.type | lowercase">
            {{ element.type }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef>Group</th>
        <td mat-cell *matCellDef="let element" data-name="Group">
          {{ element.group }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element" data-name="Date">
          {{ helperService.getDate(element.created_at) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <spinner-loading
      (scrollEnd)="loadMore()"
      *ngIf="data && data.items.length < data.total"
    />
  </div>
</wrapper-loading>
