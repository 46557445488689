<div class="wrap">
  <table mat-table [dataSource]="container_items" class="mat-elevation-z8">

    <ng-container matColumnDef="product_name">
      <th mat-header-cell *matHeaderCellDef>Product name</th>
      <td mat-cell *matCellDef="let element" data-name="Product name">
        {{ element.item_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Quantity">
        {{ element.qty }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element" data-name="Price">
        {{ element.price }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef>Cost</th>
      <td mat-cell *matCellDef="let element" data-name="Cost">
        {{ element.cost }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
