import { gql } from 'apollo-angular';
import { SCHEDULE } from './schedule';

export const LOCKSMITH = gql`
  ${SCHEDULE}
  fragment Locksmith on Locksmith {
    address
    confirmed
    description
    file_id
    file_url
    id
    is_verified
    link_to_map
    link_to_site
    mail
    name
    owner_name
    owner_phone
    phone
    priority
    reviews {
      id
      name
      rating
      status
      text
      title
    }
    schedules {
      ...LocksmithSchedule
    }
    services
    url
  }
`;
