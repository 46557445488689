<div class='coverFirstBlogComponent'>
  <div class='FirstBlogComponentBg' [style.--url]="data.photo.file_id | getImageUrlByPhotoID: data.photo.file : true">
    <div class='FirstBlogComponent'>
      <h1>{{data.headline}}</h1>
      <hr class='hrYellow' />
    </div>
  </div>

  <div class='coverText'>
    <p class="text" *ngFor="let text of description" [innerHTML]="text"></p>
  </div>
</div>