import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-about-city',
  templateUrl: './about-city.component.html',
  styleUrls: ['./about-city.component.scss']
})
export class AboutCityComponent {
  @Input() data: any;

  get description() {
    return this.data.description.split('\n');
  }
}
