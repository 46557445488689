import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { InfoComponent } from 'src/app/pages/schedule/components/table/info/info.component';
import { CompanyItemComponent } from './component/company-item.component';
import { FullInfoComponent } from './component/full-info/full-info.component';
import { InfoFieldComponent } from './component/full-info/info-field/info-field.component';
import { CompanyLogoComponent } from './component/full-info/logo/logo.component';
import { ScheduleComponent } from './component/full-info/schedule/schedule.component';
import { ServicesComponent } from './component/full-info/services/services.component';

@NgModule({
  imports: [CommonModule, IconsModule, RouterModule, MatButtonModule],
  declarations: [
    CompanyItemComponent,
    FullInfoComponent,
    InfoFieldComponent,
    ServicesComponent,
    ScheduleComponent,
    CompanyLogoComponent,
  ],
  exports: [CompanyItemComponent],
})
export class CompanyItemModule {}
