import { gql } from "apollo-angular";
import { User } from "src/app/entity/user.entity";

export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserDto!) {
    updateUser(user: $user) {
      id
      email
      name
      role
      workiz_id
      location
    }
  }
`

type PartialUser = Omit<User, 'email' | 'password' | 'logs'>;

export interface IUpdateUserDto {
  user: PartialUser;
}

export interface IUpdateUserResponse {
  updateUser: PartialUser;
}
