import { gql } from 'apollo-angular';
import { UnreviewedReviews } from 'src/app/entity/unreviewed-reviews.entity';

export const GET_LOCKSMITH_WITH_UNREVIEWED_REVIEW = gql`
  query getLocksmithWithUnreviewedReviews {
    getLocksmithWithUnreviewedReviews {
      id
      name
      count_new_reviews
      reviews {
        created_at
      }
    }
  }
`;

export interface IGetLocksmithWithUnreviewedReviewResponse {
  getLocksmithWithUnreviewedReviews: UnreviewedReviews[];
}
