import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { DeleteDialogModule } from 'src/app/modules/ui-kit/delete-item-popup/delete-dialog.module';
import { DialogModalComponent } from './component/blocks-modal/blocks-modal.component';
import { BlocksModule } from './component/blocks/blocks.module';
import { ConstructorComponent } from './component/constructor.component';
import { FieldsControlModule } from './component/fields-control/fields-control.module';
import { ConstructedItemsComponent } from './component/preview-items/constructed-items.component';
import { ConstructedPagesComponent } from './component/preview-items/constructed-pages/constructed-pages.component';
import { TemplateItemComponent } from './component/preview-items/info-item/info-item.component';
import { ConstructorAbstractService } from './constructor.abstract.service';
import { ConstructorService } from './constructor.service';
import { AllowedTypeConstructedPageGuard } from './guard/allowed-type-constructed-page.guard';
import { ConstructedPageExistGuard } from './guard/constructed-page-exist.guard';
import { ErrorModalConstructorComponent } from './component/error-modal/error-modal.component';
import { GetValidityPipe } from './pipe/get-validity.pipe';
import { GetImageUrlByPhotoIDPipe } from './pipe/get-image-url-by-photo-id.pipe';
import { TransformTypeBlockToTitlePipe } from './pipe/transform-type-block-to-title.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    MatTabsModule,
    MatSelectModule,
    MatButtonModule,

    ReactiveFormsModule,
    FormsModule,
    IconsModule,
    DirectiveModule,
    BlocksModule,
    FieldsControlModule,
    LoadingModule,

    DeleteDialogModule,

    GetImageUrlByPhotoIDPipe,
    TransformTypeBlockToTitlePipe
  ],
  declarations: [
    ConstructorComponent,
    DialogModalComponent,
    ConstructedItemsComponent,
    ConstructedPagesComponent,
    ErrorModalConstructorComponent,
    TemplateItemComponent,

    GetValidityPipe,
  ],
  exports: [ConstructorComponent, ConstructedItemsComponent],
  providers: [
    ConstructorService,
    AllowedTypeConstructedPageGuard,
    ConstructedPageExistGuard,
  ],
})
export class ConstructorModule {
  static inversion(
    useClass: Type<ConstructorAbstractService>
  ): ModuleWithProviders<ConstructorModule> {
    return {
      ngModule: ConstructorModule,
      providers: [
        {
          provide: ConstructorAbstractService,
          useClass,
        },
      ],
    };
  }
}
