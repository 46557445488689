import { NgModule } from '@angular/core';
import { IfRolesDirective } from 'src/app/directive/if-role.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { ScrollEndDirective } from './scroll/scroll-end';
import { ScrollEndRootDirective } from './scroll/scroll-end-root';
import { ResizeDirective } from './resize.directive';

@NgModule({
  declarations: [
    IfRolesDirective,
    ScrollEndDirective,
    ScrollEndRootDirective,
    ClickOutsideDirective,
    ResizeDirective,
  ],
  exports: [
    IfRolesDirective,
    ScrollEndDirective,
    ScrollEndRootDirective,
    ResizeDirective,
    ClickOutsideDirective,
  ],
})
export class DirectiveModule {}
