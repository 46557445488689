import { gql } from "apollo-angular";
import { User } from "src/app/entity/user.entity";

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      email
      name
      role
      workiz_id
      location
      selected @client
    }
    getAllInvitedUsers {
      id
      email
      name
      role
      workiz_id
      location
      selected @client
    }
  }
`

export interface IGetAllUsersResponse {
  getAllUsers: User[];
  getAllInvitedUsers: User[];

}
