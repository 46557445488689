import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'time-select',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.css'],
})
export class TimeComponent {
  @Input() time: string;
  @Output() setTime = new EventEmitter<string>();
  @Input() canOpen? = false;
  @ViewChild('time_list_ref') time_list_ref: ElementRef<HTMLElement>;
  open: boolean = false;

  get times() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      times.push(moment({ hour }).format('hh:mm A'));
      times.push(
        moment({
          hour,
          minute: 30,
        }).format('hh:mm A')
      );
    }

    return times;
  }

  public change(time: string) {
    this.setTime.emit(time);
  }

  toggleOpen() {
    if (this.canOpen) this.open = true;
  }
}
