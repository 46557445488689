import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { NavigationAbstractService } from './navigation.abstract.service';
import { NavigationComponent } from './navigation.component';



@NgModule({
  declarations: [
    NavigationComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
  ],
  exports: [
    NavigationComponent
  ]
})
export class NavigationModule {
  static inversion(useClass: Type<NavigationAbstractService>): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [
        {
          provide: NavigationAbstractService,
          useClass
        }
      ]
    };
  }
}
