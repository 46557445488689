import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { ItemTemplate } from 'src/app/entity/item_template.entity';
import { CarsService } from 'src/app/pages/cars/cars.service';

@Component({
  selector: 'app-template-item',
  templateUrl: './template-item.component.html',
  styleUrls: ['./template-item.component.scss']
})
export class TemplateItemComponent implements OnChanges {
  @Output() onClose = new EventEmitter<void>();
  @Input() edit_item?: Omit<ItemTemplate, 'template_id'>;

  public loading: boolean = false;
  public form: FormGroup = new FormGroup({
    uhs_sku: new FormControl('', [Validators.required]),
    sku: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly carsService: CarsService,
    private readonly route: ActivatedRoute,
    private readonly alertService: AlertService,
  ) { }

  ngOnChanges(): void {
    if (this.edit_item) {
      this.form.patchValue(this.edit_item)
    }
  }

  public onSubmit() {
    if (this.edit_item) {
      this.carsService.updateItemTemplate({
        id: this.edit_item!.id,
        uhs_sku: this.form.value.uhs_sku,
        sku: this.form.value.sku,
        quantity: Number(this.form.value.quantity),
      }).subscribe({
        next: ({ loading }) => {
          this.loading = loading;
        },
        complete: () => {
          this.form.reset();
          this.onClose.emit();
          this.alertService.alert({ type: 'success', message: 'Item successfully updated' });
        },
        error: (e) => this.alertService.alert({ type: 'error', message: e.message })
      })
    } else {
      this.carsService.createManyItemsTemplate([{
        uhs_sku: this.form.value.uhs_sku,
        sku: this.form.value.sku,
        quantity: Number(this.form.value.quantity),
        template_id: this.route.snapshot.paramMap.get('template_id')!
      }]).subscribe({
        next: ({ loading }) => {
          this.loading = loading;
        },
        complete: () => {
          this.form.reset();
          this.onClose.emit();
          this.alertService.alert({ type: 'success', message: 'Item successfully added' });
        },
        error: (e) => this.alertService.alert({ type: 'error', message: e.message })
      })
    }
  }
}
