import { Injectable } from '@angular/core';
import {
  GET_ALL_CONTAINERS,
  IGetAllContainersResponse,
} from './apollo/query/get-all-containers.query';
import { Apollo } from 'apollo-angular';
import { Observable, filter, map } from 'rxjs';
import { Container } from 'src/app/entity/container.entity';
import {
  GET_ALL_TEMPLATES,
  IGetTemplatesResponse,
} from './apollo/query/get-all-templates.query';
import { Template } from 'src/app/entity/template.entity';
import {
  GET_CONTAINER_BY_ID,
  IGetContainerByIdDto,
  IGetContainerByIdResponse,
} from './apollo/query/get-container-by-id.query';
import { WorkizItem } from 'src/app/entity/workiz-item.entity';
import {
  GET_TEMPLATE_BY_ID,
  IGetTemplateByIdDto,
  IGetTemplateByIdResponse,
} from './apollo/query/get-template-by-id.query';
import {
  CREATE_MANY_ITEMS_TEMPLATE,
  ICreateManyItemsTemplateDto,
  ICreateManyItemsTemplateResponse,
} from './apollo/mutatuin/create-many-items-template.mutation';
import {
  DELETE_MANY_ITEMS_TEMPLATE,
  IDeleteManyItemsTemplateDto,
  IDeleteManyItemsTemplateResponse,
} from './apollo/mutatuin/delete-many-items-template.mutation';
import {
  IUpdateItemTemplateDto,
  IUpdateItemTemplateResponse,
  UPDATE_ITEM_TEMPLATE,
} from './apollo/mutatuin/update-many-items-template.mutation';
import {
  ASSIGN_MANY_TEMPLATES_TO_CAR,
  IAssignManyTemplatesToCarDto,
  IAssignManyTemplatesToCarResponse,
} from './apollo/mutatuin/assign-many-template-to-car.mutation';
import {
  GENERATE_DIFFERENCE_REPORT,
  IGenerateDifferenceReportDto,
  IGenerateDifferenceReportResponse,
} from './apollo/query/generate-difference-report.query';
import {
  CREATE_MANY_TEMPLATES,
  ICreateManyTemplatesDto,
  ICreateManyTemplatesResponse,
} from './apollo/mutatuin/create-many-templates.mutation';
import {
  DELETE_MANY_TEMPLATES,
  IDeleteManyTemplatesDto,
  IDeleteManyTemplatesResponse,
} from './apollo/mutatuin/delete-many-templates.mutation';
import {
  IUpdateTemplatesDto,
  IUpdateTemplatesResponse,
  UPDATE_TEMPLATE,
} from './apollo/mutatuin/update-template.mutatuin';
import { CREATE_REQUEST_FOR_CAR_ITEMS, ICreateRequestForCarItemsDto, ICreateRequestForCarItemsResponse } from './apollo/mutatuin/create-request-for-car-items.mutation';
import { FIND_UPDATE_CAR_REQUESTS, IFindUpdateCarRequests, IFindUpdateCarRequestsResponse, IFormatUpdateCarRequest } from './apollo/query/find-update-car-requests.query';
import { APPROVE_REQUEST_FOR_CAR_ITEMS, IApproveRequestForCarItemsDto, IApproveRequestForCarItemsResponse } from './apollo/mutatuin/approve-request-for-car-items.mutation';
import { FIND_APPROVED_UPDATE_CAR_REQUESTS, IFindApprovedUpdateCarRequestsResponse } from './apollo/query/find-approved-update-car-requests.query';
import { FIND_SUBMITTED_UPDATE_CAR_REQUESTS, IFindSubmittedUpdateCarRequestsResponse } from './apollo/query/find-submitted-update-car-requests.query';
import { ISubmitRequestForCarItemsDto, ISubmitRequestForCarItemsResponse, SUBMIT_REQUEST_FOR_CAR_ITEMS } from './apollo/mutatuin/submit-request-for-car-items.mutation';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { ConfirmInventoryTabs } from './component/confirm-inventory/confirm-inventory.component';

@Injectable()
export class CarsService {
  public current_tab: 'Containers' | 'Templates' = 'Containers';

  constructor(
    private readonly apollo: Apollo,
    private readonly alertService: AlertService
  ) { }

  public generateDifferenceReport(workiz_id: string) {
    return this.apollo
      .watchQuery<
        IGenerateDifferenceReportResponse,
        IGenerateDifferenceReportDto
      >({
        query: GENERATE_DIFFERENCE_REPORT,
        variables: { workiz_id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.generateDifferenceReport)
      );
  }

  public getAllContainers(): Observable<Container[]> {
    return this.apollo
      .watchQuery<IGetAllContainersResponse, {}>({
        query: GET_ALL_CONTAINERS,
      })
      .valueChanges.pipe(map((response) => response.data.getAllContainers))
  }

  public getContainerById(id: string): Observable<WorkizItem[]> {
    return this.apollo
      .watchQuery<IGetContainerByIdResponse, IGetContainerByIdDto>({
        query: GET_CONTAINER_BY_ID,
        fetchPolicy: 'no-cache',
        variables: {
          id,
        },
      })
      .valueChanges.pipe(
        map((response) => response.data.getContainerById.container)
      );
  }

  public getAllTemplates(): Observable<Template[]> {
    return this.apollo
      .watchQuery<IGetTemplatesResponse, {}>({
        query: GET_ALL_TEMPLATES,
      })
      .valueChanges.pipe(map((response) => response.data.getAllTemplates));
  }

  public getTemplateById(
    id: string
  ): Observable<IGetTemplateByIdResponse['getTemplateById']> {
    return this.apollo
      .watchQuery<IGetTemplateByIdResponse, IGetTemplateByIdDto>({
        query: GET_TEMPLATE_BY_ID,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map((response) => response.data.getTemplateById));
  }

  public createManyItemsTemplate(
    createItemTemplateDto: ICreateManyItemsTemplateDto['createItemTemplateDto']
  ) {
    return this.apollo.mutate<
      ICreateManyItemsTemplateResponse,
      ICreateManyItemsTemplateDto
    >({
      mutation: CREATE_MANY_ITEMS_TEMPLATE,
      variables: {
        createItemTemplateDto,
      },
      update: (cache, { data }) => {
        cache.modify({
          id: `Template:${data?.createManyItemTemplates[0].template_id}`,
          fields: {
            items(array = []) {
              return [
                ...array,
                ...data!.createManyItemTemplates.map((item) => ({
                  __ref: `ItemTemplate:${item.id}`,
                })),
              ];
            },
          },
        });
      },
    });
  }

  public deleteManyItemsTemplate(ids: string[], template_id: string) {
    return this.apollo.mutate<
      IDeleteManyItemsTemplateResponse,
      IDeleteManyItemsTemplateDto
    >({
      mutation: DELETE_MANY_ITEMS_TEMPLATE,
      variables: {
        ids,
      },
      update: (cache, { data }) => {
        cache.modify({
          id: `Template:${template_id}`,
          fields: {
            items(array = []) {
              return array.filter(
                (item: any) =>
                  !data?.deleteManyItemTemplates.includes(
                    item.__ref.replace('ItemTemplate:', '')
                  )
              );
            },
          },
        });
      },
    });
  }

  public updateItemTemplate(
    dto: IUpdateItemTemplateDto['updateItemTemplateDto']
  ) {
    return this.apollo.mutate<
      IUpdateItemTemplateResponse,
      IUpdateItemTemplateDto
    >({
      mutation: UPDATE_ITEM_TEMPLATE,
      variables: { updateItemTemplateDto: dto },
    });
  }

  public assignManyTemplateToCar(
    assignManyTemplateToCar: IAssignManyTemplatesToCarDto['createCarTemplateDto']
  ) {
    return this.apollo.mutate<
      IAssignManyTemplatesToCarResponse,
      IAssignManyTemplatesToCarDto
    >({
      mutation: ASSIGN_MANY_TEMPLATES_TO_CAR,
      variables: {
        createCarTemplateDto: assignManyTemplateToCar,
      },
      refetchQueries: () => [{ query: GET_ALL_CONTAINERS }],
    });
  }

  public createManyTemplates(
    createTemplateDto: ICreateManyTemplatesDto['createTemplateDto']
  ) {
    return this.apollo.mutate<
      ICreateManyTemplatesResponse,
      ICreateManyTemplatesDto
    >({
      mutation: CREATE_MANY_TEMPLATES,
      variables: { createTemplateDto },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getAllTemplates: (exist) => [
              ...exist,
              ...data!.createManyTemplates.map((item) => ({
                __ref: `Template:${item.id}`,
              })),
            ],
          },
        });
      },
    });
  }

  public deleteManyTemplates(ids: string[]) {
    return this.apollo.mutate<
      IDeleteManyTemplatesResponse,
      IDeleteManyTemplatesDto
    >({
      mutation: DELETE_MANY_TEMPLATES,
      variables: {
        ids,
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getAllTemplates(exist = []) {
              return exist.filter(
                (item: any) =>
                  !data?.deleteManyTemplates.includes(
                    item.__ref.replace('Template:', '')
                  )
              );
            },
          },
        });
      },
    });
  }

  public updateTemplate(
    updateTemplateDto: IUpdateTemplatesDto['updateTemplateDto']
  ) {
    return this.apollo.mutate<IUpdateTemplatesResponse, IUpdateTemplatesDto>({
      mutation: UPDATE_TEMPLATE,
      variables: { updateTemplateDto },
    });
  }

  public selectContainer(container_id?: string) {
    this.apollo.client.cache.updateQuery<IGetAllContainersResponse>({ query: GET_ALL_CONTAINERS }, (data) => {
      return {
        getAllContainers: data!.getAllContainers.map((container) => {
          if (container_id === container.id) return { ...container, selected: !container.selected }
          return container;
        })
      }
    });
  }

  public unselectAllContainers() {
    this.apollo.client.cache.updateQuery<IGetAllContainersResponse>({ query: GET_ALL_CONTAINERS }, (data) => {
      return {
        getAllContainers: data!.getAllContainers.map((container) => {
          return { ...container, selected: false }
        })
      }
    });
  }


  public createRequestForCarItems(createUpdateCarDto: ICreateRequestForCarItemsDto['createUpdateCarDto']) {
    return this.apollo.mutate<ICreateRequestForCarItemsResponse, ICreateRequestForCarItemsDto>({
      mutation: CREATE_REQUEST_FOR_CAR_ITEMS,
      variables: { createUpdateCarDto }
    });
  }
  public findUpdateCarRequests() {
    return this.apollo.watchQuery<IFindUpdateCarRequestsResponse>({ query: FIND_UPDATE_CAR_REQUESTS, fetchPolicy: 'cache-and-network' })
      .valueChanges
      .pipe(map(({ data }) => ({
        consideration: this.groupByTime(
          data.findUpdateCarRequests,
          'requested_at',
          (item) => item.tab === ConfirmInventoryTabs.WAITING_FOR_CONSIDERATION ? ({ ...item }) : undefined
        ),
        submitted: this.groupByTime(
          data.findUpdateCarRequests,
          'requested_at',
          (item) => item.tab === ConfirmInventoryTabs.APPROVED ? ({ ...item }) : undefined
        )
      })));
  }

  public setTabForUpdateCarRequest(id: string, tab: ConfirmInventoryTabs) {

    this.apollo.client.cache.modify({
      id: `FindRequestsForUpdateCar:${id}`,
      fields: {
        tab(exist) {
          return tab
        }
      }
    });
  }
  public setQuantityForUpdateCarRequest(id: string, quantity: number) {
    this.apollo.client.cache.modify({
      id: `FindRequestsForUpdateCar:${id}`,
      fields: {
        requested_quantity() {
          return quantity
        }
      }
    });
  }

  public approveRequestForCarItems(approveUpdateCarDto: IApproveRequestForCarItemsDto['approveUpdateCarDto']) {
    return this.apollo.mutate<IApproveRequestForCarItemsResponse, IApproveRequestForCarItemsDto>({
      mutation: APPROVE_REQUEST_FOR_CAR_ITEMS,
      variables: { approveUpdateCarDto }
    })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            message: "Successfully send to review",
            type: "success"
          })
        }
      });
  }

  public findApprovedUpdateCarRequests() {
    return this.apollo.watchQuery<IFindApprovedUpdateCarRequestsResponse>({ query: FIND_APPROVED_UPDATE_CAR_REQUESTS })
      .valueChanges.pipe(map(({ data }) =>
        this.groupByTime(
          data.findApprovedUpdateCarRequests,
          'approved_at',
          (item) => ({ ...item, submitted_quantity: item.approved_quantity })
        )));
  }

  public submitRequestForCarItems(submitUpdateCarDto: ISubmitRequestForCarItemsDto['submitUpdateCarDto']) {
    return this.apollo.mutate<ISubmitRequestForCarItemsResponse, ISubmitRequestForCarItemsDto>({
      mutation: SUBMIT_REQUEST_FOR_CAR_ITEMS,
      variables: { submitUpdateCarDto },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            findSubmittedUpdateCarRequests: (exist) => {
              return { ...data!.submitRequestForCarItems.map(({ id }) => ({ __ref: `FindRequestsForUpdateCar:${id}` })), ...exist }
            }
          }
        })
        cache.modify({
          fields: {
            findApprovedUpdateCarRequests: (exist: readonly { __ref: string }[]) => {
              return exist.filter(f_exist => !data!.submitRequestForCarItems.some(item => f_exist.__ref.includes(item.id)))
            }
          }
        })
      },
    })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            message: "Successfully submitted",
            type: "success"
          })
        }
      });
  }

  public findSubmittedUpdateCarRequests() {
    return this.apollo.watchQuery<IFindSubmittedUpdateCarRequestsResponse>({ query: FIND_SUBMITTED_UPDATE_CAR_REQUESTS })
      .valueChanges.pipe(map(({ data }) =>
        this.groupByTime(
          data.findSubmittedUpdateCarRequests,
          'submitted_at',
          (item) => ({ ...item })
        )));
  }

  public groupByTime(
    data: IFindUpdateCarRequests[],
    field: 'approved_at' | 'requested_at' | 'submitted_at',
    handleItem: (value: IFindUpdateCarRequests) => IFindUpdateCarRequests | undefined
  ) {
    return data.reduce((format_request: IFormatUpdateCarRequest, item) => {
      const handle_item = handleItem(item)
      if (handle_item) {
        if (item[field] in format_request) {
          format_request[item[field]].push(handle_item);
        } else {
          Object.assign(format_request, { [item[field]]: [handle_item] });
        }
      }
      return format_request;
    }, {})
  }

}
