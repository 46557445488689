<form class="wrap" [formGroup]="form" (submit)="onSubmit()">
  <range [(workingHours)]="working_hours" />

  <mat-form-field>
    <mat-label>Name</mat-label>
    <input
      matInput
      placeholder="Type name here"
      type="text"
      required
      formControlName="name"
    />
    <mat-error *ngIf="nameHasError">Field can not be empty</mat-error>
  </mat-form-field>

  <p class="Lexend-16 regular darck-blue">Color</p>
  <div class="colors">
    <label
      *ngFor="let color of colors | keyvalue"
      [style]="color.value"
      class="color Lexend-16 regular pointer"
    >
      <input
        type="radio"
        name="color"
        [value]="color.key"
        required
        formControlName="color"
      />
      {{ color.key }}
      <check-icon color="currentcolor" />
    </label>
  </div>
  <button mat-raised-button type="submit">Save</button>
</form>
