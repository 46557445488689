import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent {
  @Input() text: string;

  get splitText() {
    return this.text.trim().split("\n")
  }
}
