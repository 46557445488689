<form (ngSubmit)="onSubmit()" class="form" [formGroup]="form">
  <mat-form-field>
    <mat-label>
      S.L.K SKU
    </mat-label>
    <input matInput type="text" placeholder="Type slk sku here" formControlName="sku" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      UHS SKU
    </mat-label>
    <input matInput type="text" placeholder="Type uhs name here" formControlName="uhs_sku" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Quantity
    </mat-label>
    <input matInput type="number" placeholder="Type quantity here" formControlName="quantity" />
  </mat-form-field>

  <button mat-raised-button type="submit" [disabled]="form.invalid || loading">Add item</button>
</form>

