import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ConstructedItemsComponent } from '../../../common/constructor/component/preview-items/constructed-items.component';
import { ConstructorModule } from '../../../common/constructor/constructor.module';
import { AllowedTypeConstructedPageGuard } from '../../../common/constructor/guard/allowed-type-constructed-page.guard';
import { ConstructedPageService } from './services/constructed-page.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConstructorModule.inversion(ConstructedPageService),
    RouterModule.forChild([
      {
        path: 'slk-admin/:company-id/:type',
        loadChildren: () =>
          import('./constructed-pages.module').then(m => m.ConstructedPagesModule),
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConstructedItemsComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard, AllowedTypeConstructedPageGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
})
export class ConstructedPagesModule { }
