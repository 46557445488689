import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISchedulePicker } from '../time-picker.component';

@Component({
  selector: 'range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss']
})
export class RangeComponent {
  @Input() workingHours: ISchedulePicker[] = [];
  @Output() workingHoursChange = new EventEmitter<ISchedulePicker[]>();

  private readonly default_work_day: ISchedulePicker[] = [{ work_from: "12:00 am", work_to: "11:59 pm" }]

  public addNewRange() {
    this.workingHours = [...this.workingHours, ...this.default_work_day]
    this.workingHoursChange.emit(this.workingHours)
  }

  public removeRange(index_of_element: number) {
    this.workingHours = this.workingHours.filter((_, index)=>{
      return index_of_element !== index
    })

    this.workingHoursChange.emit(this.workingHours)
  }

  public updateRange(value: string, key: string, index_of_element: number) {
    this.workingHours = this.workingHours.map((m_range, index) => {
      if (index === index_of_element) return { ...m_range, [key]: value }
      return m_range
    })
    this.workingHoursChange.emit(this.workingHours)
  }

}
