<p>
  <span>Name:</span><span class="dark-blue">{{ name }}</span>
</p>
<p>
  <span>Day:</span> <span class="dark-blue">{{ day_name }}</span>
</p>
<div class="hours">
  <p>Hours:</p>
  <group-ranges [day]="day" *ngIf="day" />
</div>

<div class="buttons">
  <button mat-raised-button (click)="approveUserScheduleRequest()">
    Approve
  </button>
  <button mat-raised-button class="red" (click)="declineUserScheduleRequest()">
    Decline
  </button>
</div>
