import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'title-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss'],
})
export class HeadComponent {
  @Output() clickBack = new EventEmitter<void>();
  @Output() clickCross = new EventEmitter<void>();
  @Input() title: string = 'Title';
  @Input() showBack: boolean = false;


  public onBack(event: Event) {
    event.preventDefault();
    this.clickBack.emit();
  }

  public onCross() {
    this.clickCross.emit();
  }
}
