<ng-container [formGroup]="form">
  <spinner-loading *ngIf="loading" />
  <section class="question-bar">
    <mat-form-field class="example-full-width">
      <textarea
        cdkTextareaAutosize
        matInput
        placeholder="Start typing..."
        class="textarea"
        formControlName="prompt"
        (keydown.enter)="onKeyDown($event)"
      ></textarea>
    </mat-form-field>

    <button title="send" class="send-button" (click)="sendMessage()">
      <send-message-icon data-icon />
    </button>
  </section>
  <mat-slide-toggle formControlName="chat_type">
    Search thought database
  </mat-slide-toggle>
</ng-container>
