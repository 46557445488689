<div class="search-bar">
  <filters [tab]="tab" />
  <mat-form-field>
    <mat-label></mat-label>
    <input
      matInput
      (input)="setSearchValue($event)"
      type="text"
      placeholder="Search a technician..."
    />
  </mat-form-field>
</div>
