import { gql } from "apollo-angular";

export const DELETE_MANY_INVITED_USERS = gql`
  mutation deleteManyInvitedUsers ($user_ids: [ID!]!) {
    deleteManyInvitedUsers(user_ids: $user_ids)
  }
`;

export interface IDeleteManyInvitedUsersDto {
  user_ids: string[];
}

export interface IDeleteManyInvitedUsersResponse {
  deleteManyUsers: string[];
}
