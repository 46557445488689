<div class='hourService'>
  <a id="Hour" class='anchor' ></a>
  <div class='secondMainSpan'>
    <h2>{{ data.headline }}</h2>
    <hr />
  </div>
  <p class='text' [innerHTML]="data.description" ></p>
  <div class='list'>
    <ul class='listItem'>
      <li *ngFor="let service of list">{{ service }}</li>
    </ul>
  </div>
</div>
