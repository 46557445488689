<div class="youMayAlso">
  <div class="youMayAlsoBlock">
    <div class="coverTitle">
      <h2>{{ data.headline }}</h2>
      <hr />
    </div>

    <div class="list">
      <a class="card" rel="dofollow" role="listitem" *ngFor="let blog of blogs">
        <img
          [src]="blog.preview_info?.photo?.link"
          alt="blog photo"
          class="img"
          loading="lazy"
        />
        <p class="title headline-font-four black bold">
          {{ blog.preview_info?.title }}
        </p>
        <p
          class="description body-text-font grey"
          [innerHTML]="blog.preview_info?.description"
        ></p>
      </a>
    </div>
  </div>
</div>
