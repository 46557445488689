import { ApolloCache, InMemoryCache } from '@apollo/client/core';
import moment from 'moment';
import { UserWithScheduleFormatted } from 'src/app/entity/schedule/user-formatted.entity';
import { UserSchedule } from 'src/app/entity/schedule/user-schedule.entity';
import { ConfirmInventoryTabs } from 'src/app/pages/cars/component/confirm-inventory/confirm-inventory.component';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    WorkizContainerInfo: {
      keyFields: ["item_id"]
    },
    User: {
      fields: {
        selected: {
          read(existing) {
            return existing || false;
          },
        }
      }
    },
    WorkizContainer: {
      fields: {
        selected: {
          read(existing) {
            return existing || false;
          },
        }
      }
    },
    InvitedUser: {
      fields: {
        selected: {
          read(existing) {
            return existing || false;
          },
        }
      }
    },
    Query: {
      fields: {
        getAllUsers: {
          merge(_, incoming) {
            return incoming;
          }
        },
        getAllInvitedUsers: {
          merge(_, incoming) {
            return incoming;
          }
        },
        getMessages: {
          merge(_, incoming) {
            return incoming
          }
        },
      }
    },
    FindRequestsForUpdateCar: {
      fields: {
        tab: {
          read(exist) {
            return exist || ConfirmInventoryTabs.WAITING_FOR_CONSIDERATION
          }
        }
      }
    },
    UserWithSchedule: {
      fields: {
        schedules_formatted: {
          read(_, { readField, cache }) {
            const schedules = readField('schedules') as { __ref: string }[]

            return formatSchedules(cache, schedules)
          }
        },
        schedule_requests_formatted: {
          read(_, { readField, cache }) {
            const schedules = readField('schedule_requests') as { __ref: string }[]

            return formatSchedules(cache, schedules)
          }
        },
        schedule_requests: {
          merge(existing = [], incoming = [], { mergeObjects }) {
            return [...incoming]
          }
        },
        schedules: {
          merge(existing = [], incoming = [], { mergeObjects }) {
            return [...incoming]
          }
        },
        notes: {
          merge(existing = [], incoming = [], { mergeObjects }) {
            return [...incoming]
          }
        },
      },
    }
  }
});


export function formatSchedules(cache: InMemoryCache | ApolloCache<any>, schedules: { __ref: string }[]) {
  const initial_days: UserWithScheduleFormatted = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  }

  schedules.forEach(schedule => {
    const data = cache.extract()[cache.identify(schedule)!] as UserSchedule

    const current_day = moment.unix(data.work_from).format('dddd') as string;

    initial_days[current_day].push({
      ...data,
      work_from: moment.unix(data.work_from).format('hh:mm a'),
      work_to: moment.unix(data.work_to).format('hh:mm a')
    })
  })

  return initial_days
}
