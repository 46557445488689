import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-burger-button',
  templateUrl: './burger-button.component.html',
  styleUrls: ['./burger-button.component.scss']
})
export class BurgerButtonComponent {
  @Output('handle-click') click = new EventEmitter<void>();
  @Input() is_open: boolean;

  public onClick() {
    this.click.emit();
  }
}
