<div class="wrap-player" *ngIf="!showSpeech">
  <wrapper-loading [loading]="loading">
    <title-head title="Audio record" (clickCross)="onClose()" />

    <player [audio_link]="data.call.audio" />

    <p
      class="transcript Lexend-14 blue regular"
      *ngIf="!!text"
      (click)="showSpeech = true"
    >
      Read transcript
      <arrow-icon />
    </p>

    <p
      class="transcript Lexend-14 blue regular"
      (click)="speechToText()"
      *ngIf="!text"
    >
      Speech to text
    </p>
  </wrapper-loading>
</div>

<transcript
  *ngIf="showSpeech"
  [text]="text"
  (clickBack)="onClickBack()"
  (clickCross)="onClose()"
/>
