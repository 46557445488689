<wrapper-loading [loading]="loading">
  <div class="wrap" *ngIf="company">
    <h2 class="Lexend-24 regular">{{ company.name }}</h2>
    <mat-tab-group class="tabs">
      <mat-tab label="General info">
        <company-form [company]="company" *ngIf="company" />
      </mat-tab>
      <mat-tab label="Reviews" *ngIf="company">
        <ng-container *ngIf="company.reviews.length > 0; else noReviews">
          <reviews [reviews]="company.reviews" />
        </ng-container>
        <ng-template #noReviews>
          <div class="emptyReviews">
            <search-in-circle />
            <p class="Lexend-24 regular dark-blue">
              You don’t have reviews yet
            </p>
            <p class="Lexend-18 regular grey">
              Reviews appear after somebody left them <br />
              on the website
            </p>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</wrapper-loading>
