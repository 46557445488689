import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from './modules/authorization/authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild('elseTemplate') elseTemplate: any;
  public loading: boolean = true
  constructor(
    private readonly authService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser()
      .subscribe({
        next: ({ loading }) => {
          this.loading = loading;
        },
        error: (err) => {
          this.loading = false;
        },
      })
  }

  callAlert() {
    alert({ type: 'success', message: 'test' })
  }
}
