import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ReviewsStatus } from 'src/app/enum/reviews-status.enum';
import {
  IReviewReviewsDto,
  IReviewReviewsResponse,
  REVIEW_REVIEW,
} from 'src/app/pages/slk-admin/apollo/mutation/review-review';

@Injectable()
export class ReviewsService {
  constructor(private readonly apollo: Apollo) {}

  public reviewReview(id: string, status: ReviewsStatus) {
    this.apollo
      .mutate<IReviewReviewsResponse, IReviewReviewsDto>({
        mutation: REVIEW_REVIEW,
        variables: { reviews: { id, status } },
        update: (cache) => {
          status === ReviewsStatus.REJECTED
            ? cache.modify({
                id: `Locksmith:${id}`,
                fields: {
                  reviews(exist:  readonly { __ref: string }[]) {
                    return exist.filter((item) => !item.__ref.includes(id));
                  },
                },
              })
            : cache.modify({
                id: `Reviews:${id}`,
                fields: {
                  status() {
                    return status;
                  },
                },
              });
        },
      })
      .subscribe();
  }
}
