<form (submit)="onSubmit()" class="gridGap" [formGroup]="form">
  <h1>Settings</h1>

  <mat-form-field>
    <mat-label>Max tokens</mat-label>
    <input
      matInput
      type="number"
      placeholder="e.g. 4000"
      formControlName="max_tokens"
    />

    <mat-error *ngIf="max_tokens?.hasError('min')">
      The minimum value is <strong>1</strong>
    </mat-error>

    <mat-error *ngIf="max_tokens?.hasError('max')">
      The maximum value is <strong>700</strong>
    </mat-error>

    <mat-error *ngIf="max_tokens?.hasError('required')">
      This field is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Workiz cookies</mat-label>
    <input
      matInput
      type="text"
      placeholder="Enter key here"
      formControlName="workiz_cookie"
    />

    <mat-error *ngIf="workiz_cookie?.hasError('required')">
      This field is <strong>required</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Workiz Arizona cookies</mat-label>
    <input
      matInput
      type="text"
      placeholder="Enter key here"
      formControlName="workiz_arizona_cookie"
    />

    <mat-error *ngIf="workiz_arizona_cookie?.hasError('required')">
      This field is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <button mat-raised-button type="submit">Save changes</button>
</form>
