import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs";
import { AlertService } from "src/app/modules/ui-kit/alert/alert.service";
import { GET_SYSTEM_SETTINGS, ISystemSettingsResponse } from "../settings/apollo/query/get-system-settings";
import { CREATE_FINE_TUNE_ITEM, ICreateFineTuneItemDto, ICreateFineTuneItemResponse } from "./apollo/mutation/create-fine-tune-item.mutation";
import { ChatType, ISendMessageDto, ISendMessageResponse, SEND_MESSAGE } from "./apollo/mutation/send-message.mutation";
import { IStartUpdateJobsInfoDto, IStartUpdateJobsInfoResponse, START_UPDATE_JOBS_INFO } from "./apollo/mutation/start-update-jobs-info";
import { GET_ALL_MESSAGES, IGetAllMessagesDto, IGetAllMessagesResponse } from "./apollo/query/get-all-messages.query";

@Injectable()
export class ChatService {
  constructor(
    private readonly apollo: Apollo,
    private readonly alertService: AlertService,
  ) { }

  public getAllMessages(pagination: IGetAllMessagesDto['pagination']) {
    return this.apollo.watchQuery<IGetAllMessagesResponse, IGetAllMessagesDto>({
      query: GET_ALL_MESSAGES,
      variables: { pagination },
    }).valueChanges
  }

  public sendMessage(prompt: string, chat_type: ChatType) {
    return this.apollo.mutate<ISendMessageResponse, ISendMessageDto>({
      mutation: SEND_MESSAGE,
      variables: { prompt, chat_type },
      updateQueries: {
        getMessages: (prev, { mutationResult }) => {
          const prevData = prev as IGetAllMessagesResponse;
          return {
            getMessages: { items: [mutationResult.data!.sendMessage, ...prevData.getMessages.items], total: prevData.getMessages.total + 1 }
          }
        }
      }
    })
  }


  public createFineTuneItem(prompt: string, text: string) {
    return this.apollo.mutate<ICreateFineTuneItemResponse, ICreateFineTuneItemDto>({
      mutation: CREATE_FINE_TUNE_ITEM,
      variables: { prompt, text, },
      updateQueries: {
        getFineTuneList: (existing, { mutationResult }) => {
          const { getFineTuneList } = existing as { getFineTuneList: { __ref: string }[] };
          return { getFineTuneList: [...getFineTuneList, { __ref: `FineTuneItem:${mutationResult.data!.createFineTuneItem.id}` }] };
        },
      }
    })
  }

  public startUpdateJobsInfo(variables: IStartUpdateJobsInfoDto) {
    return this.apollo.mutate<IStartUpdateJobsInfoResponse, IStartUpdateJobsInfoDto>({
      mutation: START_UPDATE_JOBS_INFO, variables,
      update: (cache) => {
        this.getSystemSettings().subscribe((data) => {
          cache.modify({
            id: cache.identify(data),
            fields: {
              is_parsing: () => {
                return true
              }
            }
          })
        })

      }
    }).subscribe({
      complete: () => {
        this.alertService.alert({ type: 'success', message: 'Parsing data in progress' })
      },
      error: () => {
        this.alertService.alert({ type: 'warning', message: 'Parsing data failure' })
      }
    })
  }
  public getSystemSettings() {
    return this.apollo
      .watchQuery<ISystemSettingsResponse, {}>({ query: GET_SYSTEM_SETTINGS })
      .valueChanges.pipe(map(({ data }) => data.getSystemSettings));
  }

}
