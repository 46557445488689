import { gql } from "apollo-angular";
import { UserInfo } from "src/app/entity/schedule/user-info.entity";

export const CREATE_OR_UPDATE_USER_INFO = gql`
  mutation createOrUpdateUserInfo($user_infos: [CreateOrUpdateUserInfoDto!]!) {
    createOrUpdateUserInfo(user_infos:$user_infos) {
      id
      key
      user_id
      value
    }
  }
`

export type ICreateOrUpdateUserInfoDto = {
  user_infos: UserInfo[];
}

export type ICreateOrUpdateUserInfoResponse = {
  createOrUpdateUserInfo: UserInfo[];
}
