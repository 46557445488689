import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_ALL_JOBS_WORKIZ, IGetAllJobsWorkizDto, IGetAllJobsWorkizResponse } from "./apollo/query/get-all-jobs-workiz.query";
import { map } from "rxjs";
import { ICtmGetCallsOptionDto, GET_CALLS, ICtmGetCallsOptionResponse } from "./apollo/query/get-calls.query";
import { FIND_TRANSCRIPTION, IFindTranscriptionResponse, IFindTranscriptionDto } from "./apollo/query/find-transcription.query";
import { ISpeechToTextResponse, ISpeechToTextDto, SPEECH_TO_TEXT } from "./apollo/mutation/speech-to-text.mutation";

@Injectable()
export class ApiService {
  constructor(
    private readonly apollo: Apollo
  ) { }

  public getAllJobsWorkiz({ offset = 1, records = 50 }: IGetAllJobsWorkizDto) {
    return this.apollo.watchQuery<IGetAllJobsWorkizResponse, IGetAllJobsWorkizDto>({
      query: GET_ALL_JOBS_WORKIZ,
      variables: { offset, records },
    }).valueChanges.pipe(map((r) => r.data.getAllJobsWorkiz))
  }

  public getCalls({ page = 1, per_page = 40 }: ICtmGetCallsOptionDto['ctmGetCallsOptionDto']) {
    return this.apollo.watchQuery<ICtmGetCallsOptionResponse, ICtmGetCallsOptionDto>({
      query: GET_CALLS,
      variables: { ctmGetCallsOptionDto: { page, per_page } },
    }).valueChanges.pipe(map((r) => r.data.getCalls))
  }

  public findTranscription(call_id: string) {
    return this.apollo.watchQuery<IFindTranscriptionResponse, IFindTranscriptionDto>({
      query: FIND_TRANSCRIPTION,
      variables: { call_id },
    }).valueChanges.pipe(map((r) => r.data.findTranscription))
  }

  public speechToText(call_id: string) {
    return this.apollo.mutate<ISpeechToTextResponse, ISpeechToTextDto>({
      mutation: SPEECH_TO_TEXT,
      variables: { call_id },
    })
  }
}
