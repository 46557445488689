import { NgModule } from '@angular/core';
import { SideMenuComponent } from './component/side-menu.component';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../icons/icons.module';

@NgModule({
  imports: [CommonModule, IconsModule],
  declarations: [SideMenuComponent],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
