import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { IconsModule } from '../../icons/icons.module';
import { DeleteDialogComponent } from './component/delete-dialog.component';

@NgModule({
  imports: [MatDialogModule, IconsModule, MatButtonModule],
  declarations: [DeleteDialogComponent]
})
export class DeleteDialogModule {}
