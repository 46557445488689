<div class="filters" (clickOutside)="setOpen(false)">
  <button
    class="open-filter pointer"
    (click)="setOpen(!open_filters)"
    [ngClass]="{ active: open_filters || selected.length > 0 }"
  >
    <filter-icon />
    Filters
    <span class="Lexend-14 regular" *ngIf="selected.length > 0">
      {{ selected.length }}
    </span>
  </button>

  <form
    class="box"
    [formGroup]="form"
    (change)="onValueChanges()"
    *ngIf="open_filters"
  >
    <p class="Lexend-18 regular">Filters</p>
    <button class="underline clear" type="reset" (click)="onClear()" mat-button>
      Clear all
    </button>
    <drop-down
      [formGroupName]="'Working time'"
      [filters]="{ title: 'Working time', items: working_time }"
    />
    <drop-down
      [formGroupName]="'State'"
      [filters]="{ title: 'State', items: states }"
    />
  </form>
</div>
