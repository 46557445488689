import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'format_duration_audio' })
export class FormatDurationAudioPipe implements PipeTransform {
  transform(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }
}

