import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconsModule } from '../../icons/icons.module';
import { PasswordComponent } from './component/password-input.component';

@NgModule({
  declarations: [PasswordComponent],
  imports: [
    CommonModule,
    IconsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
  ],

  exports: [PasswordComponent],
})
export class PasswordInputModule {}
