import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConstructorService } from '../constructor.service';

@Pipe({
  name: 'getValidity',
})
export class GetValidityPipe implements PipeTransform {
  constructor(private readonly constructorService: ConstructorService) {}
  transform(form: FormGroup[]): any {
    return this.constructorService.checkValidity(form);
  }
}
