import { gql } from "apollo-angular";

export const DELETE_MANY_USER_INFO = gql`
  mutation deleteManyUserInfo ($ids: [ID!]!){
    deleteManyUserInfo(ids: $ids)
  }
`


export type IDeleteManyUserInfoDto = {
  ids: string[]
}

export type IDeleteManyUserInfoResponse = {
  deleteManyUserInfo: string[]
}
