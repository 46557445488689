import { gql } from "apollo-angular";
import { ItemTemplate } from "src/app/entity/item_template.entity";

export const GET_TEMPLATE_BY_ID = gql`
  query getTemplateById ($id: ID!) {
    getTemplateById(id: $id) {
      id
      name
      items {
        id
        quantity
        sku
        uhs_sku
        template_id
      }
    }
  }
`;

export interface IGetTemplateByIdDto {
  id: string;
}

export interface IGetTemplateByIdResponse {
  getTemplateById: {
    id: string;
    name: string;
    items:  ItemTemplate[];
  }
}


