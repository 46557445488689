import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Provider, SkipSelf } from '@angular/core';

export function controlProviderFactory(container: ControlContainer) {
  return container;
}

// This provider creates a connection between the parent component's form and the current component's
// form directives. Should be put in the component decorator's viewProviders array.
// Nested form parts MUST NOT use ChangeDetectionStrategy.OnPush!
export const CONTROL_CONTAINER: Provider = {
  provide: ControlContainer,
  useFactory: controlProviderFactory,
  deps: [[new SkipSelf(), ControlContainer]],
};


@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  viewProviders: [CONTROL_CONTAINER]
})
export class PasswordComponent {
  @Input() controlName: string = 'password';
  @Input() label: string = 'Current password';

  public hide: boolean = true;
  public control: any

  constructor(public readonly controlContainer: ControlContainer) {}
}

export const password_pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
