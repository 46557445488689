import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { CreateLocksmithDto } from '../../apollo/mutation/create-locksmith';
import {
  IGetLocksmithByIdDto,
  IGetLocksmithByIdResponse,
} from '../../apollo/query/get-locksmith-by-id';

@Injectable()
export abstract class FormAbstractService {
  public abstract loading?: boolean;
  public abstract locksmith?: Locksmith;
  public abstract submitForm(new_locksmith: Locksmith): void;
  public abstract getLocksmithById?(variables?: IGetLocksmithByIdDto): void;
  public abstract deleteLocksmith(id: string): void;
}
