<title-head
  title="Transcript"
  [showBack]="true"
  (clickBack)="onBack()"
  (clickCross)="onClose()"
/>

<div class="wrapText text Lexend-16 grey regular">
  {{ text }}
</div>
