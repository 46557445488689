<div class="accept-invite">
  <logo-icon class="logo" />
  <div class="wrapLogin">
    <form class="from" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <h1 class="Lexend-32 regular center">Accept invite</h1>
      <mat-form-field>
        <mat-label>Enter your name</mat-label>

        <input
          matInput
          type="text"
          placeholder="Enter your name"
          formControlName="name"
        />

        <mat-error *ngIf="name.hasError('required')">
          Name <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <password-input
        ngDefaultControl
        controlName="password"
      />

      <button
        mat-raised-button
        class="button"
        [disabled]="registerForm.invalid"
        type="submit"
      >
        Register
      </button>
    </form>
  </div>
</div>
