import { Component, Input } from '@angular/core';
import { Locksmith } from 'src/app/entity/locksmith.entity';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent {
  @Input() schedule: Locksmith['schedules'];
}
