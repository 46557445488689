<section class='secAboutCity'>
  <a id="about-city" class='anchor' ></a>
  <div class='aboutCity'>
    <div class='secondMainSpan'>
      <h2 >{{ data.headline }}</h2>
      <hr />
    </div>
    <div class='cityTextBlock'>
      <p class='text' *ngFor="let text of description">{{text}}</p>
    </div>
  </div>
</section>

