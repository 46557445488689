<upload class="wrap-upload"
  [accept]="['image/png', 'image/jpg', 'image/jpeg', 'image/heif', 'image/heic', 'image/webp']" label="File"
  (onUpload)="onChange($event)" *ngIf="!file_exist">
  <app-upload-view />
</upload>

<div class="wrap-fields" [formGroup]="$any(control)" [ngClass]="{
  hide: !file_exist
}">
  <p class="Lexend-14">File</p>
  <div class="wrap-image">
    <img [src]="file_id | getImageUrlByPhotoID : file" alt="image" />
    <cross-icon class="cross pointer" (click)="reset()" />
  </div>

  <mat-form-field>
    <mat-label> Alt </mat-label>
    <input matInput type="text" placeholder="Type alt here" formControlName="alt" />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Title </mat-label>
    <input matInput type="text" placeholder="Type title here" formControlName="title" />
  </mat-form-field>
</div>