import { gql } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { Schedule } from 'src/app/entity/schedule.entity';

export const CREATE_LOCKSMITH = gql`
  mutation createLocksmith($locksmith: CreateLocksmithDto!) {
    createLocksmith(locksmith: $locksmith) {
      id
    }
  }
`;

export type CreateLocksmithDto = {
  locksmith: Locksmith;
};

export type CreateLocksmithResponse = {
  locksmith: Locksmith;
};
