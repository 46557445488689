import { Component, OnInit } from '@angular/core';
import { ManageUsersService, SelectUserArgs } from '../manage-users.service';
import { Observable } from 'rxjs';
import { IGetAllUsersResponse } from '../apollo/query/get-all-users.query';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserRole } from 'src/app/enum/user-role.enum';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  public $users: Observable<IGetAllUsersResponse>;

  constructor(private readonly manageUsersService: ManageUsersService) {}

  get getIsOpenMenu() {
    return this.manageUsersService.getIsOpenMenu;
  }

  get getEditUser() {
    return this.manageUsersService.getEditUser;
  }

  get sidebarTitle() {
    return this.getEditUser ? 'Edit member' : 'Invite member'
  }

  ngOnInit(): void {
    this.$users = this.manageUsersService.getUsers()
  }

  public toggleSideMenu() {
    if (this.getEditUser) {
      this.manageUsersService.setEditUser = undefined;
    } else {
      this.manageUsersService.toggleSideMenu();
    }
  }

  public handleSelectUser(
    query_name: SelectUserArgs['query_name'],
    args: Omit<SelectUserArgs, 'query_name'>
  ): void {
    this.manageUsersService.selectUser({ query_name, ...args });
  }

  public selectedTabChange(e: MatTabChangeEvent): void {
    this.manageUsersService.setCurrentTab = e.tab.textLabel;
  }
}
