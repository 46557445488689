import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CarsService } from '../../../cars.service';
import { IGenerateDifferenceReportResponse } from '../../../apollo/query/generate-difference-report.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ICreateRequestForCarItemsDto } from '../../../apollo/mutatuin/create-request-for-car-items.mutation';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'send-to-update',
  templateUrl: './send-to-update.component.html',
  styleUrls: ['./send-to-update.component.scss']
})
export class SendToUpdateComponent implements OnInit {
  public displayedColumns: string[] = ['slk_sku', 'uhs_sku', 'difference', 'actions'];
  public form: FormGroup = new FormGroup({});
  public loading: boolean = true

  @Input() containerId: string;
  @Output() containerIdChange = new EventEmitter<string>();

  public difference_items: IGenerateDifferenceReportResponse['generateDifferenceReport'];

  constructor(
    public dialogRef: MatDialogRef<SendToUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private readonly carsService: CarsService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.carsService.generateDifferenceReport(this.data.id)
      .subscribe(r => {
        this.difference_items = r.map(item => ({ ...item, difference: Math.abs(item.difference) }));

        this.difference_items.forEach((item) => {
          this.form.addControl(item.template_item_id, new FormControl(item.difference))
        });

        this.loading = false;
      })
  }

  public onDelete(index: number, template_item_id: string) {
    this.difference_items = this.difference_items.filter((_, i) => i !== index);
    this.form.removeControl(template_item_id);
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onCreate() {
    const request_data = Object.entries(this.form.value)
      .map(([item_template_id, requested_quantity]) => ({ item_template_id, requested_quantity })) as ICreateRequestForCarItemsDto['createUpdateCarDto']

    this.carsService.createRequestForCarItems(request_data).subscribe({
      complete: () => {
        this.alertService.alert({
          message: "Keys have successfully sent to technician for approve",
          type: "success"
        })
        this.onClose();
      }
    })
  }

}
