import { Component, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
interface DropDownItem {
  title: string;
  items: string[];
}
@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent {
  @Input() filters: DropDownItem;
  constructor (public readonly controlContainer: ControlContainer) {}

  public open: boolean = false;

  public toggleOpen() {
    this.open = !this.open;
  }
}
