<div class="wrap-schedule-table">
  <table mat-table class="mat-elevation-z8" [dataSource]="data || []">
    <ng-container *ngFor="let name of displayed_columns" [matColumnDef]="name">
      <th mat-header-cell *matHeaderCellDef style="width: 200px">
        {{ name | lowercase }}
      </th>
      <td mat-cell *matCellDef="let ceil" [attr.data-name]="name | lowercase">
        {{ ceil[name] }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayed_columns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayed_columns"></tr>
  </table>
</div>
