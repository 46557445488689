<ng-container [formGroup]="$any(controlContainer.control)">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <input
      matInput
      class="password-input"
      placeholder="Enter password"
      [type]="hide ? 'password' : 'text'"
      [formControlName]="controlName"
      />

    <mat-error *ngIf="controlContainer.control!.get(controlName)!.hasError('required')">
      Password <strong>required</strong>
    </mat-error>

    <mat-error *ngIf="controlContainer.control!.get(controlName)!.hasError('pattern')">
      The password should contain 8 characters, one symbol, one uppercase letter
      and one number
    </mat-error>

    <div class="eye_icon pointer" (click)="hide = !hide">
      <open-eye-icon *ngIf="hide; else elseTemplate" />

      <ng-template #elseTemplate>
        <close-eye-icon />
      </ng-template>
    </div>
  </mat-form-field>
</ng-container>
