import { gql } from 'apollo-angular';

export const DELETE_LOCKSMITH = gql`
  mutation ($id: ID!) {
    deleteLocksmithById(id: $id)
  }
`;

export type IDeleteLocksmithDto = {
  id: string;
};

export type IDeleteLocksmithResponse = {
  id: string;
};
