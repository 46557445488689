import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-our-services-fields',
  templateUrl: './our-services-fields.component.html',
  styleUrls: ['./our-services-fields.component.scss']
})
export class OurServicesFieldsComponent {
  @Output() removeItem = new EventEmitter<number>();

  constructor(
    public readonly controlContainer: ControlContainer
  ) { }

  public removeBlock() {
    this.removeItem.emit()
  }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }
}
