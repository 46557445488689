import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserWithSchedule } from "src/app/entity/schedule/user-with-schedules.entity";
import { TimeTemplate } from "src/app/entity/time-template.entity";
import { UserRole } from "src/app/enum/user-role.enum";
import { DayAbstractService } from "../../../components/day/day-abstract.service";
import { MyScheduleService } from "./my-schedule.service";


@Injectable()
export class DayMyScheduleService implements DayAbstractService {

  constructor(
    private readonly scheduleService: MyScheduleService,
  ) { }

  public get startWeek() {
    return this.scheduleService.startWeek
  }

  public get acceptable_edit_roles(): UserRole[] {
    return this.scheduleService.acceptable_edit_roles;
  }

  public formatDate(time: string, dayIndex: number): number {
    return this.scheduleService.formatDate(time, dayIndex);
  }

  public createOrUpdateManyUserSchedules(user_schedules: { id?: string | undefined; work_from: number; work_to: number; user_id: string; }[], user: UserWithSchedule): void {
    this.scheduleService.createOrUpdateManyUserSchedules(user_schedules, user)
  }

  public deleteManyUserSchedules(ids: string[], user: UserWithSchedule): void {
    this.scheduleService.deleteManyUserSchedules(ids, user)
  }

  public getAllTimeTemplates(): Observable<TimeTemplate[]> {
    return this.scheduleService.getAllTimeTemplates();
  }

  // public updateTimeTemplate(time_template: TimeTemplate): void {
  //   this.scheduleService.updateTimeTemplate(time_template);
  // }

  // public deleteTimeTemplate(id: string): void {
  //   this.scheduleService.deleteTimeTemplate(id);
  // }

  // public createTimeTemplate(time_template: { color: string; name: string; value: string; }): void {
  //   this.scheduleService.createTimeTemplate(time_template);
  // }

}
