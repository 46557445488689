import { gql } from "apollo-angular";
import { UserSchedule } from "src/app/entity/schedule/user-schedule.entity";

export const CREATE_OR_UPDATE_MANY_USERS_SCHEDULES = gql`
  mutation createOrUpdateManyUsersSchedules(
    $user_schedules: [CreateOrUpdateUserScheduleDto!]!
  ) {
    createOrUpdateManyUsersSchedules(user_schedules: $user_schedules) {
      id
      user_id
      work_from
      work_to
    }
  }
`


export type ICreateOrUpdateManyUsersSchedulesDto = {
  user_schedules: {
    id?: string;
    work_from: number;
    work_to: number;
    user_id: string;
  }[]
}

export type ICreateOrUpdateManyUsersSchedulesResponse = {
  createOrUpdateManyUsersSchedules: UserSchedule[]

}
