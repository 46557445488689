import { gql } from "apollo-angular";
import { UserNote } from "src/app/entity/schedule/user-note.entity";

export const CREATE_OR_UPDATE_USER_NOTE = gql`
  mutation createOrUpdateUserNote($user_note: CreateOrUpdateUserNoteDto!) {
    createOrUpdateUserNote(user_note: $user_note) {
      id
      note
      user_id
      week_end
      week_start
    }
  }
`


export type ICreateOrUpdateUserNoteDto = {
  user_note: UserNote;
}


export type ICreateOrUpdateUserNoteResponse = {
  createOrUpdateUserNote: UserNote
}
