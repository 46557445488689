import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropDown implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }

  @Input() name: string;
  @Input() showContent: boolean = false;
  @Input() show_remove_icon: boolean = true;


  @Output() removeItem = new EventEmitter<void>();
  @Output() toggleContentChanges = new EventEmitter<void>();

  public remove_icon_pressed = false;

  public removeBlock() {
    this.removeItem.emit();
  }

  public setRemovePressed(state: boolean) {
    this.remove_icon_pressed = state;
  }

  public toggleRemovePressed() {
    this.remove_icon_pressed = !this.remove_icon_pressed;
  }

  public toggleContent() {
    this.toggleContentChanges.emit()
    this.showContent = !this.showContent;
  }

  public closeContent() {
    this.showContent = false;
  }
}
