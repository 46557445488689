import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'company-logo',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements AfterViewInit {
  @Input() form: FormGroup;

  default_logo: string = '/assets/company-default-logo/default.png';
  loading: boolean = false;

  @ViewChild('img') img: ElementRef<HTMLImageElement>;

  constructor(public readonly controlContainer: ControlContainer) { }

  ngAfterViewInit(): void {
    // console.log(this.form.value);

    if (this.form.get('photo')?.value && this.img.nativeElement) {
      this.img.nativeElement.src = this.form.get('photo')?.value;
    }
  }

  get photoFile() {
    if (this.form.get('file')?.value) {
      return URL.createObjectURL(this.form.get('file')?.value);
    } else return null;
  }

  handleUrl(e: Event) {
    const { value } = e.target as HTMLInputElement;
    this.form.get('file')?.patchValue(null);
    this.updateImgUrl(value);
  }

  updateImgUrl(new_img: string | null) {
    if (new_img) {
      this.img.nativeElement.src = new_img;
    }
  }

  errorHandle(event: ErrorEvent) {
    (event?.target as HTMLImageElement).src = this.default_logo;
  }

  uploadPhoto(file_list: FileList | null) {
    if (file_list && file_list.length > 0) {
      this.form.get('file')?.patchValue(file_list[0]);
      this.form.get('photo')?.patchValue('');
      this.updateImgUrl(this.photoFile);
    }
  }
}
