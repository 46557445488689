import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { ProfileService } from '../../profile.service';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  public nameForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly profileService: ProfileService,
    private readonly alertService: AlertService,
    private readonly authService: AuthorizationService
  ) { }


  ngOnInit(): void {
    this.setUserName();
  }

  public resetPasswordChange() {
    this.setUserName();
  }

  public get name() {
    return this.nameForm.get('name')!;
  }

  public onSubmit() {
    const { name } = this.nameForm.value;
    if (name) {
      this.profileService.updateCurrentUser({ name })
        .subscribe({
          complete: () => {
            this.alertService.alert({
              message: 'Name was successfully changed',
              type: 'success',
            });
          },
        });
    }
  }

  private setUserName() {
    this.authService.getCurrentUser()
      .subscribe({
        next: (r) => {
          this.nameForm.patchValue({
            name: r.data.getCurrentUser.name,
          });
        },
      });
  }

}
