import { Component, Input, OnInit } from '@angular/core';
import { ChatService } from '../../../../chat.service';
import { Message } from 'src/app/entity/message.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {
  @Input() message: Message;
  public database_result_exist: boolean = false;

  constructor(
    private readonly chatService: ChatService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    const parse_string: any[] | null = JSON.parse(this.database_result || 'null');

    this.database_result_exist = !!parse_string && parse_string.length > 0;
  }

  public get database_result(): string | null {
    return this.message.database_result
  }

  public sendToTeaching() {
    this.chatService.createFineTuneItem(this.message.prompt, this.message.text).subscribe({
      complete: () => this.alertService.alert({ type: 'success', message: 'Item successfully sent to teaching' }),
      error: (e) => this.alertService.alert({ type: 'error', message: e.message })
    });
  }
}
