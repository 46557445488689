import { Component, OnInit } from '@angular/core';

import { PageEvent } from '@angular/material/paginator';
import { AllLocksmithAbstractService } from '../../services/all-locksmith-abstract.service';

@Component({
  selector: 'all-locksmith',
  templateUrl: './all-locksmith.component.html',
  styleUrls: ['./all-locksmith.component.scss'],
})
export class AllLocksmithComponent implements OnInit {
  constructor(
    private readonly allLocksmithService: AllLocksmithAbstractService
  ) {}

  ngOnInit() {
    this.allLocksmithService.getLocksmith();
  }

  public setSearchValue(e: Event) {
    const { value } = e.target as HTMLInputElement;
    this.allLocksmithService.searchLocksmith(value);
  }

  get btn_name() {
    return this.allLocksmithService.item_btn_name;
  }

  get pageSize() {
    return this.allLocksmithService.page_size;
  }

  get length() {
    return this.allLocksmithService.total_length;
  }

  get pageIndex() {
    return this.allLocksmithService.page_index;
  }

  get loading() {
    return this.allLocksmithService.loading;
    return false;
  }

  get confirmed_locksmith() {
    console.log(this.allLocksmithService.confirmed_locksmith);

    return this.allLocksmithService.confirmed_locksmith;
  }

  onPageChange(event: PageEvent) {
    this.allLocksmithService.togglePage(event);
  }
}
