import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { password_pattern } from 'src/app/modules/ui-kit/password-input/component/password-input.component';
import { ProfileService } from '../../profile.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
  public hide_current: boolean = true;
  public hide_new: boolean = true;

  public passwordForm = new FormGroup({
    current_password: new FormControl('', [
      Validators.required,
      Validators.pattern(password_pattern),
    ]),
    new_password: new FormControl('', [
      Validators.required,
      Validators.pattern(password_pattern),
    ]),
  });

  constructor(
    private readonly profileService: ProfileService,
    private readonly alertService: AlertService
  ) { }

  public get current_password() {
    return this.passwordForm.get('current_password')!;
  }

  public get new_password() {
    return this.passwordForm.get('new_password')!;
  }

  public onSubmit() {
    const { current_password: old_password, new_password } = this.passwordForm.value;

    if (this.passwordForm.valid && old_password && new_password) {
      this.profileService
        .changePassword({ old_password, new_password })
        .subscribe({
          complete: () => {
            this.passwordForm.reset();
            this.alertService.alert({
              message: 'Password was successfully changed',
              type: 'success',
            });
          },
          error: () => {
            this.alertService.alert({
              message: 'Oops something went wrong',
              type: 'error',
            });
          },
        });
    }
  }
}
