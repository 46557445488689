import { Component, Input } from '@angular/core';

@Component({
  selector: 'pointer',
  templateUrl: './pointer.component.html',
  styleUrls: ['./pointer.component.scss'],
})
export class PointerComponent {
  @Input() testSpec: boolean | null;
}
