<div class="background_block" [style]="CSSVariables">
  <div class="coverComponent add_padding_by_background_exist">
    <app-need-more-info-button *ngIf="parsed_styles?.add_top_need_more_info_button" />
    <app-title-one [headline]="data.headline" *ngIf="data.headline" />
    <div class="coverLastBlock">
      <app-text-block *ngIf="data.description" [text]="data.description" class="coverText" />
      <app-switch-list *ngIf="data.first_list" [style]="css_variables_first_list" [list]="data.first_list || ''"
        [quantity_columns]="parsed_styles?.first_list_grid_quantity_columns!"
        [switch_to_number]="parsed_styles?.first_list_number_list!"
        [grid_revers]="parsed_styles?.first_text_grid_revers!" />
    </div>
    <div class="coverBody">
      <div [ngClass]="{
          coverTextBlockGrided: parsed_styles?.body_grid_revers,
          coverTextBlock: !parsed_styles?.body_grid_revers
        }" class="full_block_text">
        <app-text-block *ngIf="data.body_text" [text]="data.body_text" class="coverText" />
        <app-switch-list *ngIf="data.list" [style]="css_variables_main_list" [list]="data.list"
          [quantity_columns]="parsed_styles?.main_list_grid_quantity_columns!"
          [switch_to_number]="parsed_styles?.main_list_number_list!"
          [grid_revers]="parsed_styles?.body_text_grid_revers!" />
      </div>

      <div [ngClass]="{
          coverTextBlockTwoGrided: parsed_styles?.body_grid_revers,
          coverTextBlockTwo: !parsed_styles?.body_grid_revers
        }" *ngIf="img_exist">
        <img draggable="false" [src]="data.photo.file_id | getImageUrlByPhotoID: data.photo.file" class="bodyImg"
          [alt]="data.photo.alt" [title]="data.photo.title" />
      </div>
    </div>

    <div class="coverLastBlock" *ngIf="data.last_text || data.last_list">
      <app-text-block *ngIf="data.last_text" [text]="data.last_text || ''" class="coverLastText" />
      <app-switch-list *ngIf="data.last_list" [style]="css_variables_last_list" [list]="data.last_list || ''"
        [quantity_columns]="parsed_styles?.last_list_grid_quantity_columns!"
        [switch_to_number]="parsed_styles?.last_list_number_list!"
        [grid_revers]="parsed_styles?.last_text_grid_revers!" />
    </div>
    <img *ngIf="img_exist" draggable="false" class="mobileImage"
      [src]="data.photo.file_id | getImageUrlByPhotoID: data.photo.file" [alt]="data.photo.alt"
      [title]="data.photo.title" />
    <app-need-more-info-button *ngIf="parsed_styles?.add_bottom_need_more_info_button" />
  </div>
</div>