import { setContext } from '@apollo/client/link/context';

const access_token = () =>
  typeof window !== 'undefined' ? localStorage.getItem('access_token') : null;

export const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(access_token() && { authorization: 'Bearer ' + access_token() }),
      'Apollo-Require-Preflight': true,
    },
  };
});
