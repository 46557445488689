import { Injectable } from "@angular/core";
import { NavigationAbstractService } from "../../../components/navigation/navigation.abstract.service";
import { MyScheduleService } from "./my-schedule.service";


@Injectable()
export class ChangeDateService implements NavigationAbstractService {
  constructor(
    private readonly scheduleService: MyScheduleService,
  ) { }
  public name = 'my schedule'


  public get disabled() {
    return this.scheduleService.disabled;
  }

  public get startWeek() {
    return this.scheduleService.startWeek
  }

  public get endWeek() {
    return this.scheduleService.endWeek
  }

  public get weekStartDate() {
    return this.scheduleService.weekStartDate
  }

  public get weekEndDate() {
    return this.scheduleService.weekEndDate
  }

  public setWeekIndex(week_index: number) {
    this.scheduleService.setWeekIndex(week_index);
  }

  public setCalendarIndex({ start }: { start: moment.Moment, end: moment.Moment }) {
    const calc_current_week = start.clone().startOf('isoWeek').week() - this.scheduleService.currentWeek.clone().startOf('isoWeek').week();

    this.setWeekIndex(calc_current_week);
  }

}
