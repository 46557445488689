import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { UserRole } from 'src/app/enum/user-role.enum';
import { CarsService } from 'src/app/pages/cars/cars.service';
import { AuthorizationService } from '../authorization.service';
import { TechnicianGuard } from './technician.guard';

@Injectable()
export class RoleGuard {
  private readonly error_href = {
    [UserRole.ADMIN]: '/chat/',
    [UserRole.DISPATCHER]: '/schedule/',
    [UserRole.MAIN_DISPATCHER]: '/schedule/',
    [UserRole.TECHNICIAN]: '/profile/',
    [UserRole.LOGISTIC]: '/profile/',
    [UserRole.HR]: '/profile/',
    [UserRole.MANAGER]: '/profile/',
    [UserRole.MARKETING]: '/profile/',
  };

  constructor(
    public auth: AuthorizationService,
    public router: Router,
    public carsService: CarsService,
    public technicianGuard: TechnicianGuard
  ) {}
  public handleRole(
    acceptable_roles: UserRole[],
    observer: Subscriber<boolean>,
    current_user_role: UserRole
  ) {
    if (acceptable_roles.length === 0) {
      observer.next(true);
    } else {
      if (acceptable_roles.includes(current_user_role)) {
        observer.next(true);
      } else {
        this.router.navigate([this.error_href[current_user_role]]);
        observer.next(false);
      }
    }
    observer.complete();
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {
      this.auth.getCurrentUser().subscribe({
        next: ({ loading, data: auth_data }) => {
          const acceptable_roles: UserRole[] = route.data['acceptable_roles'];

          if (!loading) {
            if (auth_data.getCurrentUser.role === UserRole.TECHNICIAN) {
              this.technicianGuard.canActivate(route).subscribe((next) => {
                if (next) {
                  this.handleRole(
                    acceptable_roles,
                    observer,
                    auth_data.getCurrentUser.role
                  );
                }
              });
            } else {
              this.handleRole(
                acceptable_roles,
                observer,
                auth_data.getCurrentUser.role
              );
            }
          }
        },
        error: () => {
          observer.next(false);
        },
      });
    });
  }
}
