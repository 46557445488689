import { map } from 'rxjs';
import {
  GET_LOGS, IGetLogsDto, IGetLogsResponse,
} from './apollo/query/get-logs.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

@Injectable()
export class LogsService {
  constructor(private readonly apollo: Apollo) { }

  public getLogs({ skip = 0, take = 30 }: IGetLogsDto['pagination']) {
    return this.apollo
      .watchQuery<IGetLogsResponse, IGetLogsDto>({
        query: GET_LOGS,
        variables: {
          pagination: {
            skip,
            take,
          },
        },
      })
      .valueChanges.pipe(map((r) => r.data.getLogs));
  }
}
