import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { Schedule } from 'src/app/entity/schedule.entity';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';
import { AllLocksmithAbstractService } from '../../services/all-locksmith-abstract.service';

export type Company = {
  address: {
    id: string;
    name: string;
  }[];
  description: string;
  link_to_map: string;
  link_to_site: string;
  name: string;
  owners_name: string;
  owners_phone: string;
  photo: string;
  business_mail: string;
  business_name: string;
  business_phone: string;
  schedule: Schedule[];
  services: string[];
  url?: string;
  id: string;
};

@Component({
  selector: 'company-item',
  templateUrl: './company-item.component.html',
  styleUrls: ['./company-item.component.scss'],
})
export class CompanyItemComponent {
  @Input() company: Locksmith;
  @Input() btn_name: string;

  open: boolean = false;

  constructor(
    public dialog: MatDialog,
    public allLocksmithService: AllLocksmithAbstractService
  ) {
    console.log(this.company);
  }

  public openDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'business',
        onDelete: () => {
          this.allLocksmithService.deleteLocksmith(this.company.id);
        },
      },
    });
  }

  public get routerLink() {
    return (
      '/slk-admin/' + this.allLocksmithService.path + '/' + this.company.id
    );
  }
}
