import { gql } from "apollo-angular";

export const DELETE_MANY_USERS = gql`
  mutation deleteManyUsers ($user_ids: [ID!]!) {
    deleteManyUsers(user_ids: $user_ids)
  }
`;

export interface IDeleteManyUsersDto {
  user_ids: string[];
}

export interface IDeleteManyUsersResponse {
  deleteManyUsers: string[];
}
