import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import {
  GET_CONSTRUCTED_PAGE_COMPANIES,
  IGetConstructedPageCompaniesResponse,
} from './graphql/query/get-constructed-page-companies.query';
import {
  CREATE_CONSTRUCTED_PAGE_COMPANY,
  ICreateConstructedPageCompanyDto,
  ICreateConstructedPageCompanyResponse,
} from './graphql/mutation/create-constructed-page-company.mutation';
import { Reference } from '@apollo/client/cache/inmemory/types';
import {
  DELETE_CONSTRUCTED_PAGE_COMPANY,
  IDeleteConstructedPageCompanyDto,
  IDeleteConstructedPageCompanyResponse,
} from './graphql/mutation/delete-constructed-page-company.mutation';
import {
  IUpdateConstructedPageCompanyDto,
  IUpdateConstructedPageCompanyResponse,
  UPDATE_CONSTRUCTED_PAGE_COMPANY,
} from './graphql/mutation/update-constructed-page-company.mutation copy';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(
    private readonly apollo: Apollo,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {}

  public getAllCompanies() {
    return this.apollo.watchQuery<IGetConstructedPageCompaniesResponse>({
      query: GET_CONSTRUCTED_PAGE_COMPANIES,
    });
  }

  public createCompagnie(variables: ICreateConstructedPageCompanyDto) {
    this.apollo
      .mutate<
        ICreateConstructedPageCompanyResponse,
        ICreateConstructedPageCompanyDto
      >({
        mutation: CREATE_CONSTRUCTED_PAGE_COMPANY,
        variables,
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getConstructedPageCompanies: (exist: readonly Reference[]) => {
                return [
                  ...exist,
                  {
                    __ref: `ConstructedPageCompany:${data?.createConstructedPageCompany.id}`,
                  },
                ];
              },
            },
          });
        },
      })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            type: 'success',
            message: 'Company successfully created!',
          });
        },
      });
  }

  public updateCompagnie(variables: IUpdateConstructedPageCompanyDto) {
    this.apollo
      .mutate<
        IUpdateConstructedPageCompanyResponse,
        IUpdateConstructedPageCompanyDto
      >({ mutation: UPDATE_CONSTRUCTED_PAGE_COMPANY, variables })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            type: 'success',
            message: 'Company successfully updated!',
          });
        },
      });
  }

  public deleteCompany(variables: IDeleteConstructedPageCompanyDto) {
    this.apollo
      .mutate<
        IDeleteConstructedPageCompanyResponse,
        IDeleteConstructedPageCompanyDto
      >({
        mutation: DELETE_CONSTRUCTED_PAGE_COMPANY,
        variables,
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getConstructedPageCompanies: (exist: readonly Reference[]) => {
                return [
                  ...exist.filter(
                    ({ __ref }) =>
                      !__ref.includes(data!.deleteConstructedPageCompany)
                  ),
                ];
              },
            },
          });
        },
      })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            type: 'success',
            message: 'Company successfully deleted!',
          });
        },
      });
  }
}
