import { Component, Input } from '@angular/core';
import { WorkizItem } from 'src/app/entity/workiz-item.entity';

@Component({
  selector: 'cars-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class CarsTableComponent {
  @Input() container_items: WorkizItem[];

  public displayedColumns: string[] = ['product_name', 'quantity', 'price', 'cost'];
}
