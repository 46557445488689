<ng-container [formGroup]="$any(controlContainer.control)">
  <p class="Lexend-14">Image</p>
  <div>
    <p class="Lexend-12 light grey note">
      You can use custom logo or use a default
    </p>

    <div class="logo_wrap">
      <img
        #img
        class="logo"
        src=""
        alt="company logo"
        (error)="errorHandle($event)"
      />

      <upload (onUpload)="uploadPhoto($event)">
        <div class="btn_background">
          <div class="btn">
            <p class="Lexend-16">Upload image</p>
          </div>
        </div>
      </upload>
    </div>
  </div>

  <div class="logo_inp_wrap">
    <div class="separator">
      <hr />
      <p>or</p>
      <hr />
    </div>

    <mat-form-field>
      <mat-label>Import from URL</mat-label>
      <input
        (input)="handleUrl($event)"
        matInput
        type="text"
        placeholder="Paste logo link"
        formControlName="file_url"
      />
    </mat-form-field>
  </div>
</ng-container>
