import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

import {
  CreateLocksmithDto,
  CreateLocksmithResponse,
  CREATE_LOCKSMITH,
} from '../../apollo/mutation/create-locksmith';
import { IGetLocksmithByIdDto } from '../../apollo/query/get-locksmith-by-id';
import { AllLocksmithService } from '../../common/services/all-locksmith.service';

@Injectable()
export class CreateLocksmithService extends AllLocksmithService {
  public getLocksmithById?(variables?: IGetLocksmithByIdDto | undefined): void {
    // throw new Error('Method not implemented.');
  }
  constructor(alertService: AlertService, apollo: Apollo, router: Router) {
    super(apollo, alertService, router);
  }

  public submitForm(new_locksmith: Locksmith) {
    return this.apollo
      .mutate<CreateLocksmithResponse, CreateLocksmithDto>({
        mutation: CREATE_LOCKSMITH,
        variables: { locksmith: new_locksmith },
        context: { hasUpload: true },
      })
      .subscribe({
        complete: () => {
          this.router.navigate(['/slk-admin/confirmed']);
        },
      });
  }
  public deleteLocksmith(id: string) {
    this.router.navigate(['/slk-admin/confirmed']);
  }
}
