<app-side-menu
  [is_open]="is_open"
  (onClose)="is_openChange.emit(false)"
  [headline]="type + ' company'"
>
  <form (ngSubmit)="onSubmit()" class="form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        placeholder="Type name here"
        formControlName="name"
      />
    </mat-form-field>
    <button type="submit" mat-raised-button>{{ type }}</button>
  </form>
</app-side-menu>
