import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  GET_LOCKSMITH_WITH_UNREVIEWED_REVIEW,
  IGetLocksmithWithUnreviewedReviewResponse,
} from '../../apollo/query/get-locksmith-with-unreviewed-reviews';
import { map } from 'rxjs';

@Injectable()
export class ReviewsMainService {
  constructor(private readonly apollo: Apollo) {}
  public searchValue: string = '';
  public getLocksmithWithUnreviewedReview() {
    return this.apollo
      .watchQuery<IGetLocksmithWithUnreviewedReviewResponse>({
        query: GET_LOCKSMITH_WITH_UNREVIEWED_REVIEW,
      })
      .valueChanges.pipe(map((r) => r.data.getLocksmithWithUnreviewedReviews));
  }
}
