import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { CompanyFormModule } from '../../../common/company-form/company-form.module';
import { ConfirmedLocksmithComponent } from './component/confirmed-locksmith.component';
import { reviewsModule } from './component/reviews/reviews.module';
import { ConfirmedLocksmithService } from './confirmed-locksmith.service';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    MatTabsModule,
    reviewsModule,
    IconsModule,
    CompanyFormModule.inversion(ConfirmedLocksmithService),
    RouterModule.forChild([
      {
        path: 'slk-admin/confirmed-locksmith/:locksmith-id',
        loadChildren: async () =>
          (await import('./confirmed-locksmith.module'))
            .ConfirmedLocksmithModule,
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConfirmedLocksmithComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [ConfirmedLocksmithComponent],
  providers: [ConfirmedLocksmithService],
})
export class ConfirmedLocksmithModule {}
