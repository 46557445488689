import { Component } from '@angular/core';
import { CarsService } from '../../cars.service';
import { IFindUpdateCarRequests, IFormatUpdateCarRequest } from '../../apollo/query/find-update-car-requests.query';

export enum ConfirmInventoryTabs {
  WAITING_FOR_CONSIDERATION = 'Waiting for consideration',
  APPROVED = 'Approved',
}

@Component({
  selector: 'confirm-inventory',
  templateUrl: './confirm-inventory.component.html',
  styleUrls: ['./confirm-inventory.component.scss']
})
export class ConfirmInventoryComponent {
  public loading: boolean = true

  public consideration: IFormatUpdateCarRequest = [];
  public submitted: IFormatUpdateCarRequest = [];

  constructor(
    private readonly carsService: CarsService,
  ) { }

  get tab_approved(): ConfirmInventoryTabs {
    return ConfirmInventoryTabs.APPROVED;
  }

  get tab_consideration(): ConfirmInventoryTabs {
    return ConfirmInventoryTabs.WAITING_FOR_CONSIDERATION;
  }

  get consideration_exist(): boolean {
    return Object.keys(this.consideration).length === 0;
  }

  ngOnInit(): void {
    this.carsService.findUpdateCarRequests()
      .subscribe((formatted_request) => {
        this.consideration = formatted_request.consideration;
        this.submitted = formatted_request.submitted;
      })
  }

  public approveRequestForCarItems() {
    const get_nested_arrays = Object.values(this.submitted).reduce((result: IFindUpdateCarRequests[], currentArray) => result.concat(currentArray), []);
    const request_data = get_nested_arrays.map((item) => ({ id: item.id, approved_quantity: item.requested_quantity }))

    this.carsService.approveRequestForCarItems(request_data);
  }
}
