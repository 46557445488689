<div class="wrap">
  <div
    *ngFor="let folder of $containers | async"
    class="folder"
    [attr.data-selected]="folder.selected"
  >
    <dots-icon
      class="dots pointer"
      mat-icon-button
      [matMenuTriggerFor]="menu"
    />
    <mat-checkbox
      class="checkbox"
      [checked]="folder.selected"
      (change)="handleSelectContainer(folder.id)"
    />

    <mat-menu #menu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="templates">
        <span>Assign template</span>
      </button>
      <button mat-menu-item (click)="setContainerId(folder.id)">
        <span>Send to update</span>
      </button>
    </mat-menu>

    <mat-menu #templates="matMenu">
      <button
        mat-menu-item
        *ngFor="let template of $templates | async"
        (click)="
          assignTemplate({ template_id: template.id, workiz_id: folder.id })
        "
      >
        {{ template.name }}
      </button>
    </mat-menu>

    <a [routerLink]="folder.id" class="folder-block">
      <div class="icons">
        <folder-icon />
      </div>
      <p class="Lexend-14 medium dark-blue">{{ folder.name }}</p>
    </a>
  </div>
</div>
