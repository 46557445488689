import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    RouterModule.forChild([
      {
        path: 'slk-hr/vacancies',
        loadChildren: async () =>
          (await import('./vacancies.module')).VacanciesModule,
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: VacanciesComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.slk_admin,
        },
      },
    ]),
  ],
  declarations: [VacanciesComponent],
  providers: [],
})
export class VacanciesModule {}
// VacanciesComponent
