<div class="lastTeachingDialogDialog">
  <cross-icon (click)="onClose()" />
  <h2 class="headline">
    Last teaching
    <span>
      Status:
      <b [ngClass]="fullLastFineTune?.status">
        {{fullLastFineTune?.status || ""}}
      </b>
    </span>
  </h2>
  <div class="wrapSteps">
    <p *ngFor="let step of fullLastFineTune?.events; let i = index">
      Step{{i + 1}}: {{step.message}}
      <span>Date: {{ step.created_at * 1000| date: 'short' }}</span>
    </p>
  </div>
</div>
