<mat-form-field>
  <search-icon class="search-icon" />
  <input matInput [formControl]="searchControl" placeholder="Search..." />
</mat-form-field>
<div>
  <h1 class="Lexend-32 regular dark-blue">Reviews</h1>
  <wrapper-loading>
    <div *ngIf="unreviewed_review">
      <div class="wrap" *ngIf="unreviewed_review.length > 0">
        <reviews-item [unreviewed_reviews]="unreviewed_review || []" />
      </div>
      <div class="noReviewsWrap" *ngIf="unreviewed_review.length === 0">
        <div class="noReviews">
          <search-in-circle />
          <p class="Lexend-24 regular dark-blue">No reviews found</p>
          <p class="Lexend-18 regular grey">
            Reviews appear after somebody left them <br />
            on the website
          </p>
        </div>
      </div>
    </div>
  </wrapper-loading>
</div>
