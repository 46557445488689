import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { FormatWorkTimePipe } from 'src/app/pipe/format-work-time.pipe';
import { CheckTimePeriodRangePipe } from '../../pipe/check-time-period-range.pipe';
import { CompareTimePeriodAndRangePipe } from '../../pipe/compare-time-period-and-range.pipe';
import { DayAbstractService } from './day-abstract.service';
import { DayComponent } from './day.component';
import { GroupRangesComponent } from './group-ranges/group-ranges.component';
import { RequestsComponent } from './requests/requests.component';
import { PeriodsComponent } from './time-picker/periods/periods.component';
import { ListComponent } from './time-picker/range/list/list.component';
import { RangeComponent } from './time-picker/range/range.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    SideMenuModule,
    DirectiveModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
  ],
  declarations: [
    DayComponent,
    ListComponent,
    RangeComponent,
    PeriodsComponent,
    FormatWorkTimePipe,
    TimePickerComponent,
    CheckTimePeriodRangePipe,
    CompareTimePeriodAndRangePipe,
    GroupRangesComponent,
    RequestsComponent,
  ],
  exports: [DayComponent, GroupRangesComponent],
  providers: [],
})
export class DayModule {
  static inversion(
    useClass: Type<DayAbstractService>
  ): ModuleWithProviders<DayModule> {
    return {
      ngModule: DayModule,
      providers: [
        {
          provide: DayAbstractService,
          useClass,
        },
      ],
    };
  }
}
