import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UnreviewedReviews } from 'src/app/entity/unreviewed-reviews.entity';

@Component({
  selector: 'reviews-item',
  templateUrl: './reviews-item.component.html',
  styleUrls: ['./reviews-item.component.scss'],
})
export class ReviewsMainItemComponent {
  @Input() unreviewed_reviews: UnreviewedReviews[];
  displayedColumns = ['Business name', 'Reviews', 'Date', 'Actions'];

  constructor(private router: Router) {}

  redirectToLink(element: UnreviewedReviews) {
    const link = `slk-admin/company/${element.id}`;
    this.router.navigateByUrl(link);
  }
}
