import { gql } from 'apollo-angular';

export const CHANGE_PASSWORD = gql`
  mutation changePassword($passwords: ChangePasswordDto!) {
    changePassword(passwords: $passwords) {
      message
      status
    }
  }
`;

export interface IChangePasswordDto {
  passwords: {
    old_password: string;
    new_password: string;
  };
}
export interface IChangePasswordResponse {
  changePassword: {
    message: string;
    status: number;
  };
}
