<div class="wrap-range">
  <div
    class="wrap-list darck-blue"
    *ngFor="let time of workingHours; let index_of_element = index"
  >
    From
    <list
      (handleChangeTime)="updateRange($event, 'work_from', index_of_element)"
      [time]="time.work_from"
    />
    - To
    <list
      (handleChangeTime)="updateRange($event, 'work_to', index_of_element)"
      [time]="time.work_to"
    />
    <cross-in-circle
      *ngIf="workingHours.length > 1"
      class="pointer"
      (click)="removeRange(index_of_element)"
    />
  </div>
  <p class="blue Lexend-14 pointer" (click)="addNewRange()">Add range</p>
</div>
