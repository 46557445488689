<div class="wrap">
  <mat-slide-toggle (toggleChange)="setIsWork()" [checked]="isWork">
    Working
  </mat-slide-toggle>

  <range [(workingHours)]="working_hours" *ngIf="isWork" />

  <div class="or Lexend-16 light" *ngIf="isWork">
    <hr />
    OR
    <hr />
  </div>

  <div class="periods" *ngIf="isWork">
    <button
      mat-stroked-button
      (click)="newPeriod()"
      *ifRoles="roles_show_time_template"
    >
      + Add new
    </button>

    <label
      *ngFor="let period of time_templates"
      [style]="colors[period.color]"
      class="color Lexend-16 regular pointer"
      (contextmenu)="opeContextMenu($event, period)"
    >
      <input
        required
        name="color"
        type="checkbox"
        [value]="period.color"
        (change)="timeChange($event, period)"
        [checked]="period | check_time_period_range : working_hours"
      />
      {{ period.name }}
      <check-icon color="currentcolor" />
    </label>
  </div>
  <button mat-raised-button (click)="onSave()">Save</button>
</div>

<button
  *ifRoles="roles_show_requests"
  (click)="reviewRequestTime()"
  class="request blue pointer"
  [disabled]="!+quantity_request_schedules"
>
  Requests ({{ quantity_request_schedules }})
</button>

<ng-container *ifRoles="roles_show_requests">
  <div
    style="visibility: hidden; position: fixed"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    [matMenuTriggerFor]="rightMenu"
  ></div>

  <mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-item="item" let-period="period">
      <button
        (click)="setTimeTemplate(period)"
        class="Lexend-16 darck-blue"
        mat-menu-item
      >
        Edit
      </button>

      <button
        (click)="deleteTimeTemplate(period)"
        class="Lexend-16 red"
        mat-menu-item
      >
        Delete
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
