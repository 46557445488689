import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { MatDatepicker } from '@angular/material/datepicker';
import { default as _rollupMoment, Moment } from 'moment';
import { IStartUpdateJobsInfoDto } from '../../apollo/mutation/start-update-jobs-info';
import { ChatService } from '../../chat.service';
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'parse-data',
  templateUrl: './parse-data.component.html',
  styleUrls: ['./parse-data.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ParseDataComponent implements OnInit {
  public is_parsing: boolean;
  public is_open = false;
  public max: string;

  public form = new FormGroup({
    account: new FormControl<IStartUpdateJobsInfoDto['account']>('main', [Validators.required]),
    year_month: new FormControl(moment(), [Validators.required]),
  })

  constructor(private readonly chatService: ChatService) { }

  ngOnInit(): void {
    this.max = moment().format('YYYY-MM-DD');
    this.chatService.getSystemSettings().subscribe(({ is_parsing }) => {
      this.is_parsing = is_parsing
    })
  }

  public onParseData() {
    this.is_open = !this.is_open;
  }

  public onSubmit() {
    const { account, year_month } = this.form.value!

    if (account && year_month) {
      const from_month = year_month.month() + 1;
      const from_year = Number(year_month?.format('YY'));

      this.chatService.startUpdateJobsInfo({ account, from_month, from_year })
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const year_month = this.form.get('year_month');
    const value_year_month = year_month?.value
    const month = normalizedMonthAndYear.month();
    const year = normalizedMonthAndYear.year();

    if (value_year_month) {
      this.form?.patchValue({ year_month: value_year_month.month(month).year(year) });
    } else {
      this.form?.patchValue({ year_month: moment().year(year).month(month) });
    }

    datepicker.close();
  }

}

