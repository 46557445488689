import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { FineTuneItem } from 'src/app/entity/fine-tune-item.entity';
import { TeachingService } from 'src/app/pages/chat/teaching.service';

@Component({
  selector: 'teaching-block',
  templateUrl: './teaching-block.component.html',
  styleUrls: ['./teaching-block.component.scss'],
})
export class TeachingBlockComponent implements OnInit {
  @Input() fineTune: FineTuneItem;

  public is_editing = false;
  public answer: string;

  constructor(
    private readonly teachingService: TeachingService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.setDefaultText();
  }

  public startEditing() {
    this.is_editing = true;
  }

  private setDefaultText() {
    this.answer = this.fineTune.text;
  }

  public cancelEditing() {
    this.is_editing = false;
    this.setDefaultText();
  }

  public handleInput(e: Event) {
    e.preventDefault();
    const paragraph = e.target as HTMLParagraphElement;
    this.answer = paragraph.innerText;
  }

  public deleteFineTuneItem() {
    this.teachingService.deleteFineTuneItem(this.fineTune.id).subscribe({
      complete: () => this.alertService.alert({ type: 'success', message: 'Item successfully deleted' }),
      error: (e) => this.alertService.alert({ type: 'error', message: e.message })
    })
  }

  public updateFineTuneItem() {
    this.teachingService.updateFineTuneItem({
      id: this.fineTune.id,
      prompt: this.fineTune.prompt,
      text: this.answer.trim(),
    }).subscribe({
      next: () => (this.is_editing = false),
      complete: () => this.alertService.alert({ type: 'success', message: 'Item successfully updated' }),
      error: (e) => this.alertService.alert({ type: 'error', message: e.message })
    });
  }
}
