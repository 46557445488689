import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hour-twenty-four-service',
  templateUrl: './hour-twenty-four-service.component.html',
  styleUrls: ['./hour-twenty-four-service.component.scss']
})
export class HourTwentyFourServiceComponent {
  @Input() data: any;

  get list () {
    return this.data.list.split("\n")
  }
}
