import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConstructorAbstractService } from '../../constructor.abstract.service';
import { FormTemplates } from '../fields-control/fields-control.form.template';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent {
  constructor(
    private readonly constructorAbstractService: ConstructorAbstractService
  ) {}

  public get form_templates(): typeof FormTemplates {
    return FormTemplates;
  }

  public get form(): FormGroup[] {
    return this.constructorAbstractService.form;
  }

  public getTypeByFrom(form: FormGroup) {
    return form.value.type_block;
  }

  public getValuesOfFrom(form: FormGroup) {
    return form.value;
  }
}
