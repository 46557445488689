import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public hours: string[] = [];

  @Input() time: string = '12:00 am';

  @Output('handleChangeTime') handleChangeTime = new EventEmitter<string>();

  public open: boolean;

  ngOnInit(): void {
    for (let hour = 0; hour < 24; hour++) {
      this.hours.push(moment({ hour }).format('hh:mm a'));
      this.hours.push(moment({ hour, minute: 30 }).format('hh:mm a'));
    }
    this.hours.push('11:59 pm');
  }

  public setOpen(state: boolean = false) {
    this.open = state;
  }

  public selectHour(hour: string) {
    // this.select_hour = hour;
    this.setOpen(false)

    this.handleChangeTime.emit(hour);
  }
}
