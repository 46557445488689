import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ConstructorBlog } from 'src/app/entity/blog/constructor-blog.entity';
import { GET_BLOG_BY_META_BLOG_URL, IGetBlogsFilterByMetaBlogUrlDto, IGetBlogsFilterByMetaBlogUrlResponse } from './apollo/query/get-blog-by-meta-blog-url.query';

@Component({
  selector: 'app-you-may-also-like',
  templateUrl: './you-may-also-like.component.html',
  styleUrls: ['./you-may-also-like.component.scss']
})
export class YouMayAlsoLikeComponent implements OnChanges {
  @Input() data: any;

  public blogs: ConstructorBlog[] = [];

  constructor(private readonly apollo: Apollo) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['data'].isFirstChange()) {

      this.blogs = [];
      const list = this.data.list.split('\n');
      list.forEach((link: string) => {
        this.getBlogs(link);
      });
    }
  }

  public getBlogs(link: string): void {
    this.apollo.watchQuery<IGetBlogsFilterByMetaBlogUrlResponse, IGetBlogsFilterByMetaBlogUrlDto>({
      query: GET_BLOG_BY_META_BLOG_URL,
      variables: { metaBlogUrl: link }
    })
      .valueChanges
      .subscribe({
        next: (r) => {
          this.blogs.push(r.data.getBlogsFilterByMetaBlogUrl);
        },
        error(err) { },
      });
  }
}
