import { NgModule } from '@angular/core';
import { ArrowNavigationIconComponent } from './arrow-navigation/arrow-navigation.component';
import { ArrowIconComponent } from './arrow/arrow.component';
import { BlogsIconComponent } from './blogs/blogs.component';
import { BlueDiamondComponent } from './blue-diamond/blue-diamond';
import { BlueArrowComponent } from './blue-icon/blue-arrow.component';
import { BluePhoneIconComponent } from './blue-phone/blue-phone.component';
import { BotChatIconComponent } from './bot-chat/bot-chat.component';
import { CarIconComponent } from './car/car.component';
import { ChatIconComponent } from './chat/chat.component';
import { CheckListIconComponent } from './check-list/check-list.component';
import { CheckIconComponent } from './check/check.component';
import { ConfirmedIconComponent } from './confirmed/confirmed.component';
import { ConstructorSaveIconComponent } from './constructor-save/constructor-save.component';
import { CopyIconComponent } from './copy/copy.component';
import { CrossInCircleIconComponent } from './cross-in-circle/cross-in-circle.component';
import { CrossIconComponent } from './cross/cross.component';
import { DatabaseIconComponent } from './database/database.component';
import { DeleteCrossIconComponent } from './delete-cross/delete-cross.component';
import { DeleteIconComponent } from './delete/delete.component';
import { DotsIconComponent } from './dots/dots.component';
import { DoubleArrowIconComponent } from './double-arrow/double-arrow.component';
import { EditIconComponent } from './edit/edit.component';
import { FileIconComponent } from './file/file.component';
import { FilterIconComponent } from './filter/filter.component';
import { FolderIconComponent } from './folder/folder.component';
import { InfoIconComponent } from './info/info.component';
import { JobsIconComponent } from './jobs/jobs.component';
import { LinkIconComponent } from './link-icon/link-icon.component';
import { LocationIconComponent } from './location/location.component';
import { LockIconComponent } from './lock/lock.component';
import { LogoIconComponent } from './logo/logo.component';
import { LogoutIconComponent } from './logout/logout.component';
import { LogsIconComponent } from './logs/logs.component';
import { ManageUsersIconComponent } from './manage-users/manage-users.component';
import { PlayIconComponent } from './play/play.component';
import { PlusInCircleComponent } from './plus-in-circle/plus-in-circle.component';
import { PlusIconComponent } from './plus/plus.component';
import { OpenEyeComponent } from './profile/closed-eye/closed-eye.component';
import { ClosedEyeComponent } from './profile/open-eye/open-eye.component';
import { PointerErrorComponent } from './profile/pointer-error/pointer-error.component';
import { PointerIconComponent } from './profile/pointer/pointer.component';
import { ProfileIconComponent } from './profile/profile.component';
import { RedCrossIconComponent } from './red-cross-icon/red-cross-icon.component';
import { RequestIconComponent } from './request/request.component';
import { resizeComponentIcon } from './resize/resize.component';
import { SearchInCircleComponent } from './search-in-circle/search-in-circle.component';
import { SearchIconComponent } from './search/search.component';
import { UploadPictureIconComponent } from './upload-picture/upload-picture.component';
import { UploadWithoutOutlineIconComponent } from './upload-without-outline/upload-without-outline.component';
import { RefreshIconComponent } from './refresh/refresh.component';
import { ScheduleIconComponent } from './schedule/schedule.component';
import { SendMessageIconComponent } from './send-message/send-message.component';
import { SettingsIconComponent } from './settings/settings.component';
import { TeachingIconComponent } from './teaching/teaching.component';
import { UploadIconComponent } from './upload/upload.component';
import { UserChatIconComponent } from './user-chat/user-chat.component';
import { CompanyIconComponent } from './company/company.component';
import { VacanciesIconComponent } from './vacancies/vacancies.component';
import { TemplatesIconComponent } from './templates/templates.component';

const icons = [
  ArrowIconComponent,
  CarIconComponent,
  ChatIconComponent,
  RequestIconComponent,
  JobsIconComponent,
  TeachingIconComponent,
  SettingsIconComponent,
  ScheduleIconComponent,
  ProfileIconComponent,
  ConfirmedIconComponent,
  ManageUsersIconComponent,
  PointerErrorComponent,
  LogsIconComponent,
  LogoutIconComponent,
  LogoIconComponent,
  SendMessageIconComponent,
  OpenEyeComponent,
  ClosedEyeComponent,
  PointerIconComponent,
  EditIconComponent,
  DeleteIconComponent,
  CheckIconComponent,
  RefreshIconComponent,
  CrossIconComponent,
  PlayIconComponent,
  resizeComponentIcon,
  CopyIconComponent,
  FolderIconComponent,
  DotsIconComponent,
  FileIconComponent,
  InfoIconComponent,
  PlusIconComponent,
  DoubleArrowIconComponent,
  CrossInCircleIconComponent,
  DeleteCrossIconComponent,
  UploadIconComponent,
  TemplatesIconComponent,
  ArrowNavigationIconComponent,
  SearchIconComponent,
  BlueArrowComponent,
  LinkIconComponent,
  FilterIconComponent,
  FilterIconComponent,
  CheckListIconComponent,
  VacanciesIconComponent,
  SearchInCircleComponent,
  RedCrossIconComponent,
  PlusInCircleComponent,
  BlogsIconComponent,
  LocationIconComponent,
  BluePhoneIconComponent,
  BlueDiamondComponent,
  UploadPictureIconComponent,
  ConstructorSaveIconComponent,
  UploadWithoutOutlineIconComponent,
  LockIconComponent,
  BotChatIconComponent,
  UserChatIconComponent,
  DatabaseIconComponent,
  CompanyIconComponent,
];

@NgModule({
  declarations: icons,
  exports: icons,
})
export class IconsModule {}
