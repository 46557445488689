import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

export function generateUrl(form: FormGroup) {
  const address: string = form.get('address')?.value[0];
  if (address) {
    const [street, city, st] = address
      .toLowerCase()
      .replace('#', '')
      .split(', ');
    const business_name = form.get('name')?.value;
    const name = business_name
      .toLowerCase()
      .replace(/[&]/g, '')
      .replace('.', '')
      .split(' ')
      .filter((e: any) => e)
      .join('-');

    let idUrl = '';
    if (city) {
      idUrl = [street.split(' ').join('-'), city.trim(), name].join('-');
    } else if (st) {
      idUrl = [
        street.split(' ').join('-'),
        city.trim(),
        st.trimEnd(),
        name,
      ].join('-');
    } else {
      return (idUrl = [street.split(' ').join('-'), name].join('-'));
    }
    return idUrl;
  }
  return '';
}

@Component({
  selector: 'id-link',
  templateUrl: './id-link.component.html',
})
export class IdLinkComponent {
  @Input() form: FormGroup;
  constructor(public readonly controlContainer: ControlContainer) {}

  get urlLink() {
    return generateUrl(this.form);
  }
}
