import { UserRole } from 'src/app/enum/user-role.enum';

export const permissions = {
  chat: [UserRole.ADMIN],
  teaching: [UserRole.ADMIN],
  api: [UserRole.ADMIN, UserRole.MAIN_DISPATCHER, UserRole.DISPATCHER],
  schedule: [UserRole.ADMIN, UserRole.MAIN_DISPATCHER, UserRole.DISPATCHER],
  'my-schedule': [
    UserRole.MAIN_DISPATCHER,
    UserRole.DISPATCHER,
    UserRole.TECHNICIAN,
  ],
  'manage-users': [UserRole.ADMIN, UserRole.MAIN_DISPATCHER],
  settings: [UserRole.ADMIN],
  logs: [UserRole.ADMIN],
  profile: [],
  cars: [UserRole.ADMIN, UserRole.LOGISTIC],
  'cars-confirm-inventory': [UserRole.TECHNICIAN],
  register: [],
  slk_admin: [UserRole.ADMIN],
  hr: [UserRole.ADMIN, UserRole.MANAGER, UserRole.HR],
  marketing: [UserRole.ADMIN, UserRole.MANAGER, UserRole.MARKETING],

  'ai-helper': [UserRole.ADMIN],
  work: [
    UserRole.ADMIN,
    UserRole.MAIN_DISPATCHER,
    UserRole.DISPATCHER,
    UserRole.TECHNICIAN,
  ],
  system: [UserRole.ADMIN],
  users: [],
  inventory: [UserRole.ADMIN, UserRole.LOGISTIC],
};
