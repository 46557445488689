import { Injectable } from "@angular/core";
import { InviteUserAbstractService } from "./invite-user.service";
import { ManageUsersService } from "../../../manage-users.service";
import { InviteUserDto } from "../../../apollo/mutation/invite-user-to-app.mutation";
import { AlertService } from "src/app/modules/ui-kit/alert/alert.service";
import { MatDialog } from "@angular/material/dialog";
import { SuccessInviteComponent } from "../../success-invite/success-invite.component";
import { Observable } from "rxjs";

@Injectable()
export class CreateUserService extends InviteUserAbstractService {
  public override loading: boolean;

  constructor(
    private readonly manageUsersService: ManageUsersService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  public onSubmit(invite_user_dto: InviteUserDto['inviteUserDto']) {
    return new Observable<void>((observer) => {
      this.manageUsersService.inviteUserToApp(invite_user_dto)
      .subscribe({
        next: ({ data, loading }) => {
          observer.next();
          this.loading = loading;

          if (!loading && data?.inviteUserToApp?.key) {
            this.dialog.open(SuccessInviteComponent, {
              data: { url_key: data?.inviteUserToApp.key },
              maxWidth: '513px',
              width: '100%',
            });
          }
        },
        complete: () => {
          observer.complete();
          this.manageUsersService.setEditUser = undefined;
          this.alertService.alert({ message: 'User successfully invited', type: 'success' });
        },
        error: ({ message }) => {
          observer.error(message);
          this.alertService.alert({ message, type: 'error' });
          this.loading = false;
        },
      });
    });
  }
}
