import { CommonModule } from '@angular/common';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { BlockWithImageComponent } from './components/blogs/block-with-image/block-with-image.component';
import { SwitchListComponent } from './components/blogs/block-with-image/components/switch-list/switch-list.component';
import { TextBlockComponent } from './components/blogs/block-with-image/components/text-block/text-block.component';
import { TitleOneComponent } from './components/blogs/block-with-image/components/title-one/title-one.component';
import { MainBlockComponent } from './components/blogs/main-block/main-block.component';
import { NeedMoreInfoButtonComponent } from './components/blogs/need-more-info-button/need-more-info-button.component';
import { PricesBlockComponent } from './components/blogs/prices-block/prices-block.component';
import { YouMayAlsoLikeComponent } from './components/blogs/you-may-also-like/you-may-also-like.component';
import { PhotoComponent } from './components/locations/photo/photo.component';
import { AboutUsComponent } from './components/locations/about-us/about-us.component';
import { HourTwentyFourServiceComponent } from './components/locations/hour-twenty-four-service/hour-twenty-four-service.component';
import { AboutCityComponent } from './components/locations/about-city/about-city.component';
import { OurServicesComponent } from './components/locations/our-services/our-services.component';
import { NgModule } from '@angular/core';
import { BlocksComponent } from './blocks.component';
import { GetImageUrlByPhotoIDPipe } from '../../pipe/get-image-url-by-photo-id.pipe';
import { TestimonialsComponent } from './components/locations/testimonials/testimonials.component';

@NgModule({
  imports: [CommonModule, IconsModule, GetImageUrlByPhotoIDPipe],
  declarations: [
    BlockWithImageComponent,
    MainBlockComponent,
    PricesBlockComponent,
    TitleOneComponent,
    TextBlockComponent,
    SwitchListComponent,
    NeedMoreInfoButtonComponent,
    YouMayAlsoLikeComponent,
    BlocksComponent,
    PhotoComponent,
    AboutUsComponent,
    HourTwentyFourServiceComponent,
    AboutCityComponent,
    OurServicesComponent,
    TestimonialsComponent,
  ],
  exports: [
    BlockWithImageComponent,
    MainBlockComponent,
    PricesBlockComponent,
    YouMayAlsoLikeComponent,
    BlocksComponent,
    PhotoComponent,
    AboutUsComponent,
    HourTwentyFourServiceComponent,
    AboutCityComponent,
    OurServicesComponent,
  ],
})
export class BlocksModule {}
