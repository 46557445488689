import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  CHANGE_PASSWORD,
  IChangePasswordDto,
  IChangePasswordResponse,
} from './apollo/mutation/change-password.mutation';
import {
  IUpdateCurrentUserDto,
  IUpdateCurrentUserResponse,
  UPDATE_CURRENT_USER,
} from './apollo/mutation/update-current-user.mutation';

@Injectable()
export class ProfileService {
  constructor(private readonly apollo: Apollo) { }

  public changePassword(passwords: IChangePasswordDto['passwords']) {
    return this.apollo.mutate<IChangePasswordResponse, IChangePasswordDto>({
      mutation: CHANGE_PASSWORD,
      variables: {
        passwords,
      },
    });
  }

  public updateCurrentUser(user: IUpdateCurrentUserDto['user']) {
    return this.apollo.mutate<
      IUpdateCurrentUserResponse,
      IUpdateCurrentUserDto
    >({
      mutation: UPDATE_CURRENT_USER,
      variables: { user },
    });
  }
}
