import { gql } from "apollo-angular";
import { TimeTemplate } from "src/app/entity/time-template.entity";

export const GET_ALL_TIME_TEMPLATES = gql`
  query getAllTimeTemplates {
    getAllTimeTemplates {
      color
      id
      name
      value
    }
  }
`


export type IGetAllTimeTemplatesResponse = {
  getAllTimeTemplates: TimeTemplate[];
}
