import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-prices-block',
  templateUrl: './prices-block.component.html',
  styleUrls: ['./prices-block.component.scss']
})
export class PricesBlockComponent implements OnChanges {
  @Input() data: PriceBlockData;

  public parsed_styles?: BlockStyles;

  ngOnChanges(): void {
    this.parsed_styles = this.data.styles;
  }
}

interface PriceBlockData {
  position_block: number;
  type_block?: string;
  headline?: string;
  description?: string;
  body_text?: string;
  photo?: {
    link?: string;
    image?: File;
    alt?: string;
    title?: string;
  };
  styles?: BlockStyles;
  list?: string;
  last_text?: string;
  last_list?: string;
  first_list?: string;
  text_right?: string;
}

export interface BlockStyles {
  add_top_need_more_info_button?: boolean;
  add_bottom_need_more_info_button?: boolean;
  first_list_number_list?: boolean;
  main_list_number_list?: boolean;
  last_list_number_list?: boolean;
  body_grid_revers?: boolean;
  turn_padding_top?: boolean;
  turn_padding_bottom?: boolean;
  first_text_grid_revers?: boolean;
  body_text_grid_revers?: boolean;
  last_text_grid_revers?: boolean;
  background_exists?: boolean;
  first_list_grid_quantity_columns?: number;
  main_list_grid_quantity_columns?: number;
  last_list_grid_quantity_columns?: number;
}
