import { ApiService } from '../../api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'workiz',
  templateUrl: './workiz.component.html',
  styleUrls: ['./workiz.component.scss'],
})
export class WorkizComponent implements OnInit {
  public loading: boolean = true;

  constructor(private readonly apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService
      .getAllJobsWorkiz({ offset: 1, records: 50 })
      .subscribe(() => (this.loading = false));
  }
}
