import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Output() saveChanges = new EventEmitter<{ start: moment.Moment; end: moment.Moment; }>();
  @Output() cancelChanges = new EventEmitter();

  @Input() start: moment.Moment;
  @Input() end: moment.Moment;

  public day_names = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  public calendar: moment.Moment[][] = [];

  public current_month = moment();

  public month_index = 0
  public selected_date: { start: moment.Moment; end: moment.Moment; };

  private selected_month = moment().startOf('month').add(this.month_index, 'month')
  private start_day = this.selected_month.clone().startOf('month');
  private end_day = this.selected_month.clone().endOf('month');

  private selected_month_index = this.selected_month.clone().month();
  public get selectedMonthIndex(): number {
    return this.selected_month_index
  }

  private current_day: moment.Moment = this.current_month.clone()
  public get currentDay(): moment.Moment {
    return this.current_day
  }

  public current_month_and_year = this.selected_month.clone().format('MMMM YYYY');

  constructor() { }

  ngOnInit(): void {
    this.start = this.start.startOf('day') || moment().clone().startOf('isoWeek').startOf('day');
    this.end = this.end.endOf('day') || moment().clone().endOf('isoWeek').endOf('day');

    this.month_index = this.end.clone().month() - this.current_month.clone().month();

    this.setAllDateChanges()
    this.rebuildCalendar();
  }

  public rebuildCalendar() {
    this.calendar = []
    let date = this.start_day.clone().subtract(1, 'day');
    while (date.isBefore(this.end_day, 'day'))
      this.calendar.push(
        Array(7)
          .fill(0)
          .map(() => date.add(1, 'day').clone())
      );
  }

  public setAllDateChanges() {
    this.setSelectedDate(this.start, this.end)

    this.selected_month = moment().add(this.month_index, 'month')
    this.start_day = this.selected_month.clone().startOf('month').startOf('isoWeek');
    this.end_day = this.selected_month.clone().endOf('month').endOf('isoWeek');

    this.current_month_and_year = this.selected_month.clone().format('MMMM YYYY');

    this.selected_month_index = this.selected_month.clone().month();
    this.current_day = this.current_month.clone()
  }

  public setSelectedDate(start: moment.Moment, end: moment.Moment) {
    this.selected_date = { start: start.startOf('day'), end: end.endOf('day') }
  }

  public setIndex(index: number) {
    this.month_index = this.month_index + index;

    this.setAllDateChanges()

    this.rebuildCalendar();
  }

  public onSave() {
    this.saveChanges.emit(this.selected_date);
  }

  public onCancel() {
    this.cancelChanges.emit(this.selected_date);
  }
}
