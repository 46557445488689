<logo-icon class="logo" />
<div class="wrapLogin">
  <form class="from" [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 class="Lexend-32 regular center">Login</h1>

    <mat-form-field>
      <mat-label>Email address</mat-label>

      <input
        matInput
        type="email"
        placeholder="Enter your email here"
        formControlName="email"
      />

      <mat-error *ngIf="email.hasError('required')">
        Email <strong>required</strong>
      </mat-error>

      <mat-error *ngIf="email.hasError('email')">
        The email is not valid
      </mat-error>
    </mat-form-field>

    <password-input controlName="password" />

    <button mat-raised-button class="button" type="submit">Login</button>
  </form>
</div>
