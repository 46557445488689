<p class="Lexend-12 grey light">Schedule:</p>
<div class="day_list">
  <div *ngFor="let day of schedule" class="day">
    <p class="Lexend-14">{{ day.name | titlecase }}</p>
    <div class="time_wrap">
      <p class="Lexend-14">
        {{ day.open_at | date : "shortTime" }} -
        {{ day.close_at | date : "shortTime" }}
      </p>
    </div>
    <p class="Lexend-14 grey light">
      {{ day.close_at && day.open_at ? "open" : "close" }}
    </p>
  </div>
</div>
