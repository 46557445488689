import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserInfo } from 'src/app/entity/schedule/user-info.entity';
import { ScheduleService } from '../../../pages/schedule/services/schedule.service';

@Component({
  selector: 'info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() info: UserInfo[] = [];
  @Input() user_id: string;

  public form: FormGroup;
  public position: string = '';
  public open: boolean = false;
  public format_info: { [x: string]: { key: FormControl<string>; value: FormControl<string> } } = {};
  public format_info_keys: string[] = []

  constructor(
    private fb: FormBuilder,
    private readonly scheduleService: ScheduleService
  ) { }

  ngOnInit(): void {
    this.formatInfo(this.info)
  }

  get acceptable_edit_roles() {
    return this.scheduleService.acceptable_edit_roles
  }

  public formatInfo(info: UserInfo[]) {
    info.forEach(({ id, key, value }) => {
      Object.assign(this.format_info, {
        [id]: this.fb.group({ key: [key], value: [value] }, {
          validators: Validators.required
        })
      })
    });

    this.form = this.fb.group(this.format_info);
    this.format_info_keys = Object.keys(this.format_info);
  }

  public addNewInfo() {
    const new_info = { key: '', value: '' }
    const key_group = Math.random();

    this.formatInfo([...this.info, { ...new_info, id: `${key_group}`, user_id: this.user_id }])
  }

  public removeInfo(key: string) {
    this.form.removeControl(key)
    this.format_info_keys = this.format_info_keys.filter(f_key => f_key !== key)
  }

  public onMouseOver(e: Event) {
    const el = e.target as HTMLDivElement;
    const { top, bottom, width } = el.getBoundingClientRect();
    this.position = `--top: ${top}px; --bottom: ${bottom}px; --width: ${width}px;`
  }

  public openSideMenu(state: boolean) {
    this.open = state
  }

  public onSubmit() {
    const format_infos_request = Object.entries(this.form.value).map(([id, values]) => {
      return ({ ...values as { key: string, value: string }, id, user_id: this.user_id })
    })
    const user_infos: UserInfo[] = JSON.parse(JSON.stringify(Object.values(format_infos_request)), (key, value) => {
      if (key === 'id' && !isNaN(+value)) return undefined;
      return value;
    });

    const handleItemsForRemove = [...this.info].filter(info => !user_infos.some(s_info => s_info.id === info.id)).map(item => item.id);

    if (handleItemsForRemove.length > 0) {
      this.scheduleService.deleteManyUserInfo(handleItemsForRemove, this.user_id);
      this.open = false
    }

    if (user_infos.some(info => !info.id)) {
      this.scheduleService.createOrUpdateTechInfo(user_infos, this.user_id);
      this.open = false
    }
  }
}
