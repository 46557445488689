import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

export interface DialogData {
  url_key: string;
}

@Component({
  selector: 'app-success-invite',
  templateUrl: './success-invite.component.html',
  styleUrls: ['./success-invite.component.scss']
})
export class SuccessInviteComponent implements OnInit {
  public accept_link: string;

  constructor(
    public dialogRef: MatDialogRef<DialogData>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.accept_link = `${environment.FRONTEND_URL}/accept-invite?key=${this.data.url_key}`;
  }

  public copyLink() {
    navigator.clipboard.writeText(this.accept_link).then(() => {
      this.alertService.alert({ message: 'Link successfully copied', type: 'info' })
    });
  }

  public onClose() {
    this.dialogRef.close();
  }
}
