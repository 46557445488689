import { gql } from "apollo-angular";
import { Message } from "src/app/entity/message.entity";

export enum ChatType {
  DEFAULT = 'DEFAULT',
  WITH_API = 'WITH_API'
}

export const SEND_MESSAGE = gql`
  mutation sendMessage(
    $prompt: String!
    $chat_type: ChatType!
   ){
    sendMessage(
      prompt: $prompt
      chat_type: $chat_type
    ) {
      id
      type
      text
      prompt
      database_result
      database_query
    }
  }
`

export interface ISendMessageDto {
  prompt: string;
  chat_type: ChatType;
}

export interface ISendMessageResponse {
  sendMessage: Message
}
