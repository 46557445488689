<div class="copy_link">
  <div class="cross" (click)="onClose()">
    <cross-icon />
  </div>

  <p class="Lexend-24 regular">Member was successfully invited!</p>
  <p class="Lexend-16 light">You can share this link to invite member.</p>
  <div class="link Lexend-16 regular" (click)="copyLink()">
    <p>{{ accept_link }}</p>
    <copy-icon />
  </div>
</div>
