<section class="container" tabindex="0">
  <table mat-table [dataSource]="unreviewed_reviews">
    <ng-container matColumnDef="Business name">
      <th mat-header-cell *matHeaderCellDef>Business name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="Reviews">
      <th mat-header-cell *matHeaderCellDef>Reviews</th>
      <td mat-cell *matCellDef="let element">
        +{{ element.count_new_reviews }} new
      </td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.reviews[0].created_at * 1000 | date : "shortDate" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element" style="width: 100px">
        <a class="link" (click)="redirectToLink(element)">
          View
          <link-icon />
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
