import { Component, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { User } from 'src/app/entity/user.entity';
import { UserRole, reversed_roles, roles } from 'src/app/enum/user-role.enum';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';

@Component({
  selector: 'invite-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  public permitted_roles: Record<string, string[]> = {
    [UserRole.ADMIN]: Object.keys(roles),
    [UserRole.MAIN_DISPATCHER]: [
      reversed_roles[UserRole.MAIN_DISPATCHER],
      reversed_roles[UserRole.DISPATCHER],
      reversed_roles[UserRole.TECHNICIAN],
    ],
  };
  public acceptable_roles = [UserRole.ADMIN, UserRole.MAIN_DISPATCHER];
  public user: User;
  public isMainDispatcher = false;
  public roles_list: string[] = [];

  constructor(
    public readonly controlContainer: ControlContainer,
    public readonly authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.authorizationService.getCurrentUser().subscribe(({ data }) => {
      this.roles_list = this.permitted_roles[data.getCurrentUser.role];
    });
  }

  get role() {
    return this.controlContainer.control?.get('role')!;
  }

  public onRoleChange(e: MatSelectChange): void {
    e.source.close();
    this.controlContainer.control?.patchValue({ location: '' });
  }
}
