import { Component, Input } from '@angular/core';
import { IGenerateDifferenceReportResponse } from 'src/app/pages/cars/apollo/query/generate-difference-report.query';

@Component({
  selector: 'difference-table',
  templateUrl: './difference-table.component.html',
  styleUrls: ['./difference-table.component.scss']
})
export class DifferenceTableComponent {
  @Input() difference: IGenerateDifferenceReportResponse['generateDifferenceReport'];

  public columns_names = ['name', 'sku', 'uhs_sku', 'template_quantity', 'actual_quantity', 'difference', ]
}
