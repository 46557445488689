import { Pipe, PipeTransform } from '@angular/core';
import { UserWithScheduleFormatted } from '../entity/schedule/user-formatted.entity';


@Pipe({ name: 'format_work_time' })
export class FormatWorkTimePipe implements PipeTransform {
  transform(day_info: UserWithScheduleFormatted['']): string {

  if (day_info.length === 0) return 'Off';
  const working_time = day_info.reduce((formatted_date, hours, index) => {
    let br = '\n';
    return (formatted_date += `${hours.work_from} - ${hours.work_to}${br}`);
  }, '');
  return working_time.trim();

  }
}
