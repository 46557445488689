import { gql } from "apollo-angular";
import { UserWithSchedule } from "src/app/entity/schedule/user-with-schedules.entity";
import { UserRole } from "src/app/enum/user-role.enum";

export const GET_USERS_WITH_SCHEDULE = gql`
  query getUsersWithSchedule(
    $userWithScheduleDto:UserWithScheduleDto!
  ) {
  getUsersWithSchedule(
    userWithScheduleDto: $userWithScheduleDto
  ) {
    id
    email
    name
    info {
      id
      key
      user_id
      value
    }

    schedules_formatted @client
    schedules {
      id
      user_id
      work_from
      work_to
    }

    schedule_requests_formatted @client
    schedule_requests {
      id
      user_id
      work_from
      work_to
    }

    notes {
      id
      note
      user_id
      week_end
      week_start
    }

    # selected @client
    workiz_id
    location
  }
  }
`;

export type IGetUsersWithScheduleDto = {
  userWithScheduleDto: {
    from: number;
    to: number;
    search_value: string;
    is_available?: boolean;
    states?: string[];
    role: UserRole[];
  }
}

export type IGetUsersWithScheduleResponse = {
  getUsersWithSchedule: UserWithSchedule[]
}


