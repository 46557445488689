<app-burger-button (handle-click)="toggleIsOpen()" [is_open]="is_open_mobile" />

<header class="header" [attr.data-open]="is_open_mobile">
  <logo-icon class="logo" />
  <nav class="nav">
    <a
      #link
      routerLink="/chat"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.chat"
    >
      <chat-icon data-icon />
      Chat
    </a>
    <a
      #link
      routerLink="/api/"
      [ngClass]="{ active: current_route.includes('api') }"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.api"
    >
      <jobs-icon data-icon />
      API
    </a>
    <a
      #link
      routerLink="/schedule/"
      [ngClass]="{ active: current_route.includes('schedule') }"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.schedule"
    >
      <schedule-icon data-icon />
      Schedule
    </a>
    <a
      #link
      routerLink="/my-schedule/"
      [ngClass]="{ active: current_route.includes('my-schedule') }"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions['my-schedule']"
    >
      <schedule-icon data-icon />
      My schedule
    </a>
    <a
      #link
      routerLink="/manage-users/users/"
      [ngClass]="{ active: current_route.includes('manage-users') }"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions['manage-users']"
    >
      <manage-users-icon data-icon />
      Manage users
    </a>
    <a
      #link
      routerLink="/profile/"
      [ngClass]="{ active: current_route.includes('profile') }"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.profile"
    >
      <profile-icon data-icon />
      Profile
    </a>
    <a
      #link
      routerLink="/settings"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.settings"
    >
      <settings-icon data-icon />
      Settings
    </a>
    <a
      #link
      routerLink="/logs"
      routerLinkActive="active"
      ariaCurrentWhenActive="page"
      class="Lexend-16 regular link"
      *ifRoles="getPermissions.logs"
    >
      <logs-icon data-icon />
      Logs
    </a>
    <accordion
      accordion_title="Inventory"
      *ifRoles="getPermissions.inventory"
      [open]="current_route.includes('cars')"
    >
      <a
        #link
        routerLink="/cars/containers"
        [ngClass]="{
          active:
            current_route.includes('cars') &&
            !current_route.includes('cars/approved-inventory')
        }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.cars"
      >
        <car-icon data-icon />
        Cars
      </a>
      <a
        #link
        routerLink="/cars/approved-inventory"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.cars"
      >
        <check-list-icon data-icon />
        Confirm inventory
      </a>
    </accordion>
    <accordion
      accordion_title="SLK Admin panel"
      *ifRoles="getPermissions.marketing"
      [open]="current_route.includes('slk-admin')"
    >
      <a
        #link
        routerLink="/slk-admin/confirmed"
        [ngClass]="{ active: current_route.includes('confirmed') }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.marketing"
      >
        <confirmed-icon data-icon />
        Confirmed
      </a>
      <a
        #link
        routerLink="/slk-admin/requests"
        [ngClass]="{ active: current_route.includes('requests') }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.marketing"
      >
        <request-icon data-icon />
        Requests
      </a>
      <a
        #link
        routerLink="/slk-admin/reviews-main"
        [ngClass]="{ active: current_route.includes('reviews-main') }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.marketing"
      >
        <chat-icon data-icon />
        Reviews
      </a>
      <a
        #link
        routerLink="/slk-admin/companies/"
        [ngClass]="{
          active:
            current_route.includes('blog') ||
            current_route.includes('location') ||
            current_route.includes('companies')
        }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.marketing"
      >
        <company-icon data-icon />
        Companies
      </a>
    </accordion>
    <accordion
      accordion_title="SLK HR"
      *ifRoles="getPermissions.hr"
      [open]="current_route.includes('vacancies')"
    >
      <a
        #link
        routerLink="/slk-hr/vacancies"
        [ngClass]="{ active: current_route.includes('vacancies') }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.hr"
      >
        <vacancies-icon data-icon />
        Vacancies
      </a>
      <a
        #link
        routerLink="/slk-g-admin/templates"
        [ngClass]="{ active: current_route.includes('templates') }"
        class="Lexend-16 regular link"
        *ifRoles="getPermissions.hr"
      >
        <templates-icon data-icon />
        Templates
      </a>
    </accordion>
  </nav>
  <button
    (click)="logout()"
    class="logout Lexend-16 regular red pointer"
    title="Log out"
  >
    <confirmed-icon data-icon />
    Log out
  </button>
</header>
<div class="backdrop"></div>
