import { gql } from 'apollo-angular';
import { FineTuneItem } from 'src/app/entity/fine-tune-item.entity';

export const UPDATE_FINE_TUNE_ITEM = gql`
  mutation updateFineTuneItem($fine_tune_item: UpdateFineTuneItemDto!) {
    updateFineTuneItem(fine_tune_item: $fine_tune_item) {
      id
      prompt
      text
    }
  }
`;

export interface IUpdateFineTuneItemDto {
  fine_tune_item: Pick<FineTuneItem, 'id' | 'prompt' | 'text'>;
}

export interface IUpdateFineTuneItemResponse {
  updateFineTuneItem: FineTuneItem;
}
