<audio
  controls
  hidden
  #audio
  [src]="audio_link"
  (canplay)="setSliderMax()"
  (timeupdate)="onTimeUpdate()"
  (ended)="changeIconState(true)"
  (pause)="changeIconState(true)"
  (play)="changeIconState(false)"
></audio>
<!-- [value]="rangeValue" -->

<div class="wrapTimeline">
  <span class="Lexend-16 light dark-blue">
    {{ durationTime | format_duration_audio }}
  </span>

  <mat-slider min="0" step="1" [max]="maxDurationTime">
    <input
      matSliderThumb
      (valueChange)="onChangeDurationRange($event)"
      [value]="durationTime"
    />
  </mat-slider>

  <span class="Lexend-16 light dark-blue">
    {{ maxDurationTime | format_duration_audio }}
  </span>
</div>

<div class="buttons">
  <change-time-right (click)="changeDuration(-1)" />
  <play-button [pause]="pause" (click)="togglePlay()" />
  <change-time-left (click)="changeDuration(1)" />
</div>
