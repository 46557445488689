<search-bar [tab]="tab" />
<wrapper-loading [loading]="loading">
  <div class="wrap-schedule-table">
    <table mat-table class="mat-elevation-z8" [dataSource]="users || []">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef style="width: 200px">Name</th>
        <td mat-cell *matCellDef="let user" data-name="Name">
          {{ user.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let user" data-name="Location">
          {{ user.location }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Info">
        <th mat-header-cell *matHeaderCellDef>Info</th>
        <td mat-cell *matCellDef="let user" data-name="Info">
          <info [info]="user.info" [user_id]="user.id" />
        </td>
      </ng-container>

      <ng-container matColumnDef="Monday">
        <th mat-header-cell *matHeaderCellDef data-day>Monday</th>
        <td mat-cell *matCellDef="let user" data-name="Monday">
          <day
            [user]="user"
            dataName="Monday"
            [dayIndex]="0"
            [day]="user.schedules_formatted.Monday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Tuesday">
        <th mat-header-cell *matHeaderCellDef data-day>Tuesday</th>
        <td mat-cell *matCellDef="let user" data-name="Tuesday">
          <day
            [user]="user"
            dataName="Tuesday"
            [dayIndex]="1"
            [day]="user.schedules_formatted.Tuesday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Wednesday">
        <th mat-header-cell *matHeaderCellDef data-day>Wednesday</th>
        <td mat-cell *matCellDef="let user" data-name="Wednesday">
          <day
            [user]="user"
            dataName="Wednesday"
            [dayIndex]="2"
            [day]="user.schedules_formatted.Wednesday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Thursday">
        <th mat-header-cell *matHeaderCellDef data-day>Thursday</th>
        <td mat-cell *matCellDef="let user" data-name="Thursday">
          <day
            [user]="user"
            dataName="Thursday"
            [dayIndex]="3"
            [day]="user.schedules_formatted.Thursday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Friday">
        <th mat-header-cell *matHeaderCellDef data-day>Friday</th>
        <td mat-cell *matCellDef="let user" data-name="Friday">
          <day
            [user]="user"
            dataName="Friday"
            [dayIndex]="4"
            [day]="user.schedules_formatted.Friday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Saturday">
        <th mat-header-cell *matHeaderCellDef data-day>Saturday</th>
        <td mat-cell *matCellDef="let user" data-name="Saturday">
          <day
            [user]="user"
            dataName="Saturday"
            [dayIndex]="5"
            [day]="user.schedules_formatted.Saturday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Sunday">
        <th mat-header-cell *matHeaderCellDef data-day>Sunday</th>
        <td mat-cell *matCellDef="let user" data-name="Sunday">
          <day
            [user]="user"
            dataName="Sunday"
            [dayIndex]="6"
            [day]="user.schedules_formatted.Sunday"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef data-note>Note</th>
        <td mat-cell *matCellDef="let user" data-name="Note">
          <note [note]="user?.notes?.[0]" [user_id]="user.id" />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</wrapper-loading>
