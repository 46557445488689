<ng-container [formGroup]="$any(controlContainer.control)">
  <mat-form-field>
    <mat-label>
      {{
        i == 0 ? "Business address" : "Business address#" + (i + 1)
      }}</mat-label
    >

    <input
      matInput
      type="text"
      id="{{ i }}"
      #addressRef
      placeholder="Enter business address"
      [formControlName]="i"
    />
    <mat-error *ngIf="addressError(i)"> Input can not be empty </mat-error>
  </mat-form-field>
</ng-container>
