import { gql } from 'apollo-angular';
import { FineTuneItem } from 'src/app/entity/fine-tune-item.entity';

export const GET_FINE_TUNE_LIST = gql`
  query getFineTuneList {
    getFineTuneList {
      id
      prompt
      text
    }
  }
`;

export type IFineTuneListResponse = {
  getFineTuneList: FineTuneItem[];
};
