<div class="wrap" [attr.data-open]="showContent">
  <div class="titleWrap Lexend-18 dark-blue medium" (click)="toggleContent()" *ngIf="!remove_icon_pressed">
    {{ name }}
    <arrow-icon [class.open]="showContent" />
  </div>

  <div class="buttons" *ngIf="remove_icon_pressed">
    <button mat-raised-button class="red" (click)="removeBlock()">
      Delete
    </button>
    <button mat-button (click)="setRemovePressed(false)">Cancel</button>
  </div>

  <div class="content">
    <cross-in-circle *ngIf="show_remove_icon" class="cross-icon pointer" (click)="toggleRemovePressed()" />
    <ng-content></ng-content>
  </div>
</div>