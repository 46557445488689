import { Component } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-style-fields-price',
  templateUrl: './style-fields-price.component.html',
  styleUrls: ['./style-fields-price.component.scss']
})
export class StyleFieldsPriceComponent {
  constructor(
    public readonly controlContainer: ControlContainer
  ) { }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }
}
