import { Component, OnInit } from '@angular/core';
import { Container } from 'src/app/entity/container.entity';
import { CarsService } from '../../cars.service';
import { Observable } from 'rxjs';
import { Template } from 'src/app/entity/template.entity';
import { CarTemplate } from 'src/app/entity/car_template.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';


@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss']
})
export class BulkActionComponent implements OnInit {
  public selected_containers: Container[] = [];
  public $templates: Observable<Template[]>;

  constructor(
    private readonly carsService: CarsService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.carsService.getAllContainers().subscribe((r) => {
      this.selected_containers = r.filter((container) => container.selected)
    })

    this.$templates = this.carsService.getAllTemplates();
  }

  public onCancel() {
    this.carsService.unselectAllContainers();
  }

  public assignTemplate(template_id: string) {
    const dto = this.selected_containers.map((container) => ({
      template_id,
      workiz_id: container.id
    }))

    this.carsService.assignManyTemplateToCar(dto).subscribe({
      complete: () => {
        this.alertService.alert({ message: 'Template successfully assigned', type: 'success' });
        this.carsService.unselectAllContainers();
      },
      error: (e) => this.alertService.alert({ message: e.message, type: 'error' })
    });
  }
}
