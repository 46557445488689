import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, TemplateRef } from '@angular/core';
import { ScrollEndRootDirective } from './scroll-end-root';
// import { ScrollEndRootDirective } from './scroll-end-root.directive';
export enum SCROLLED_DIRECTION {
  DOWN = 'down',
  UP = 'UP',
}

@Directive({
  selector: '[scrollEnd]',
})
export class ScrollEndDirective implements OnInit, OnDestroy {
  @Output() scrollEnd: EventEmitter<any> = new EventEmitter();

  @Input() rootMargin = '0px 0px 0px 0px';

  observer: IntersectionObserver;
  scrollDirection: SCROLLED_DIRECTION;

  constructor(
    private el: ElementRef,
    @Optional() private scrollEndRoot: ScrollEndRootDirective,
  ) { }

  ngOnInit(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.scrollEnd.emit();
        }
      });
    }, {
      root: this.scrollEndRoot?.el.nativeElement,
      rootMargin: this.rootMargin,
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
