import { gql } from 'apollo-angular';
import { OpenAiFineTune } from 'src/app/entity/open-ai-fine-tune.entity';

export const GET_FULL_LAST_FINE_TUNE = gql`
  query getFullLastFineTune {
    getFullLastFineTune {
      created_at
      fine_tuned_model
      events {
        created_at
        level
        message
      }
      id
      model
      status
      updated_at
    }
  }
`;

export type IFullLastFineTuneResponse = {
  getFullLastFineTune: OpenAiFineTune;
};
