import { NgModule } from '@angular/core';
import { UsersTableComponent } from './component/table/table.component';
import { ManageUsersComponent } from './component/manage-user.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { ManageUsersService } from './manage-users.service';
import { RemoveUnderscorePipe } from 'src/app/pipe/remove-underscore.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BulkActionComponent } from './component/bulk-action/bulk-action.component';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { InviteUserComponent } from './component/invite-user/invite-user.component';
import { MatSelectModule } from '@angular/material/select';
import { StatesComponent } from './component/states/states.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SuccessInviteComponent } from './component/success-invite/success-invite.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { DeleteDialogModule } from 'src/app/modules/ui-kit/delete-item-popup/delete-dialog.module';
import { RolesComponent } from './component/invite-user/roles/roles.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    SideMenuModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    IconsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    DeleteDialogModule,
    DirectiveModule,
    RouterModule.forChild([
      {
        path: 'manage-users',
        title: 'Manage Users | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: 'users',
            component: ManageUsersComponent,
          },
          {
            path: 'invited-users',
            component: ManageUsersComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions['manage-users'],
        },
      },
    ]),
  ],
  providers: [ManageUsersService],
  declarations: [
    UsersTableComponent,
    ManageUsersComponent,
    RemoveUnderscorePipe,
    BulkActionComponent,
    InviteUserComponent,
    StatesComponent,
    SuccessInviteComponent,
    RolesComponent,
  ],
})
export class ManageUsersModule {}
