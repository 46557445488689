import { Component, Input } from '@angular/core';
import { Reviews } from 'src/app/entity/reviews.entity';
import { ReviewsStatus } from 'src/app/enum/reviews-status.enum';
import { ReviewsService } from '../../reviews.service';

@Component({
  selector: 'review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent {
  @Input() review: Reviews;
  constructor(public reviewsService: ReviewsService) {}

  getStarsRating(rating: number): number[] {
    return Array(rating).fill(0);
  }

  deleteReview(id: string) {
    this.reviewsService.reviewReview(id, ReviewsStatus.REJECTED);
  }

  primaryClick({ status, id }: Reviews) {
    this.reviewsService.reviewReview(
      id,
      status === ReviewsStatus.UNDER_CONSIDERATION
        ? ReviewsStatus.APPROVED
        : ReviewsStatus.UNDER_CONSIDERATION
    );
  }
}
