<ng-container [formGroup]="$any(controlContainer.control)">
  <div formArrayName="address" class="wrap">
    <div *ngFor="let address of addresses; let i = index" class="inp_wrap">
      <AutocompleteComponent [i]="i" [form]="form" />

      <cross-in-circle
        *ngIf="addresses.length > 1"
        class="circle"
        (click)="deleteAddress(i)"
      />
    </div>
  </div>
</ng-container>
