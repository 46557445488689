import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { UserWithScheduleFormatted } from 'src/app/entity/schedule/user-formatted.entity';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { DayAbstractService } from './day-abstract.service';

@Component({
  selector: 'day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
})
export class DayComponent implements OnInit {
  @Input() dayIndex: number;
  @Input() dataName: string;
  @Input() user: UserWithSchedule;
  @Input() day: UserWithScheduleFormatted[''];
  @Input() title_name: string;

  public editDate: TimeTemplate | undefined;

  public open = false;
  public step: 'picker' | 'create-period' | 'requests' = 'picker';
  public titleByStep: string;
  public time_periods: TimeTemplate[] = [];

  constructor(private readonly scheduleService: DayAbstractService) {}

  ngOnInit(): void {
    this.handleTitle();
    this.scheduleService.getAllTimeTemplates().subscribe((time_periods) => {
      this.time_periods = time_periods;
    });
  }

  get acceptable_edit_roles() {
    return this.scheduleService.acceptable_edit_roles;
  }

  public get request_schedules(): UserWithScheduleFormatted[''] | undefined {
    return this.user.schedule_requests_formatted?.[this.dataName];
  }

  public handleTitle() {
    if (this.step === 'picker') {
      this.titleByStep = this.title_name || this.user.name + ' schedule';
    } else if (this.step === 'requests') {
      this.titleByStep = 'Requests';
    } else {
      if (this.editDate) {
        this.titleByStep = 'Edit period';
      } else {
        this.titleByStep = 'Add new period';
      }
    }
  }

  public setTimePeriod(step: 'picker' | 'create-period' | 'requests') {
    this.step = step;
    this.handleTitle();
  }

  public setOpenMenu(state: boolean) {
    const day_depend_table = this.scheduleService.startWeek
      .clone()
      .add(this.dayIndex, 'day');
    const current_day = moment().startOf('day');
    const handle_open_sidebar = current_day.isSameOrBefore(day_depend_table);
    if (handle_open_sidebar) {
      this.open = state;
      if (!state) {
        this.step = 'picker';
      }
    }
  }
}
