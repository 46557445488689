<svg
  width="52"
  height="52"
  viewBox="0 0 52 52"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  [attr.data-pause]="pause"
  class="svg"
>
  <path
    d="M26 47.6663C37.9661 47.6663 47.6666 37.9658 47.6666 25.9997C47.6666 14.0335 37.9661 4.33301 26 4.33301C14.0338 4.33301 4.33331 14.0335 4.33331 25.9997C4.33331 37.9658 14.0338 47.6663 26 47.6663Z"
    fill="#006FDC"
  />
  <path
    class="firstLine"
    d="M21.6667 32.5V19.5"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    class="lastLine"
    d="M30.3333 32.5V19.5"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />

  <path
    class="triangle"
    d="M21.6667 17.333L34.6667 25.9997L21.6667 34.6663V17.333Z"
    fill="white"
  />
</svg>
