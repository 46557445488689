import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ICtmGetCallsOptionResponse } from '../../../apollo/query/get-calls.query';
import { MatDialog } from '@angular/material/dialog';
import { TabsComponent } from './tabs/tabs.component';
import { CtmCall } from 'src/app/entity/ctm-call.entity';

@Component({
  selector: 'ctm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class CtmTableComponent implements OnInit {
  public calls_info?: ICtmGetCallsOptionResponse['getCalls'];
  public displayedColumns: string[] = ['Phone', 'Call Status', 'Company', 'Date', 'Duration', 'Audio'];

  constructor(
    private readonly apiService: ApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.apiService.getCalls({ page: 1, per_page: 40 })
      .subscribe((data) => {
        this.calls_info = data
      })
  }

  public loadMore() {
    this.apiService.getCalls({ page: (this.calls_info!.calls.length % 39) + 1, per_page: 40 })
      .subscribe((data) => {
        this.calls_info = { ...data, calls: [...this.calls_info!.calls, ...data.calls] }
      })
  }

  public openDialog(call: CtmCall) {
    this.dialog.open(TabsComponent, {
      width: '100%',
      maxWidth: '544px',
      data: { call }
    });
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

}
