<ng-container [formGroup]="$any(controlContainer.control)">
  <p class="Lexend-16 regular grey">State:</p>

  <mat-radio-group class="list" formControlName="location">
    <mat-radio-button *ngFor="let location of locations" [value]="location">
      {{ location }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-error
    *ngIf="controlContainer.control!.get('location')!.hasError('required')"
  >
    *Please choose a location
  </mat-error>
</ng-container>
