<div class="wrap-page">
  <div class="head">
    <h1 class="Lexend-32 regular">Schedule</h1>

    <navigation />
    <schedule-calendar />
  </div>

  <mat-tab-group
    animationDuration="0"
    (selectedTabChange)="setTab($event.tab.textLabel)"
  >
    <mat-tab label="Technicians" [disabled]="disabled">
      <table-schedule tab="Technicians" [users]="users" [loading]="loading" />
    </mat-tab>
    <mat-tab label="Dispatchers" [disabled]="disabled">
      <table-schedule tab="Dispatchers" [users]="users" [loading]="loading" />
    </mat-tab>
  </mat-tab-group>
</div>
