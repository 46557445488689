import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { UserRole } from 'src/app/enum/user-role.enum';
import { ScheduleService } from './services/schedule.service';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  public open_calendar: boolean = false;
  public tab: "Technicians" | "Dispatchers" = "Technicians"

  public get weekStartDate(): string {
    return this.scheduleService.weekStartDate
  }
  public get startWeek(): moment.Moment {
    return this.scheduleService.startWeek
  }

  public get weekEndDate(): string {
    return this.scheduleService.weekEndDate
  }
  public get endWeek(): moment.Moment {
    return this.scheduleService.endWeek
  }

  public get users(): UserWithSchedule[] {
    return this.scheduleService.users
  }
  public get loading(): boolean {
    return this.scheduleService.loading
  }
  public get disabled() {
    return this.scheduleService.disabled
  }

  constructor(
    private readonly scheduleService: ScheduleService
  ) { }

  ngOnDestroy(): void {
    this.setTab('Technicians')
  }

  ngOnInit(): void {
    this.scheduleService.getUsersWithSchedule(true)
  }

  public setTab(tab: string) {
    const roles = {
      "Technicians": [UserRole.TECHNICIAN],
      "Dispatchers": [UserRole.MAIN_DISPATCHER, UserRole.DISPATCHER],
    }
    this.scheduleService.setRoles = roles[tab as keyof typeof roles];
  }

  public toggleOpenCalendar(state: boolean) {
    this.open_calendar = state;
  }

  public setNextWeekIndex(week_index: number) {
    this.scheduleService.setWeekIndex(week_index);
  }

  public setCalendarIndex({ start }: { start: moment.Moment, end: moment.Moment }) {
    const calc_current_week = start.clone().startOf('isoWeek').week() - this.scheduleService.currentWeek.clone().startOf('isoWeek').week();

    this.setNextWeekIndex(calc_current_week);
    this.toggleOpenCalendar(false)
  }
}
