import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../companies.service';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  public is_open: string | null = null;
  public companies: ConstructedCompany[];
  constructor(
    public dialog: MatDialog,
    private readonly companiesService: CompaniesService
  ) {}

  ngOnInit(): void {
    this.companiesService
      .getAllCompanies()
      .valueChanges.subscribe(({ data }) => {
        this.companies = data.getConstructedPageCompanies;
      });
  }
}
