import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { PasswordPointersComponent } from './component/password/password-pointers/password-pointers.component';
import { PointerComponent } from './component/password/password-pointers/pointer/pointer.component';
import { PasswordComponent } from './component/password/password.component';
import { ProfileComponent } from './component/profile.component';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { PersonalInfoComponent } from './component/personal-information/personal-information.component';
import { ProfileService } from './profile.service';
import { PasswordInputModule } from 'src/app/modules/ui-kit/password-input/password-input.module';

@NgModule({
  imports: [
    IconsModule,
    MatTabsModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    PasswordInputModule,
    RouterModule.forChild([
      {
        path: 'profile',
        title: 'Profile | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ProfileComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.profile,
        },
      },
    ]),
  ],
  declarations: [
    ProfileComponent,
    PointerComponent,
    PersonalInfoComponent,
    PasswordComponent,
    PasswordPointersComponent,
  ],
  providers: [ProfileService],
})
export class ProfileModule {}
