import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { DropDown } from './component/dropdown.component';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({
  imports: [IconsModule, CommonModule, MatButtonModule, DirectiveModule],
  declarations: [DropDown],
  exports: [DropDown],
})
export class DropDownModule {}
