import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GET_ALL_USERS, IGetAllUsersResponse } from "./apollo/query/get-all-users.query";
import { Observable, map } from "rxjs";
import { User } from "src/app/entity/user.entity";
import { DELETE_MANY_USERS, IDeleteManyUsersDto, IDeleteManyUsersResponse } from "./apollo/mutation/delete-many-users.mutation";
import { DELETE_MANY_INVITED_USERS } from "./apollo/mutation/delete-many-invited-users.mutation";
import { GET_ALL_USERS_WORKIZ, IGetAllUsersWorkizResponse } from "./apollo/query/get-all-users-workiz.query";
import { INVITE_USER_TO_APP, InviteUserDto, InviteUserResponse } from "./apollo/mutation/invite-user-to-app.mutation";
import { IUpdateUserDto, IUpdateUserResponse, UPDATE_USER } from "./apollo/mutation/update-user.mutation";
export interface SelectUserArgs {
  users: User[];
  selected: boolean;
  select_user_id?: string;
  query_name: 'getAllUsers' | 'getAllInvitedUsers';
}
@Injectable()
export class ManageUsersService {
  private current_tab: string = 'Users';
  private open_side_menu: boolean = false;
  private edit_user?: User;

  constructor(
    private readonly apollo: Apollo,
  ) { }

  get getCurrentTab() {
    return this.current_tab;
  }

  get getIsOpenMenu() {
    return this.open_side_menu;
  }

  get getEditUser() {
    return this.edit_user
  }

  set setEditUser(user: User | undefined) {
    this.edit_user = user;
  }

  set setCurrentTab(new_tab: string) {
    this.unselectAllUsers();
    this.current_tab = new_tab;
  }

  public toggleSideMenu() {
    this.open_side_menu = !this.open_side_menu;
  }

  public getUsers(): Observable<IGetAllUsersResponse> {
    return this.apollo.watchQuery<IGetAllUsersResponse, {}>({
      query: GET_ALL_USERS,
    })
      .valueChanges
      .pipe(map((response) => response.data))
  }

  public getAllUsersWorkiz(): Observable<IGetAllUsersWorkizResponse> {
    return this.apollo.watchQuery<IGetAllUsersWorkizResponse, {}>({
      query: GET_ALL_USERS_WORKIZ,
    })
      .valueChanges
      .pipe(map((response) => response.data))
  }

  public deleteManyUsers(user_ids: string[]) {
    return this.apollo.mutate<IDeleteManyUsersResponse, IDeleteManyUsersDto>({
      mutation: DELETE_MANY_USERS,
      variables: {
        user_ids
      },
      update: (cache) => {
        const existing = this.getAllUsersFromCache();
        const newUsers = existing?.getAllUsers.filter(user => !user_ids.includes(user.id));

        cache.writeQuery({
          query: GET_ALL_USERS,
          data: {
            getAllInvitedUsers: existing?.getAllInvitedUsers,
            getAllUsers: newUsers,
          },
        });
      }
    });
  }

  public deleteManyInvitedUsers(user_ids: string[]) {
    return this.apollo.mutate<IDeleteManyUsersResponse, IDeleteManyUsersDto>({
      mutation: DELETE_MANY_INVITED_USERS,
      variables: {
        user_ids
      },
      update: (cache) => {
        const existing = this.getAllUsersFromCache();
        const newUsers = existing?.getAllInvitedUsers.filter(user => !user_ids.includes(user.id));

        cache.writeQuery({
          query: GET_ALL_USERS,
          data: {
            getAllUsers: existing?.getAllUsers,
            getAllInvitedUsers: newUsers,
          },
        });
      }
    });
  }

  public updateUser(user: IUpdateUserDto['user']) {
    return this.apollo.mutate<IUpdateUserResponse, IUpdateUserDto>({
      mutation: UPDATE_USER,
      variables: {
        user,
      }
    });
  }

  public inviteUserToApp(inviteUserDto: InviteUserDto['inviteUserDto']) {
    return this.apollo.mutate<InviteUserResponse, InviteUserDto>({
      mutation: INVITE_USER_TO_APP,
      variables: {
        inviteUserDto,
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getAllInvitedUsers: (exist) => ([...exist, data?.inviteUserToApp]),
          },
        });
      },
    });
  }

  public selectUser({ users, selected, select_user_id, query_name }: SelectUserArgs) {
    const old_data = this.getAllUsersFromCache();

    this.apollo.client.cache.writeQuery({
      query: GET_ALL_USERS,
      data: {
        ...old_data,
        [query_name]: users.map((map_user) => {
          if (map_user.id === select_user_id || !select_user_id) {
            return { ...map_user, selected };
          }

          return map_user;
        }),
      },
    });
  }

  public unselectAllUsers() {
    const old_data = this.getAllUsersFromCache();

    this.apollo.client.cache.writeQuery({
      query: GET_ALL_USERS,
      data: {
        getAllUsers: old_data?.getAllUsers.map((map_user) => {
          return { ...map_user, selected: false };
        }),
        getAllInvitedUsers: old_data?.getAllInvitedUsers.map((map_user) => {
          return { ...map_user, selected: false };
        }),
      },
    });
  }

  private getAllUsersFromCache() {
    return this.apollo.client.readQuery<IGetAllUsersResponse>({
      query: GET_ALL_USERS,
    });
  }
}
