import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { AllLocksmithService } from '../../common/services/all-locksmith.service';

@Injectable()
export class ConfirmedService extends AllLocksmithService {
  public item_btn_name: string = 'Edit';
  path: string = 'confirmed-locksmith';
  constructor(apollo: Apollo, alertService: AlertService, router: Router) {
    super(apollo, alertService, router);
  }

  searchLocksmith(value: string) {
    this.setSearchValue(value, true);
  }

  getLocksmith() {
    this.getAllLocksmith(true);
  }

  togglePage(event: PageEvent) {
    this.handlePageChange(event, true);
  }

  deleteLocksmith(id: string) {
    this.deleteItem(id, true);
  }
}
