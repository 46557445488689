import { gql } from 'apollo-angular';
import { ReviewsStatus } from 'src/app/enum/reviews-status.enum';

export const REVIEW_REVIEW = gql`
  mutation reviewManyLocksmithReviews($reviews: [ReviewLocksmithReviewDto!]!) {
    reviewManyLocksmithReviews(reviews: $reviews) {
      created_at
      id
      locksmith_id
      name
      rating
      status
      text
      title
      updated_at
    }
  }
`;

export interface IReviewReviewsDto {
  reviews: {
    id: string;
    status: ReviewsStatus;
  };
}

export interface IReviewReviewsResponse {
  reviewManyLocksmithReviews: ReviewReviews;
}

export interface ReviewReviews {
  created_at: number;
  id: string;
  locksmith_id: string;
  name: string;
  rating: number;
  status: ReviewsStatus;
  text: string;
  title: string;
  updated_at: number;
}
