import { gql } from "apollo-angular";

export const GENERATE_DIFFERENCE_REPORT = gql`
query generateDifferenceReport ($workiz_id: String!) {
  generateDifferenceReport(workiz_id: $workiz_id) {
    actual_quantity
    difference
    name
    sku
    template_quantity
    uhs_sku
    template_item_id
  }
}
`

export interface IGenerateDifferenceReportResponse {
  generateDifferenceReport: {
    actual_quantity: number;
    difference: number;
    name: string;
    sku: string;
    template_quantity: number;
    uhs_sku: string;
    template_item_id: string;
  }[]
}

export interface IGenerateDifferenceReportDto {
  workiz_id: string
}
