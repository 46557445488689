import { Component, OnInit } from '@angular/core';
import { WorkizItem } from 'src/app/entity/workiz-item.entity';
import { CarsService } from '../../../cars.service';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { IGenerateDifferenceReportResponse } from '../../../apollo/query/generate-difference-report.query';
@Component({
  selector: 'app-specific-container',
  templateUrl: './specific-container.component.html',
  styleUrls: ['./specific-container.component.scss'],
})
export class SpecificContainerComponent implements OnInit {
  public container_items: WorkizItem[];
  public container_id: string;
  public loading = true;
  public difference: IGenerateDifferenceReportResponse['generateDifferenceReport'];

  constructor(
    private readonly carsService: CarsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.container_id = this.route.snapshot.paramMap.get('container_id')!;
    this.carsService.getContainerById(this.container_id).subscribe((r) => {
      this.container_items = r.filter((r) => r.id);
      this.loading = false
    });
    this.carsService.generateDifferenceReport(this.container_id).subscribe((r) => {
      this.difference = r
    });

  }

  public downloadDifference() {
    this.carsService.generateDifferenceReport(this.container_id).subscribe((r) => {
      this.generateExcelFile(r);
    });
  }

  private generateExcelFile(data: IGenerateDifferenceReportResponse['generateDifferenceReport']) {
    const csvData = [
      'Name',
      'SLK SKU',
      'UHS SKU',
      'Template quantity',
      'Actual quantity',
      'Difference',
    ];

    const filteredData = data.map((key) => {
      const { name, sku, uhs_sku, template_quantity, actual_quantity, difference } = key;
      return [name, sku, uhs_sku, template_quantity, actual_quantity, difference];
    });

    const file_data = [csvData, ...filteredData];
    const worksheet = XLSX.utils.aoa_to_sheet(file_data);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Responses');
    XLSX.writeFile(newWorkbook, `difference-${moment().format('DD.MM.YYYY')}.xlsx`);
  };
}
