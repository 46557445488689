import { gql } from "apollo-angular";
import { TimeTemplate } from "src/app/entity/time-template.entity";

export const CREATE_TIME_TEMPLATE = gql`
mutation createTimeTemplate($time_template: CreateTimeTemplateDto!) {
  createTimeTemplate(time_template: $time_template) {
    id
    color
    name
    value
  }
}`;

type ICreateTimeTemplate = Omit<TimeTemplate, 'id'>;

export type ICreateTimeTemplateDto = {
  time_template: ICreateTimeTemplate
}

export type ICreateTimeTemplateResponse = {
  createTimeTemplate: TimeTemplate
}
