<div class="wrap">
  <div class="day_title">
    <mat-slide-toggle
      (click)="includeDay()"
      [checked]="!!open_at && !!close_at"
      (change)="handleChange($event)"
    >
      <p class="Lexend-14">{{ name | titlecase }}</p>
    </mat-slide-toggle>
  </div>

  <div class="time" (clickOutside)="outsideOpenAt()">
    <div>
      <time-select
        #open_at_ref
        (setTime)="setOpenAt($event)"
        [time]="open_at || '12:00 AM'"
        [attr.data-disable]="!!open_at"
        [canOpen]="true"
      />
    </div>
    -
    <div>
      <time-select
        #close_at_ref
        (setTime)="setCloseAt($event)"
        [time]="close_at || '11:59 PM'"
        [attr.data-disable]="!!close_at"
        [canOpen]="!!open_at"
      />
    </div>
  </div>
</div>
