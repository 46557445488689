import { Component, ElementRef, Input, ViewChild} from '@angular/core';


@Component({
  selector: 'player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent {
  @ViewChild('audio') audio: ElementRef<HTMLAudioElement>;
  @Input() audio_link: string;

  public readonly DEFAULT_SKIP_TIME = 15;
  public pause = true;

  public durationTime: number = 0;
  public maxDurationTime: number = 0;
  public rangeValue = 0;


  togglePlay() {
    if (this.audio.nativeElement.paused) this.audio.nativeElement.play();
    else this.audio.nativeElement.pause();
  }

  public setSliderMax() {
    this.maxDurationTime = Math.floor(this.audio.nativeElement.duration);
  };

  public changeIconState(value: boolean = false) {
    this.pause = value
  };

  public onPlayEnded() {
    this.changeIconState(true);
  };

  public onTimeUpdate() {
    const audioTime = Math.floor(this.audio.nativeElement.currentTime);
    if (this.durationTime !== audioTime) {
      this.durationTime = audioTime
    }
  };

  public onChangeDurationRange(value: number) {
    this.durationTime = value;
    this.audio.nativeElement.currentTime = value
  }

  public changeDuration(direction: number) {
    this.audio.nativeElement.currentTime += this.DEFAULT_SKIP_TIME * direction;
  };
}


