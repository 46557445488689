import { gql } from "apollo-angular";

export const DELETE_CONSTRUCTED_PAGE_BY_ID = gql`
  mutation deleteConstructedPageById($id: ID!) {
    deleteConstructedPageById(id: $id)
  }
`;

export type IDeleteConstructedPageByIdDto = {
  id: string;
}
export type IDeleteConstructedPageByIdResponse = {
  deleteConstructedPageById: string;
}
