import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { UploadModule } from 'src/app/modules/ui-kit/upload/upload.module';
import { addressInpComponent } from './component/addres-inp/address-inp.component';
import { AutocompleteComponent } from './component/addres-inp/autocomplete/autocomplete.component';
import { companyFormComponent } from './component/company-form.component';
import { IdLinkComponent } from './component/id-link/id-link.component';
import { ImageComponent } from './component/image/image.component';
import { ServiceChipsComponent } from './component/services-chips/services-chips.component';
import { DayItemComponent } from './component/working-schedule/day-item/day-item.component';
import { TimeComponent } from './component/working-schedule/day-item/time/time.component';
import { WorkingScheduleComponent } from './component/working-schedule/working-schedule.component';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormAbstractService } from '../services/form-abstract.service';

@NgModule({
  imports: [
    FormsModule,
    MatFormFieldModule,
    RouterModule,
    MatSlideToggleModule,
    DirectiveModule,
    MatInputModule,
    ReactiveFormsModule,
    UploadModule,
    MatButtonModule,
    CommonModule,
    MatChipsModule,
    MatSelectModule,
    IconsModule,
  ],
  declarations: [
    companyFormComponent,
    WorkingScheduleComponent,
    IdLinkComponent,
    TimeComponent,
    AutocompleteComponent,
    ImageComponent,
    addressInpComponent,
    DayItemComponent,
    ServiceChipsComponent,
  ],
  exports: [companyFormComponent],
})
export class CompanyFormModule {
  static inversion(
    useClass: Type<FormAbstractService>
  ): ModuleWithProviders<CompanyFormModule> {
    return {
      ngModule: CompanyFormModule,
      providers: [
        {
          provide: FormAbstractService,
          useExisting: useClass,
        },
      ],
    };
  }
}
