<ng-container [formGroup]="$any(controlContainer.control)">
  <p>Working schedule</p>
  <div>
    <mat-slide-toggle
      class="toggle"
      (change)="handleChange()"
      [checked]="isFullWeek"
    >
      <p class="Lexend-14">24/7</p>
    </mat-slide-toggle>
  </div>
  <div formArrayName="schedules" class="working_schedule">
    <day-of-week
      *ngFor="let day of schedule; let i = index"
      [index]="i"
      [day]="day"
      [form]="form"
    />
  </div>
</ng-container>
