<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Headline </mat-label>
    <input matInput type="text" placeholder="Type headline here" formControlName="headline" />
  </mat-form-field>

  <mat-form-field>
    <mat-label> List </mat-label>
    <textarea cdkTextareaAutosize matInput placeholder="Type list here" formControlName="list"></textarea>
  </mat-form-field>
</form>