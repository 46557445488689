import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { roles } from 'src/app/enum/user-role.enum';
import { choose_location_list } from '../../constant/states.constant';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})
export class StatesComponent implements OnChanges {
  @Input() role: keyof typeof roles;
  public locations: string[];

  constructor (public readonly controlContainer: ControlContainer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['role']) {
      this.locations = choose_location_list[roles[this.role]];
    }
  }
}
