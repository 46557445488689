import { gql } from 'apollo-angular';
import { Log } from 'src/app/entity/log.entity';

export const GET_LOGS = gql`
  query getLogs($pagination: FindPaginationDto!) {
    getLogs(pagination: $pagination) {
      items {
        action
        created_at
        group
        id
        message
        type
        user_id
        user {
          name
          email
        }
      }
      total
    }
  }
`;

export type IGetLogsDto = {
  pagination: {
    skip: number;
    take: number;
  };
};

export type IGetLogsResponse = {
  getLogs: {
    items: Log[];
    total: number;
  };
};
