import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserNote } from 'src/app/entity/schedule/user-note.entity';
import { ScheduleService } from '../../../pages/schedule/services/schedule.service';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() note?: UserNote;
  @Input() user_id: string;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  public open_notes = false;

  public form = new FormGroup({
    note: new FormControl(''),
  })

  constructor(
    private readonly scheduleService: ScheduleService
  ) { }

  ngOnInit(): void {
    this.form.patchValue({ note: this.note?.note || '' });
  }

  get acceptable_edit_roles() {
    return this.scheduleService.acceptable_edit_roles
  }

  public openNotes() {
    this.open_notes = !this.open_notes
  }

  public onSave() {
    this.scheduleService.createOrUpdateUserNote({
      id: this.note?.id,
      note: this.form.value.note!,
      user_id: this.user_id,
      week_end: this.scheduleService.endWeek.clone().unix(),
      week_start: this.scheduleService.startWeek.clone().unix(),
    } as UserNote);
  }
}
