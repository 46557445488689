import { gql } from "apollo-angular";

export const START_UPDATE_JOBS_INFO = gql`
 mutation startUpdateJobsInfo(
  $account: Account!
  $from_month: Int
  $from_year: Int
) {
  startUpdateJobsInfo(
    account: $account
    from_month: $from_month
    from_year: $from_year
  )
}
`;

export type IStartUpdateJobsInfoDto = {
  account: 'main' | 'arizona'
  from_month: number;
  from_year: number;
}

export type IStartUpdateJobsInfoResponse = {
  startUpdateJobsInfo: string
}
