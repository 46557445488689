import { Injectable } from "@angular/core";
import { CalendarScheduleAbstractService } from "../../../components/calendar/calendar.abstract.service";
import { NavigationAbstractService } from "../../../components/navigation/navigation.abstract.service";
import { ScheduleService } from "./schedule.service";

@Injectable()
export class ChangeDateService implements NavigationAbstractService, CalendarScheduleAbstractService {
  constructor(
    private readonly scheduleService: ScheduleService,
  ) { }
  public name = 'schedule'
  public get disabled(): boolean {
    return this.scheduleService.disabled
  };

  public get startWeek() {
    return this.scheduleService.startWeek
  }

  public get endWeek() {
    return this.scheduleService.endWeek
  }

  public get weekStartDate() {
    return this.scheduleService.weekStartDate
  }

  public get weekEndDate() {
    return this.scheduleService.weekEndDate
  }

  public setWeekIndex(week_index: number) {
    this.scheduleService.setWeekIndex(week_index);
  }

  public setCalendarIndex({ start }: { start: moment.Moment, end: moment.Moment }) {
    const calc_current_week = start.clone().startOf('isoWeek').week() - this.scheduleService.currentWeek.clone().startOf('isoWeek').week();

    this.setWeekIndex(calc_current_week);
  }


}
