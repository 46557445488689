import { Injectable } from "@angular/core";
import { InviteUserAbstractService } from "./invite-user.service";
import { AlertService } from "src/app/modules/ui-kit/alert/alert.service";
import { ManageUsersService } from "../../../manage-users.service";
import { IUpdateUserDto } from "../../../apollo/mutation/update-user.mutation";
import { Observable } from "rxjs";


@Injectable()
export class UpdateUserService extends InviteUserAbstractService {
  public loading = false;

  constructor(
    private readonly manageUsersService: ManageUsersService,
    private readonly alertService: AlertService,
  ) {
    super();
  }

  public onSubmit(user: IUpdateUserDto['user']) {
    return new Observable<void>((observer) => {
      this.manageUsersService.updateUser(user).subscribe({
        next: ({ loading }) => {
          observer.next();
          this.loading = loading;
        },
        complete: () => {
          observer.complete();
          this.manageUsersService.setEditUser = undefined;
          this.alertService.alert({ message: 'User successfully updated', type: 'success' });
        },
        error: ({ message }) => {
          observer.error(message);
          this.alertService.alert({ message, type: 'error' });
          this.loading = false;
        },
      });
    });
  }
}
