<div class="ReplacementCostAchord"></div>
<div class="coverBg">

  <div
    class="coverInfoBtn"
    *ngIf="parsed_styles?.add_top_need_more_info_button"
    style="padding-top: 50px"
  >
    <app-need-more-info-button />
  </div>

  <div class="cover">
    <div class="coverTitle">
      <h2>{{ data.headline }}</h2>
      <hr class="yellowLine" />
    </div>
    <div class="coverText">
      <p *ngFor="let item of data?.description?.split('\n')" class="text" [innerHTML]="item"></p>
    </div>
    <div class="wrapColumn">
      <div
        class="data"
        [ngClass]="{
          leftColumnScroll: data.list && data.list.split('\n').length > 8,
          leftColumn: data.list && data.list.split('\n').length < 8
        }"
      >
        <div class="coverPriceList" *ngFor="let item of data.list?.split('\n')">
          <div class="serviceName" *ngIf="item.split('##-##')[0]">
            <hr />
            <p class="textIemList">{{ item.split("##-##")[0] }}</p>
          </div>
          <p class="textIemList" *ngIf="item.split('##-##')[1]">
            {{ item.split("##-##")[1] }}
          </p>
        </div>
      </div>
      <p class="leftText">{{ data.body_text }}</p>
    </div>
    <div class="coverText">
      <p class="text" *ngFor="let item of data.last_text?.split('\n')" [innerHTML]="item"></p>
    </div>
  </div>

  <div
    class="coverInfoBtn"
    *ngIf="parsed_styles?.add_bottom_need_more_info_button"
    style="padding-bottom: 50px"
  >
    <app-need-more-info-button />
  </div>
</div>
