<ng-container [formGroup]="$any(controlContainer.control)">
  <p class="title Lexend-14">Services you provide</p>
  <mat-chip-listbox selectable multiple formArrayName="services">
    <mat-chip-option
      *ngFor="let option of options"
      [value]="option"
      [selected]="checkSelected(option)"
      (click)="toggleService(option)"
    >
      {{ option }}
    </mat-chip-option>
  </mat-chip-listbox>
</ng-container>
