import { Component, Input } from '@angular/core';
import { UserWithScheduleFormatted } from 'src/app/entity/schedule/user-formatted.entity';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { ScheduleService } from '../../../pages/schedule/services/schedule.service';

@Component({
  selector: 'requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent {
  @Input() dataName: string;
  @Input() user: UserWithSchedule;
  @Input() day: UserWithScheduleFormatted[''] | undefined;
  constructor(
    private readonly scheduleService: ScheduleService
  ) { }

  public get name(): string {
    return this.user.name
  }

  public get day_name(): string {
    return this.dataName
  }

  public approveUserScheduleRequest() {
    this.scheduleService.approveUserScheduleRequest(this.user.schedule_requests.map(({ id }) => id))
  }

  public declineUserScheduleRequest() {
    this.scheduleService.declineUserScheduleRequest(this.user.schedule_requests.map(({ id }) => id))
  }

}
