import { gql } from "apollo-angular";
import { Message } from "src/app/entity/message.entity";

export const CREATE_FINE_TUNE_ITEM = gql`
  mutation createFineTuneItem(
    $prompt: String!
    $text: String!
  ) {
    createFineTuneItem (
      prompt: $prompt
      text: $text
    ){
      id
      prompt
      text
    }
  }
`


export interface ICreateFineTuneItemDto {
  prompt: string;
  text: string;
}

export interface ICreateFineTuneItemResponse {
  createFineTuneItem: Message
}
