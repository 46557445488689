import { gql } from "apollo-angular";
import { User } from "src/app/entity/user.entity";

export const INVITE_USER_TO_APP = gql`
mutation inviteUserToApp ($inviteUserDto: InviteUserDto!) {
  inviteUserToApp(inviteUserDto: $inviteUserDto) {
    id
    email
    key
    role
    location
    name
    workiz_id
  }
}
`

export interface InviteUserDto {
  inviteUserDto: Pick<User, 'email' | 'location' | 'role' | 'name' | 'workiz_id'>,
}

export interface InviteUserResponse {
  inviteUserToApp: User & { key: string };
}
