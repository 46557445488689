import { Component, Inject } from '@angular/core';
import { ConstructorAbstractService } from '../../constructor.abstract.service';
import { ConstructorService } from '../../constructor.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'error-modal-constructor',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalConstructorComponent {
  constructor(
    private readonly constructorAbstractService: ConstructorAbstractService,
    protected readonly constructorService: ConstructorService,
    @Inject(MAT_DIALOG_DATA) public data: { error: string },
  ) { }

  public get form() {
    return this.constructorAbstractService.form;
  }
}
