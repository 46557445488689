import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConstructorService } from '../constructor.service';
import { environment } from 'src/environments/environment';
import { FormTemplates } from '../component/fields-control/fields-control.form.template';

@Pipe({
  standalone: true,
  name: 'TransformTypeBlockToTitle',
})
export class TransformTypeBlockToTitlePipe implements PipeTransform {
  constructor() { }

  transform(type_block: FormTemplates): string {
    console.log(type_block);

    return ''
  }
}
