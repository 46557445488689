<div class="wrap-calendar" (clickOutside)="toggleOpenCalendar(false)">
  <p
    class="week Lexend-24 blue pointer"
    (click)="toggleOpenCalendar(!open_calendar)"
    [ngClass]="{ open: open_calendar }"
  >
    {{ weekStartDate }} - {{ weekEndDate }}
  </p>
  <calendar
    *ngIf="open_calendar"
    class="calendar"
    [start]="startWeek"
    [end]="endWeek"
    (saveChanges)="setCalendarIndex($event)"
    (cancelChanges)="toggleOpenCalendar(false)"
  />
</div>
