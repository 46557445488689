import { gql } from "apollo-angular";

export const DELETE_MANY_TEMPLATES = gql`
mutation deleteManyTemplates ($ids: [ID!]!) {
  deleteManyTemplates (ids: $ids)
}
`

export interface IDeleteManyTemplatesResponse {
  deleteManyTemplates: string[];
}

export interface IDeleteManyTemplatesDto {
  ids: string[];
}
