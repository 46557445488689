import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UploadComponent } from './component/upload.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UploadComponent],
  exports: [UploadComponent],
})
export class UploadModule {}
