import { gql } from "apollo-angular";

export const CREATE_OR_UPDATE_USER_SCHEDULE_REQUEST = gql`
  mutation createOrUpdateUserScheduleRequest(
    $schedule_request: [CreateOrUpdateUserScheduleRequestDto!]!
  ) {
    createOrUpdateUserScheduleRequest(schedule_request: $schedule_request) {
      id
      user_id
      work_from
      work_to
    }
  }
`

export interface ICreateOrUpdateUserScheduleRequestDto {
  schedule_request: {
    work_from: number;
    work_to: number;
  }[]
}

export interface ICreateOrUpdateUserScheduleRequestResponse {
  schedule_request: {
    id: string;
    work_from: number;
    work_to: number;
    user_id: string;
  }[]
}
