<form class="form" id="formId" [formGroup]="form" (ngSubmit)="submit()">
  <div class="wrap">
    <mat-form-field>
      <mat-label>Business name</mat-label>

      <input
        matInput
        type="text"
        placeholder="Enter business name"
        formControlName="name"
      />
      <mat-error *ngIf="form.get('name')?.hasError('required')">
        Input can not be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Business phone</mat-label>

      <input
        (input)="handlePhone($event)"
        matInput
        type="tel"
        placeholder="Enter business phone"
        formControlName="phone"
      />
      <mat-error *ngIf="form.get('phone')?.hasError('required')">
        Input can not be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Business email</mat-label>

      <input
        matInput
        type="email"
        placeholder="example@email.com"
        formControlName="mail"
      />
      <mat-error *ngIf="form.get('mail')?.hasError('email')">
        invalid email address
      </mat-error>
      <mat-error *ngIf="form.get('mail')?.hasError('required')">
        Input can not be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Business website <small>(optional)</small> </mat-label>

      <input
        matInput
        type="text"
        placeholder="http://example.com"
        formControlName="link_to_site"
      />

      <mat-error *ngIf="form.get('link_to_site')?.hasError('pattern')">
        invalid url address
      </mat-error>
    </mat-form-field>

    <div>
      <address-inp [form]="form" />
      <div class="add_address" (click)="addAddress()">+ add address</div>
    </div>

    <div>
      <services-chips [form]="form" />
      <mat-error *ngIf="form.get('services')?.hasError('required')">
        The list of services cannot be empty
      </mat-error>
    </div>

    <id-link [form]="form" />

    <mat-form-field>
      <mat-label>Owner’s full name <small>(optional)</small> </mat-label>

      <input
        matInput
        type="text"
        placeholder="Enter owner’s full name"
        formControlName="owner_name"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Owner’s phone <small>(optional)</small> </mat-label>

      <input
        (input)="handlePhone($event)"
        matInput
        type="text"
        placeholder="Enter owner’s phone"
        formControlName="owner_phone"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Google map link</mat-label>

      <input
        matInput
        type="text"
        placeholder="http://example.com"
        formControlName="link_to_map"
      />

      <mat-error *ngIf="form.get('link_to_map')?.hasError('required')">
        Input can not be empty
      </mat-error>
      <mat-error *ngIf="form.get('link_to_map')?.hasError('url_pattern')">
        Invalid url address
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Company description (optional)</mat-label>

      <textarea
        cdkTextareaAutosize
        matInput
        class="textarea"
        formControlName="description"
      ></textarea>
    </mat-form-field>

    <div>
      <working-schedule
        [form]="form"
        [company]="company"
        [schedule]="workingScheduleControls"
      />
      <mat-error *ngIf="form.get('schedule')?.hasError('error')"
        >The work schedule cannot be empty</mat-error
      >
    </div>

    <company-logo [form]="form" />
    <div class="btn_wrap">
      <button mat-raised-button type="submit">Save changes</button>

      <button mat-button type="button" (click)="onDelete()">
        {{ company ? "delete" : "cancel" }}
      </button>
    </div>
  </div>
</form>
