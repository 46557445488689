import { gql } from "apollo-angular";

export const LOGIN = gql`
  mutation login($login_dto: LoginCredential! ){
    login (login_dto: $login_dto) {
      access_token
      refresh_token
    }
  }
`

export interface ILoginDto {
  login_dto: {
    email: string;
    password: string;
  }
}

export interface ILoginResponse {
  login: {
    access_token: string;
    refresh_token: string;
  }
}
