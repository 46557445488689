<div class="wrap" scrollEndRoot>
  <table
    mat-table
    [dataSource]="technician.items || []"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="Job ID">
      <th mat-header-cell *matHeaderCellDef>Job ID</th>
      <td mat-cell *matCellDef="let element" data-name="Job ID">
        {{ element.UUID }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Dispatcher">
      <th mat-header-cell *matHeaderCellDef>Dispatcher</th>
      <td mat-cell *matCellDef="let element" data-name="Dispatcher">
        {{ element.CreatedBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" data-name="Name">
        {{ element.FirstName }} {{ element.LastName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element" data-name="Address">
        {{ element.Address }}, {{ element.City }}, {{ element.State }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element" data-name="Phone">
        {{ element.Phone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Job Type">
      <th mat-header-cell *matHeaderCellDef>Job Type</th>
      <td mat-cell *matCellDef="let element" data-name="Job Type">
        {{ element.JobType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Tech">
      <th mat-header-cell *matHeaderCellDef>Tech</th>
      <td mat-cell *matCellDef="let element" data-name="Tech">-</td>
    </ng-container>
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element" data-name="Date">
        {{ element.CreatedDate }} - {{ element.JobEndDateTime }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <spinner-loading *ngIf="technician.has_more" (scrollEnd)="loadMore()" />
</div>
