import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

interface Params {
  duration?: number;
  type: 'success' | 'warning' | 'error' | 'info',
  message: string;
}

@Injectable()
export class AlertService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public alert(params: Params) {
    this.snackBar.open(params.message, '', {
      verticalPosition: 'top',
      panelClass: params.type,
      duration: params.duration || 3000
    });
  }
}
