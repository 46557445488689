<div class="calendar">
  <div class="navigation Lexend-20 regular dark-blue">
    <arrow-icon class="rotate-180 pointer" (click)="setIndex(-1)" />
    {{ current_month_and_year }}
    <arrow-icon class="pointer" (click)="setIndex(1)" />
  </div>
  <div class="day-names">
    <div class="day-name Lexend-16 medium" *ngFor="let name of day_names">
      {{ name }}
    </div>
  </div>
  <div class="month">
    <div class="week" *ngFor="let week of calendar; let i = index">
      <div
        class="day Lexend-16 mediun dark-blue"
        *ngFor="let day of week"
        [ngClass]="{
          'current-day': currentDay.isSame(day),
          'current-month': selectedMonthIndex === day.month(),
          'not-selected-month': !(selectedMonthIndex === day.month()),
          start: selected_date.start.isSame(day),
          end: selected_date.end.isSame(day.clone().endOf('day')),
          between: day.isBetween(selected_date.start, selected_date.end, 'day')
        }"
        [attr.data-date]="day.date()"
        (click)="setSelectedDate(week[0], week[week.length - 1])"
      >
        {{ day.date() }}
      </div>
    </div>

    <div class="buttons">
      <button mat-raised-button (click)="onSave()">Apply</button>
      <button mat-flat-button (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>
