import { Component, Input } from '@angular/core';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { Company } from '../../company-item.component';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent {
  @Input() company: Locksmith;
}
