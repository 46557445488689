import { Injectable } from "@angular/core";
import { Apollo, QueryRef } from "apollo-angular";
import moment from "moment";
import { map } from "rxjs";
import { UserWithSchedule } from "src/app/entity/schedule/user-with-schedules.entity";
import { UserRole } from "src/app/enum/user-role.enum";
import { AlertService } from "src/app/modules/ui-kit/alert/alert.service";
import { GET_ALL_TIME_TEMPLATES, IGetAllTimeTemplatesResponse } from "../../../apollo/query/get-all-time-templates.query";
import { GET_MY_SCHEDULE, IGetMyScheduleDto, IGetMyScheduleResponse } from "../../../apollo/query/get-my-schedule";
import { DayAbstractService } from "../../../components/day/day-abstract.service";
import { CREATE_OR_UPDATE_USER_SCHEDULE_REQUEST, ICreateOrUpdateUserScheduleRequestDto, ICreateOrUpdateUserScheduleRequestResponse } from "../apollo/mutations/create-or-update-user-schedule-request.mutation";
import { DELETE_MANY_USER_SCHEDULE_REQUEST, IDeleteUserScheduleRequestDto } from "../apollo/mutations/delete-user-schedule-request.mutation";

@Injectable()
export class MyScheduleService implements DayAbstractService {
  public days_name = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  }
  public disabled: boolean = true;
  public my_schedule$: QueryRef<IGetMyScheduleResponse, IGetMyScheduleDto>

  public week_index = 0;

  private current_week: moment.Moment = moment().clone().startOf('isoWeek').add(this.week_index, 'week');

  private dayFromWeek: moment.Moment = this.current_week.clone().startOf('isoWeek');
  private dayToWeek: moment.Moment = this.current_week.clone().endOf('isoWeek');

  constructor(
    private readonly apollo: Apollo,
    private readonly alertService: AlertService,
  ) { }

  public get currentWeek(): moment.Moment {
    return this.current_week
  }

  public get weekEndDate(): string {
    return this.dayToWeek.format("MMM DD");
  }

  public get endWeek(): moment.Moment {
    return this.dayToWeek
  }

  public get weekStartDate(): string {
    return this.dayFromWeek.format("MMM DD");
  }

  public get startWeek(): moment.Moment {
    return this.dayFromWeek
  }
  public get variables() {
    return {
      from: this.startWeek.clone().unix(),
      to: this.endWeek.clone().unix(),
    }
  }

  public setWeekIndex(week_index: number) {
    this.current_week = this.current_week.clone().add(week_index, 'week');
    this.dayFromWeek = this.current_week.clone().startOf('isoWeek');
    this.dayToWeek = this.current_week.clone().endOf('isoWeek');

    this.my_schedule$.refetch(this.variables)
  }

  public getMySchedule() {
    this.my_schedule$ = this.apollo.watchQuery<IGetMyScheduleResponse, IGetMyScheduleDto>({
      query: GET_MY_SCHEDULE,
      variables: this.variables,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    })
    return this.my_schedule$.valueChanges.pipe(map(({ data, networkStatus }) => ({ data: data.getMySchedule, networkStatus })))
  }

  public get acceptable_edit_roles(): UserRole[] {
    return [UserRole.TECHNICIAN, UserRole.MAIN_DISPATCHER, UserRole.DISPATCHER]
  }

  public formatDate(time: string, dayIndex: number): number {
    const currentDate = this.dayFromWeek.clone();
    currentDate.add(dayIndex, 'day');
    const formatted_date = currentDate.format('DD/MM/YYYY');
    const date_time = formatted_date + ' ' + time;
    const formatted = moment(date_time, 'DD/MM/YYYY hh:mm a').unix();
    return formatted;
  }

  public createOrUpdateManyUserSchedules(schedule_request: { id?: string | undefined; work_from: number; work_to: number; user_id: string; }[], user: UserWithSchedule): void {
    const format_schedule_request = JSON.parse(JSON.stringify(schedule_request), (key, value) => key === 'user_id' ? undefined : value);

    this.apollo.mutate<ICreateOrUpdateUserScheduleRequestResponse, ICreateOrUpdateUserScheduleRequestDto>({
      mutation: CREATE_OR_UPDATE_USER_SCHEDULE_REQUEST,
      variables: { schedule_request: format_schedule_request },
      update: () => {
        this.getMySchedule().subscribe()
      }
    }).subscribe({
      complete: () => {
        this.alertService.alert({
          message: 'Request successfully have sent',
          type: 'success'
        })
      }
    })

  }

  public deleteManyUserSchedules(ids: string[], user: UserWithSchedule): void {
    this.apollo.mutate<{ deleteManyUserScheduleRequest: string[] }, IDeleteUserScheduleRequestDto>({
      mutation: DELETE_MANY_USER_SCHEDULE_REQUEST,
      variables: { ids },
      update: (cache, { data }) => {
        cache.modify({
          id: `UserWithSchedule:${user.id}`,
          fields: {
            schedule_requests: (exist:readonly { __ref: string }[]) => {
              return exist.filter(({ __ref }) => !data?.deleteManyUserScheduleRequest.some(id => __ref.includes(id)))
            }
          }
        })
      }
    }).subscribe();
  }

  public getAllTimeTemplates() {
    return this.apollo.watchQuery<IGetAllTimeTemplatesResponse>({
      query: GET_ALL_TIME_TEMPLATES,
    }).valueChanges.pipe(map(r => r.data.getAllTimeTemplates))
  }
}
