<div class='serviceHeader'>
  <div
    class='serviceHeaderImage'
    [style]="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(' + data.photo.link + ') center/cover no-repeat;'"

  >
    <div class='serviceHeaderText'>
      <div class='secondMainSpan'>
        <h1 style='font-size: 30px; color: #FFFFFF; text-align: center'>
          {{ data.headline }}
        </h1>
        <hr style="margin: 20px auto 20px auto" />
      </div>
    </div>
  </div>
</div>
