import { gql } from "apollo-angular";
import { Template } from "src/app/entity/template.entity";

export const CREATE_MANY_TEMPLATES = gql`
mutation createManyTemplates ($createTemplateDto: [CreateTemplateDto!]!) {
  createManyTemplates(createTemplateDto: $createTemplateDto) {
    car_templates {
      id
      template_id
      workiz_id
    }
    id
    items {
      id
      quantity
      sku
      template_id
      uhs_sku
    }
    name
  }
}
`

export interface ICreateManyTemplatesResponse {
  createManyTemplates: Template[];
}

export interface ICreateManyTemplatesDto {
  createTemplateDto: Pick<Template, 'name'>[];
}
