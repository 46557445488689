<div class="review">
  <div class="wrap">
    <div class="firstBlock">
      <p class="Lexend-16 medium">{{ review.name }}</p>
      <p class="Lexend-16 regular grey">
        {{ review.updated_at * 1000 | date : "shortDate" }}
      </p>
    </div>
    <div class="stars">
      <ng-container *ngIf="review.rating > 0">
        <mat-icon
          fontIcon="star"
          *ngFor="let star of getStarsRating(review.rating)"
        ></mat-icon>
      </ng-container>
    </div>
    <h2 class="Lexend-18 medium">{{ review.title }}</h2>
    <p class="grey Lexend-14 regular">
      {{ review.text }}
    </p>
    <div class="btn">
      <button
        mat-button
        class="red-color"
        color="warn"
        (click)="deleteReview(review.id)"
      >
        Delete
      </button>
      <button mat-flat-button color="primary" (click)="primaryClick(review)">
        {{ review.status === "APPROVED" ? "Send to consideration" : "Approve" }}
      </button>
    </div>
  </div>
</div>
