import { gql } from "apollo-angular";

export const APPROVE_REQUEST_FOR_CAR_ITEMS = gql`
  mutation approveRequestForCarItems($approveUpdateCarDto: [ApproveUpdateCarDto!]!) {
    approveRequestForCarItems(approveUpdateCarDto: $approveUpdateCarDto) {
      id
      approved_quantity
      submitted_quantity
    }
  }
`


export interface IApproveRequestForCarItemsDto {
  approveUpdateCarDto: {
    id: string;
    approved_quantity: number;
  }[]
}

export interface IApproveRequestForCarItemsResponse {
  approveRequestForCarItems: {
    id: string;
    requested_at: number;
    requested_quantity: number;
    submitted_quantity: number;
    approved_quantity: number;
  }
}
