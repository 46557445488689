import { gql } from 'apollo-angular';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';

export const GET_CONSTRUCTED_PAGE_COMPANIES = gql`
  query getConstructedPageCompanies {
    getConstructedPageCompanies {
      id
      name
    }
  }
`;

export interface IGetConstructedPageCompaniesResponse {
  getConstructedPageCompanies: ConstructedCompany[];
}
