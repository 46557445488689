import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ReviewsMainComponent } from './component/reviews-main.component';
import { ReviewsMainService } from './reviews-main.service';
import { MatInputModule } from '@angular/material/input';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { ReviewsMainItemComponent } from './component/reviews-main-item/reviews-item.component';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    IconsModule,
    LoadingModule,
    MatTableModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: 'slk-admin/reviews-main',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ReviewsMainComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [ReviewsMainComponent, ReviewsMainItemComponent],
  providers: [ReviewsMainService],
})
export class ReviewsMainModule {}
