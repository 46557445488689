<div class="wrap-ctm" scrollEndRoot>
  <table
    mat-table
    [dataSource]="calls_info?.calls || []"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="Phone">
      <th mat-header-cell *matHeaderCellDef style="max-width: 200px">Phone</th>
      <td mat-cell *matCellDef="let call" data-name="Phone">
        {{ call.caller_number_format }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Call Status">
      <th mat-header-cell *matHeaderCellDef style="width: 200px">
        Call Status
      </th>
      <td mat-cell *matCellDef="let call" data-name="Call Status">
        <p [attr.data-status]="call.call_status" class="status">
          {{ call.call_status }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="Company">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let call" data-name="Company">
        {{ call.source }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let call" data-name="Date">
        {{ call.called_at }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let call" data-name="Duration">
        {{ call.audio && (call.talk_time | format_duration_audio) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Audio">
      <th mat-header-cell *matHeaderCellDef>Audio</th>
      <td mat-cell *matCellDef="let call" data-name="Audio">
        <play-icon (click)="openDialog(call)" *ngIf="call.audio" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <spinner-loading
    *ngIf="calls_info?.calls?.length !== calls_info?.total_entries"
    (scrollEnd)="loadMore()"
  />
</div>
