<!-- (clickOutside)="setOpen()" -->
<div class="wrap-list" (clickOutside)="setOpen(false)">
  <div class="hour Lexend-16 regular pointer" (click)="setOpen(!open)">
    {{ time }}
  </div>
  <div class="list" [ngClass]="{ active: open }">
    <p
      class="Lexend-16 regular pointer"
      *ngFor="let hour of hours"
      (click)="selectHour(hour)"
    >
      {{ hour }}
    </p>
  </div>
</div>
