import { gql } from "apollo-angular";
import { CtmCall } from "src/app/entity/ctm-call.entity";

export const GET_CALLS = gql`
  query getCalls($ctmGetCallsOptionDto: CtmGetCallsOptionDto!) {
    getCalls(ctmGetCallsOptionDto: $ctmGetCallsOptionDto) {
      calls {
        id

        called_at
        account_id
        call_status

        caller_number_format

        day
        hour
        month
        unix_time

        ring_time

        dial_status

        duration
        talk_time

        audio

        source
        source_id
        source_sid
      }
      page
      per_page
      total_entries
      total_pages
    }
  }
`;

export interface ICtmGetCallsOptionDto {
  ctmGetCallsOptionDto: {
    page: number;
    per_page: number;
  }
}

export type ICtmGetCallsOptionResponse = {
  getCalls: {
    calls: CtmCall[];
    page: number;
    per_page: number;
    total_entries: number;
    total_pages: number;
  }
}
