<div class="wrap" #time_list_ref (click)="toggleOpen()">
  <p class="Lexend-16 title">{{ time }}</p>

  <blue-arrow />

  <div class="list" *ngIf="open">
    <p
      *ngFor="let time_item of times"
      class="Lexend-16 list_item"
      (click)="change(time_item)"
    >
      {{ time_item }}
    </p>
  </div>
</div>
