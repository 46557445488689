import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  template_item_id: string;
  template_id: string;
  title?: string;
  onDelete: () => void;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogData>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  public onDelete() {
    this.data.onDelete();
    this.onClose();
  }

  public onClose() {
    this.dialogRef.close();
  }
}
