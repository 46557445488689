import { gql } from "apollo-angular";
import { Transcription } from "src/app/entity/transcription.entity";

export const FIND_TRANSCRIPTION = gql`
  query findTranscription($call_id: String!){
    findTranscription(call_id:$call_id){
      ctm_call_id
      id
      text
    }
  }
`

export type IFindTranscriptionDto = {
  call_id: string;
}

export type IFindTranscriptionResponse = {
  findTranscription: Transcription
}
