import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { FormAbstractService } from 'src/app/pages/slk-admin/common/services/form-abstract.service';

@Component({
  templateUrl: './request-locksmith.component.html',
  styleUrls: ['./request-locksmith.component.scss'],
})
export class RequestLocksmithComponent {
  public get locksmith() {
    return this.formAbstractService.locksmith;
  }

  constructor(
    public formAbstractService: FormAbstractService,
    private readonly route: ActivatedRoute
  ) { }

  get loading() {
    if (this.formAbstractService.loading) {
      return this.formAbstractService.loading;
    } else return false;
  }
  ngOnInit(): void {
    this.formAbstractService.getLocksmithById?.({
      id: this.route.snapshot.paramMap.get('locksmith-id') as string,
    });
  }
  public submitForm(new_locksmith: Locksmith) { }
}
