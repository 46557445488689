import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CarsService } from '../../../cars.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { Template } from 'src/app/entity/template.entity';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent implements OnChanges {
  @Input() update_template?: Template;
  @Output() onClose = new EventEmitter<void>();

  public loading = false;
  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly carsService: CarsService,
    private readonly alertService: AlertService,
  ) { }

  ngOnChanges(): void {
    if (this.update_template) {
      this.form.patchValue({ name: this.update_template.name })
    }
  }

  get name() {
    return this.form.get('name')!;
  }

  public onSubmit() {
    if (this.update_template) {
      this.carsService.updateTemplate({
        id: this.update_template.id,
        name: this.form.value.name,
      }).subscribe({
        next: ({ loading }) => { this.loading = loading },
        complete: () => {
          this.form.reset();
          this.onClose.emit();
          this.alertService.alert({ message: 'Template successfully updated', type: 'success' })
        },
        error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
      })
    } else {
      this.carsService.createManyTemplates([{
        name: this.form.value.name
      }]).subscribe({
        next: ({ loading }) => { this.loading = loading },
        complete: () => {
          this.form.reset();
          this.onClose.emit();
          this.alertService.alert({ message: 'Template successfully created', type: 'success' })
        },
        error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
      })
    }

  }
}
