<form class="wrap" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
  <div class="password_wrap">
    <password-input
      ngDefaultControl
      controlName="current_password"
    />

    <p class="Lexend-14 ligth grey underline">Forgot your password?</p>
  </div>

  <password-input
    label="New password"
    ngDefaultControl
    controlName="new_password"
  />

  <password-pointers
    [new_password]="passwordForm.get('new_password')?.value || ''"
  />

  <div class="btn_wrap">
    <button mat-raised-button type="submit">Save changes</button>
    <button mat-button type="reset">cancel</button>
  </div>
</form>
