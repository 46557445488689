import { Component, Input } from '@angular/core';

@Component({
  selector: 'company-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css'],
})
export class CompanyLogoComponent {
  @Input() logo: string;
}
