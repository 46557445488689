import { gql } from 'apollo-angular';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';

export const UPDATE_CONSTRUCTED_PAGE_COMPANY = gql`
  mutation updateConstructedPageCompany(
    $company_dto: UpdateConstructedPageCompanyDto!
  ) {
    updateConstructedPageCompany(company_dto: $company_dto) {
      id
      name
    }
  }
`;

export interface IUpdateConstructedPageCompanyDto {
  company_dto: ConstructedCompany;
}

export interface IUpdateConstructedPageCompanyResponse {
  updateConstructedPageCompany: ConstructedCompany;
}
