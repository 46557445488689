import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TimeTemplate } from '../../../entity/time-template.entity';
import { ISchedulePicker } from '../components/day/time-picker/time-picker.component';

@Pipe({ name: 'compare_time_period_and_range' })
export class CompareTimePeriodAndRangePipe implements PipeTransform {
  private working_hours: ISchedulePicker[] = [];
  private generateElement = '';
  private colors = {
    Orange: "color: #83602E;  background: #FCF6E6;",
    Light: "color: #006FDC;  background: #DAF1FB;",
    'Dark blue': "color: #FFFFFF;  background: #2D5B70;",
    Blue: "color: #325BB1;  background: #DBE6FD;",
    Pink: "color: #CE6E81;  background: #F9E0E5;",
    Violet: "color: #8872D2;  background: #ECE5FC;",
    Green: "color: #448884;  background: #D5F0F0;",
    Brown: "color: #A96549;  background: #ECE0DB;",
  };

  constructor(private sanitized: DomSanitizer) { }


  transform(time_template: TimeTemplate[], default_working_hours: ISchedulePicker[]): SafeHtml {
    this.generateElement = '';
    this.working_hours = default_working_hours;

    if (this.working_hours.length === 0) return this.sanitized.bypassSecurityTrustHtml(this.tag('Off', 'color: var(--primary-red); background: #fbeeee;'))

    time_template.forEach((time_period) => {
      this.generateElement += this.generateTimeTemplatePeriods(time_period);
    })

    if (this.working_hours.length > 0) {
      const generate_string = this.working_hours.map((time) => {
        let status = '';
        if (time.status) {
          const lover_status = time.status.toLowerCase()
          const handle_status = lover_status === 'requested' ? 'pending' : lover_status
          status = time.status ? `<span class="${handle_status}">${handle_status}</span>` : '';
        }

        if (time) {
          return `${time.work_from} - ${time.work_to} ${status}`
        }
        return ''
      }).filter(time => time).join('\n')
      this.generateElement += this.tag(generate_string)
    }

    return this.sanitized.bypassSecurityTrustHtml(this.generateElement)
  }

  private generateTimeTemplatePeriods(time_template: TimeTemplate): string {
    const time_template_value: ISchedulePicker[] = JSON.parse(time_template.value);

    const check_range = time_template_value.reduce((check: ISchedulePicker[], time) => {
      const find_working_hour = this.working_hours.find((working_hour) => working_hour.work_from === time.work_from && working_hour.work_to === time.work_to);
      if (find_working_hour) {
        check.push(find_working_hour);
      };
      return check;
    }, []);

    if (check_range.length === time_template_value.length) {
      const generate_string = time_template_value.map((time) => {
        const status = time.status ? `<span>${time.status}</span>` : '';
        return `${time.work_from} - ${time.work_to} ${status}`
      }).join('\n');
      const style = this.colors[time_template.color as keyof typeof this.colors];

      this.working_hours = this.working_hours.filter((item) => !check_range.find((range) => item.id === range.id))

      return this.tag(generate_string, style, time_template.name);
    }

    return '';
  }


  private tag(value: string, style?: string, period_name?: string, approve_status?: string): string {
    const handleStyle = style ? `style="${style}"` : '';
    const title = period_name ? `title="${period_name}"` : '';
    const status = approve_status ? ` ${approve_status}` : '';
    return `<i ${handleStyle} ${title} class="Lexend-16 cover${status}">${value}</i>`
  }
}

