<label class='label' htmlFor='fileUpload'>
  <p *ngIf="label">{{ label }}</p>
  <input
    type="file"
    id='fileUpload'
    class="customFile"
    (change)="onChange($event)"
    [accept]="(accept || []).join(', ') || '*'"
  />
  <ng-content></ng-content>
</label>
