<button
  [disabled]="disabled"
  class="Lexend-16 medium blue pointer"
  (click)="setNextWeekIndex(-1)"
>
  <arrow-navigation-icon />Previous week
</button>
<button
  [disabled]="disabled"
  class="Lexend-16 medium blue pointer"
  (click)="setNextWeekIndex(1)"
>
  Next week
  <arrow-navigation-icon class="rotate-180" />
</button>
