import { Component, OnInit } from '@angular/core';
import { ReviewsMainService } from '../reviews-main.service';
import { UnreviewedReviews } from 'src/app/entity/unreviewed-reviews.entity';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'reviews-main',
  templateUrl: './reviews-main.component.html',
  styleUrls: ['./reviews-main.component.scss'],
})
export class ReviewsMainComponent implements OnInit {
  public unreviewed_review: UnreviewedReviews[];
  public searchControl: FormControl = new FormControl();

  constructor(private readonly reviewsMainService: ReviewsMainService) {}

  ngOnInit(): void {
    this.reviewsMainService
      .getLocksmithWithUnreviewedReview()
      .subscribe((data) => {
        this.unreviewed_review = data;
      });
    this.searchControl.valueChanges.subscribe((searchValue) => {
      this.searchValue(searchValue);
    });
  }

  searchValue(searchValue: string) {
    if (searchValue === '') {
      this.reviewsMainService
        .getLocksmithWithUnreviewedReview()
        .subscribe((data: UnreviewedReviews[]) => {
          this.unreviewed_review = data;
        });
    } else {
      if (this.unreviewed_review.length === 0) {
        this.reviewsMainService
          .getLocksmithWithUnreviewedReview()
          .subscribe((data: UnreviewedReviews[]) => {
            this.unreviewed_review = data;
            this.filterReviews(searchValue);
          });
      } else {
        this.filterReviews(searchValue);
      }
    }
  }

  filterReviews(searchValue: string) {
    this.unreviewed_review = this.unreviewed_review.filter((review) => {
      return review.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }
}
