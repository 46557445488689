import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ScheduleService } from '../../../pages/schedule/services/schedule.service';

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Input() tab: string;
  public open_filters = false;
  public open_working_time = false;
  public open_states = false;

  public working_time: string[];
  public states: string[];

  public selected: string[] = [];

  public form: FormGroup;

  constructor(
    private readonly scheduleService: ScheduleService
  ) { }
  ngOnInit(): void {
    if (this.tab === 'Dispatchers') {
      this.form = new FormGroup({
        ["Working time"]: new FormGroup({
          ["Available now"]: new FormControl(false),
          ["Doesn’t work now"]: new FormControl(false),
        }),
        ["State"]: new FormGroup({
          ['Georgia']: new FormControl(false),
          ['Ukraine']: new FormControl(false),
        }),
      })
    } else {
      this.form = new FormGroup({
        ["Working time"]: new FormGroup({
          ["Available now"]: new FormControl(false),
          ["Doesn’t work now"]: new FormControl(false),
        }),
        ["State"]: new FormGroup({
          ["Connecticut"]: new FormControl(false),
          ["Texas (Dallas)"]: new FormControl(false),
          ["Subs Dallas"]: new FormControl(false),
          ["Texas (Houston)"]: new FormControl(false),
          ["Subs Houston"]: new FormControl(false),
          ["Arizona"]: new FormControl(false),
          ["Arizona Subs"]: new FormControl(false),
        }),
      })
    }
    this.working_time = Object.keys(this.form.value['Working time']!);
    this.states = Object.keys(this.form.value["State"]!)

  }

  public setOpen(state: boolean) {
    this.open_filters = state
  }

  public onClear() {
    this.scheduleService.setFilters(undefined, [])
  }

  public onValueChanges = () => {
    const states = Object.entries(this.form.value.State!).reduce((states: string[], [key, checked]) => {
      if (checked) states.push(key)
      return states
    }, [])

    const working_time = Object.entries(this.form.value["Working time"]!).reduce((states: string[], [key, checked]) => {
      if (checked) states.push(key)
      return states
    }, [])

    this.selected = [...states, ...working_time]

    const handleTime = working_time.length > 0 ? working_time.includes('Available now') : undefined
    this.scheduleService.setFilters(handleTime, states)
  }

}
