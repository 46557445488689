import { gql } from "apollo-angular";

export const LOGOUT = gql`
  mutation logout($refresh_token: String! ) {
    logout(refresh_token: $refresh_token) {
      message
    }
  }
`


export interface ILogoutDto {
  refresh_token: string;
}


export interface ILogoutResponse {
  logout: {
    message: string
  }
}
