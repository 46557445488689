import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { Schedule } from 'src/app/entity/schedule.entity';
import { Week } from 'src/app/enum/week.enum';
import { FormCompany } from '../company-form.component';

@Component({
  selector: 'working-schedule',
  templateUrl: './working-schedule.component.html',
  styleUrls: ['./working-schedule.component.scss'],
})
export class WorkingScheduleComponent implements OnInit {
  @Input() company?: Locksmith;
  @Input() form: FormGroup;
  @Input() schedule: AbstractControl<Schedule>[];
  Week = Week;

  constructor(public readonly controlContainer: ControlContainer) {}

  ngOnInit(): void {
    if (!this.company) this.addDaysOfWeek();
  }

  get isFullWeek() {
    return this.getDays().value.every((day: Schedule) => {
      return day.open_at === '12:00 AM' || day.close_at === '11:59 PM';
    });
  }

  private getDays(): FormArray {
    return this.form.get('schedules') as FormArray;
  }

  private setWeekValue(open_at: string, close_at: string) {
    for (let i = 0; i < 7; i++) {
      const day = (this.form.get('schedules') as FormArray).at(i);
      day.setValue({
        ...day.value,
        open_at,
        close_at,
      });
    }
  }

  public handleChange() {
    if (!this.isFullWeek) {
      this.setWeekValue('12:00 AM', '11:59 PM');
    } else {
      this.setWeekValue('', '');
    }
  }

  private addDaysOfWeek(): void {
    Object.keys(Week).forEach((day) => {
      const dayGroup = new FormGroup({
        name: new FormControl(day),
        open_at: new FormControl(''),
        close_at: new FormControl(''),
      });

      this.getDays().push(dayGroup);
    });
  }
}
