import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { FineTuneItem } from 'src/app/entity/fine-tune-item.entity';
import { LastTeachingDialog } from './last-teaching-dialog/last-teaching-dialog';
import { TeachingService } from '../../../teaching.service';

@Component({
  selector: 'teaching',
  templateUrl: './teaching.component.html',
  styleUrls: ['./teaching.component.scss'],
})

export class TeachingComponent implements OnInit {
  private $fineTuneList: Observable<FineTuneItem[]>;

  constructor(
    private readonly teachingService: TeachingService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.$fineTuneList = this.teachingService.getFineTuneList();
  }

  public get getFineTuneList() {
    return this.$fineTuneList;
  }

  public openDialog() {
    this.dialog.open(LastTeachingDialog);
  }

  public startFineTune() {
    this.teachingService.startFineTune().subscribe({
      complete: () => this.alertService.alert({ type: 'success', message: 'Item successfully deleted' }),
      error: (e) => this.alertService.alert({ type: 'error', message: e.message })
    })
  }
}
