import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';
import { password_pattern } from 'src/app/modules/ui-kit/password-input/component/password-input.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public hide: boolean = true;

  constructor(private readonly authorizationService: AuthorizationService) {}

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(password_pattern),
    ]),
  });

  public get email() {
    return this.form.get('email')!;
  }
  public get password() {
    return this.form.get('password')!;
  }

  public onSubmit() {
    const { email, password } = this.form.value;
    this.form.markAllAsTouched();

    if (email && password && this.form.valid) {
      this.authorizationService.login(email, password);
    }
  }
}
