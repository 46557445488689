import { gql } from "apollo-angular";

export const DELETE_MANY_ITEMS_TEMPLATE = gql`
  mutation deleteManyItemTemplates ($ids: [ID!]!) {
    deleteManyItemTemplates(ids: $ids)
  }
`

export interface IDeleteManyItemsTemplateDto {
  ids: string[];
}

export interface IDeleteManyItemsTemplateResponse {
  deleteManyItemTemplates: string[];
}
