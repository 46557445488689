<div class="wrap">
  <div class="head">
    <h1>Manage users</h1>
    <button mat-raised-button (click)="toggleSideMenu()">Invite member</button>
  </div>

  <app-side-menu
    *ngIf="getIsOpenMenu || getEditUser"
    [is_open]="getIsOpenMenu || !!getEditUser"
    [headline]="sidebarTitle"
    (onClose)="toggleSideMenu()"
  >
    <app-invite-user (onClose)="toggleSideMenu()" />
  </app-side-menu>

  <mat-tab-group
    animationDuration="0"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab label="Users">
      <app-users-table
        [users]="($users | async)?.getAllUsers || []"
        (handleChangeCheckbox)="handleSelectUser('getAllUsers', $event)"
      />
    </mat-tab>
    <mat-tab label="Invited users">
      <app-users-table
        [users]="($users | async)?.getAllInvitedUsers || []"
        (handleChangeCheckbox)="handleSelectUser('getAllInvitedUsers', $event)"
      />
    </mat-tab>
  </mat-tab-group>
</div>
