import { Component, Input } from '@angular/core';

@Component({
  selector: 'password-pointers',
  templateUrl: './password-pointers.component.html',
  styleUrls: ['./password-pointers.component.scss'],
})
export class PasswordPointersComponent {
  @Input('new_password') new_password: string;

  testLowercase(): boolean | null {
    if (this.new_password.length === 0) return null;
    return /[a-z]/g.test(this.new_password);
  }

  testUppercase(): boolean | null {
    if (this.new_password.length === 0) return null;
    return /[A-Z]/g.test(this.new_password);
  }

  testNumber(): boolean | null {
    if (this.new_password.length === 0) return null;
    return /[0-9]/g.test(this.new_password);
  }

  testChar(): boolean | null {
    if (this.new_password.length === 0) return null;
    return /[^A-Za-z0-9]/g.test(this.new_password);
  }

  testLength(): boolean | null {
    if (this.new_password.length === 0) return null;
    return this.new_password.length >= 8;
  }
}
