import { gql } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { LOCKSMITH } from '../fragments/locksmith';

export const UPDATE_LOCKSMITH = gql`
  ${LOCKSMITH}
  mutation updateLocksmith($locksmith: UpdateLocksmithDto!) {
    updateLocksmith(locksmith: $locksmith) {
      ...Locksmith
    }
  }
`;

export type UpdateLocksmithDto = {
  locksmith: Locksmith;
};

export type UpdateLocksmithResponse = {
  locksmith: Locksmith;
};
