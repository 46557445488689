import { gql } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { LOCKSMITH } from '../fragments/locksmith';

export const GET_LOCKSMITH_BY_ID = gql`
  ${LOCKSMITH}
  query getLocksmithById($id: ID!) {
    getLocksmithById(id: $id) {
      ...Locksmith
    }
  }
`;

export interface IGetLocksmithByIdDto {
  id: string;
}
export interface IGetLocksmithByIdResponse {
  getLocksmithById: Locksmith;
}
