<div class="wrap">
  <div class="head">
    <h1 class="Lexend-32 regular">{{ template_name }}</h1>
    <button mat-raised-button (click)="toggleSideMenu()">Add new item</button>

    <upload
      (onUpload)="onUploadItems($event)"
      [accept]="[
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ' application/vnd.ms-excel'
      ]"
      class="upload"
    >
      <upload-icon />
    </upload>
  </div>

  <app-side-menu
    headline="Add new item"
    [is_open]="open_side_menu || !!edit_item"
    (onClose)="toggleSideMenu()"
  >
    <app-template-item (onClose)="toggleSideMenu()" [edit_item]="edit_item" />
  </app-side-menu>

  <template-table
    [template_items]="template_items || []"
    (setEditItem)="setEditItem($event)"
  />
</div>
