import { gql } from 'apollo-angular';

export const SCHEDULE = gql`
  fragment LocksmithSchedule on LocksmithSchedule {
    close_at
    id
    name
    open_at
  }
`;
