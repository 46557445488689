<ng-container
  [formGroup]="$any(controlContainer.control)"
  *ifRoles="acceptable_roles"
>
  <mat-form-field>
    <mat-label>Role</mat-label>

    <mat-select
      placeholder="---Select---"
      (selectionChange)="onRoleChange($event)"
      formControlName="role"
    >
      <mat-option *ngFor="let role of roles_list" [value]="role">
        {{ role }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="role.hasError('required')">
      Role is <strong>required</strong>
    </mat-error>
  </mat-form-field>
</ng-container>
