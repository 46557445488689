<div class="tableWrap">
  <table mat-table [dataSource]="template_items" class="mat-elevation-z8">

    <ng-container matColumnDef="uhs_sku">
      <th mat-header-cell *matHeaderCellDef>UHS SKU</th>
      <td mat-cell *matCellDef="let element" data-name="UHS SKU">
        {{ element.uhs_sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="slk_sku">
      <th mat-header-cell *matHeaderCellDef>S.L.K SKU</th>
      <td mat-cell *matCellDef="let element" data-name="S.L.K SKU">
        {{ element.sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Quantity">
        {{ element.quantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element" data-name="Actions" class="actions">
        <edit-icon (click)="selectEditItem(element)" />
        <delete-cross-icon (click)="deleteItem(element.id, element.template_id)" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
