import { gql } from 'apollo-angular';
import { SystemSettings } from 'src/app/entity/system-settings.entity';

export const GET_SYSTEM_SETTINGS = gql`
  query getSystemSettings {
    getSystemSettings {
      id
      is_parsing
      max_tokens
      workiz_cookie
      workiz_arizona_cookie
    }
  }
`;

export type ISystemSettingsResponse = {
  getSystemSettings: SystemSettings;
};
