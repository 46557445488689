<div class="head">
  <h2 class="Lexend-18 dark-blue medium center">Send technician for approve</h2>
  <cross-icon class="cross pointer" (click)="onClose()" />
</div>

<wrapper-loading [loading]="loading">
  <div class="wrap" [formGroup]="form">
    <table mat-table [dataSource]="difference_items" class="mat-elevation-z8">
      <ng-container matColumnDef="uhs_sku">
        <th mat-header-cell *matHeaderCellDef>UHS SKU</th>
        <td mat-cell *matCellDef="let element" data-name="UHS SKU">
          {{ element.uhs_sku }}
        </td>
      </ng-container>

      <ng-container matColumnDef="slk_sku">
        <th mat-header-cell *matHeaderCellDef>S.L.K SKU</th>
        <td mat-cell *matCellDef="let element" data-name="S.L.K SKU">
          {{ element.sku }}
        </td>
      </ng-container>

      <ng-container matColumnDef="difference">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element" data-name="Quantity">
          <mat-form-field>
            <input
              matInput
              type="number"
              [value]="element.difference"
              placeholder="Type quantity here"
              [formControlName]="element.template_item_id"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-name="Actions"
          class="actions"
        >
          <delete-cross-icon
            class="pointer"
            (click)="onDelete(i, element.template_item_id)"
          />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</wrapper-loading>

<button mat-raised-button class="button" (click)="onCreate()">
  Send request
</button>
