import { gql } from 'apollo-angular';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { LOCKSMITH } from '../fragments/locksmith';

export const GET_LOCKSMITHS = gql`
  ${LOCKSMITH}
  query getLocksmiths(
    $confirmed: Boolean!
    $pagination: FindPaginationDto!
    $search_value: String
  ) {
    getLocksmiths(
      confirmed: $confirmed
      pagination: $pagination
      search_value: $search_value
    ) {
      total
      items {
        ...Locksmith
      }
    }
  }
`;

export interface IGetLocksmithDto {
  confirmed: boolean;
  pagination: {
    skip: number;
    take: number;
  };
  search_value: string;
}

export interface IGetLocksmithResponse {
  getLocksmiths: {
    total: number;
    items: Locksmith[];
  };
}
