import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { AllLocksmithModule } from '../../common/all-locksmith/all-locksmith.module';
import { RequestsComponent } from './component/requests.component';
import { RequestService } from './requests.service';

@NgModule({
  imports: [
    AllLocksmithModule.inversion(RequestService),

    RouterModule.forChild([
      {
        path: 'slk-admin/requests',
        loadChildren: async () =>
          (await import('./requests.module')).RequestsModule,
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: RequestsComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [RequestsComponent],
})
export class RequestsModule {}
