import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { UserRole } from 'src/app/enum/user-role.enum';
import { CarsService } from 'src/app/pages/cars/cars.service';

@Injectable()
export class TechnicianGuard {
  constructor(
    public auth: AuthorizationService,
    public router: Router,
    public carsService: CarsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {
      this.carsService.findUpdateCarRequests().subscribe({
        next: (format_request) => {
          if (Object.keys(format_request.consideration).length > 0 || Object.keys(format_request.submitted).length > 0) {
            if (route.url[0].path !== 'confirm-inventory') {
              this.router.navigate(['cars', 'confirm-inventory']);

              observer.next(false);
            } else {
              observer.next(true);
            }
          } else {
            observer.next(true);
          }

          observer.complete();
        },
        error: () => {
          observer.next(true);
        }
      })
    })
  }
}
