import { NgModule } from '@angular/core';
import { WorkizComponent } from './components/workiz/workiz.component';
import { CtmComponent } from './components/ctm/ctm.component';
import { WorkizTableComponent } from './components/workiz/table/table.component';
import { ApiService } from './api.service';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiComponent } from './components/api/api.component';
import { CtmTableComponent } from './components/ctm/table/table.component';
import { FormatDurationAudioPipe } from 'src/app/pipe/format-duration-audio.pipe';
import { PlayerComponent } from './components/ctm/table/player/player.component';
import { MatSliderModule } from '@angular/material/slider';
import { ChangeTimeRightComponent } from './components/ctm/table/player/icons/change-time-right/change-time-right.component';
import { ChangeTimeLeftComponent } from './components/ctm/table/player/icons/change-time-left/change-time-left.component';
import { PlayComponent } from './components/ctm/table/player/icons/play/play.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IconsModule } from '../../modules/icons/icons.module';
import { HeadComponent } from './components/ctm/table/head/head.component';
import { TranscriptComponent } from './components/ctm/table/transcript/transcript.component';
import { TabsComponent } from './components/ctm/table/tabs/tabs.component';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { DirectiveModule } from 'src/app/directive/directive.module';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    LoadingModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    IconsModule,
    DirectiveModule,
    RouterModule.forChild([
      {
        path: 'api',
        component: MainLayoutComponent,
        title: 'API | SLK CRM',
        children: [
          {
            path: '',
            component: ApiComponent,
          }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.api,
        },
      },
    ]),
  ],
  declarations: [
    WorkizComponent,
    WorkizTableComponent,
    CtmComponent,
    ApiComponent,
    CtmTableComponent,
    FormatDurationAudioPipe,
    PlayerComponent,
    ChangeTimeRightComponent,
    ChangeTimeLeftComponent,
    PlayComponent,
    HeadComponent,
    TranscriptComponent,
    TabsComponent,
  ],
  providers: [ApiService, FormatDurationAudioPipe],
})
export class ApiModule { }
