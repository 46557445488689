import { Component, OnInit } from '@angular/core';
import { ManageUsersService } from '../../manage-users.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';
@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss']
})

export class BulkActionComponent implements OnInit {
  public selected_users_ids: string[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly manageUsersService: ManageUsersService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.manageUsersService.getUsers().subscribe((r) => {
      this.selected_users_ids = [];

      [...r.getAllUsers, ...r.getAllInvitedUsers].forEach((user) => {
        if (user.selected) {
          this.selected_users_ids.push(user.id);
        }
      });
    });
  }

  public onTrash(): void {
    const current_tab = this.manageUsersService.getCurrentTab;

    if (current_tab === 'Users') {
      this.manageUsersService.deleteManyUsers(this.selected_users_ids).subscribe({
        next: ({ loading }) => !loading && this.alertService.alert({ message: 'User successfully deleted', type: 'success' }),
        error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
      });
    } else {
      this.manageUsersService.deleteManyInvitedUsers(this.selected_users_ids).subscribe({
        next: ({ loading }) => !loading && this.alertService.alert({ message: 'User successfully deleted', type: 'success' }),
        error: (e) => this.alertService.alert({ message: e.message, type: 'error' }),
      });;
    }
  }

  public deleteItem() {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'user',
        onDelete: () => {
          this.onTrash()
        }
      },
      maxWidth: '590px',
      width: '100%'
    });
  }
}
