<div class="wrap-info">
  <info-icon class="info-icon" (mouseenter)="onMouseOver($event)" />
  <div class="over" [style]="position">
    <div
      class="empty Lexend-16 regular"
      *ngIf="info.length === 0; else loggedOut"
      (click)="openSideMenu(true)"
    >
      <plus-icon *ifRoles="acceptable_edit_roles" class="plus pointer" />
      There is no additional info yet.
    </div>

    <ng-template #loggedOut>
      <edit-icon
        class="edit plus pointer"
        *ifRoles="acceptable_edit_roles"
        (click)="openSideMenu(true)"
      />
      <div class="infos">
        <div
          class="info Lexend-16 regular"
          (click)="openSideMenu(true)"
          *ngFor="let item of info"
        >
          {{ item.key }} {{ item.value }}
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-side-menu
  headline="Edit info"
  [is_open]="open"
  (onClose)="openSideMenu(false)"
  *ifRoles="acceptable_edit_roles"
>
  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div
      class="group"
      *ngFor="let group_name of format_info_keys"
      [formGroupName]="group_name"
    >
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          matInput
          placeholder="e.g Phone number"
          formControlName="key"
          type="text"
        />
      </mat-form-field>
      <cross-in-circle class="pointer" (click)="removeInfo(group_name)" />

      <mat-form-field>
        <mat-label>Info</mat-label>
        <input
          type="text"
          matInput
          placeholder="e.g (860) 333 - 2222"
          formControlName="value"
        />
      </mat-form-field>
    </div>
    <button type="button" mat-stroked-button (click)="addNewInfo()">
      + Add more
    </button>
    <button type="submit" mat-raised-button>Save</button>
  </form>
</app-side-menu>
