import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstructorAbstractService } from '../../constructor.abstract.service';

@Component({
  selector: 'app-constructed-items',
  templateUrl: './constructed-items.component.html',
  styleUrls: ['./constructed-items.component.scss'],
})
export class ConstructedItemsComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly constructorAbstractService: ConstructorAbstractService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const type = params.get('type');
      const page_id = params.get('page-id');
      const company_id = params.get('company-id');

      this.constructorAbstractService.configPageOnLoad(
        page_id,
        type,
        company_id
      );
    });
  }

  public get type() {
    return this.constructorAbstractService.type.toLowerCase();
  }

  private get company_id() {
    return this.constructorAbstractService.company_id;
  }

  public get create_path() {
    return `/slk-admin/${this.company_id}/${this.type}/create-new/`;
  }
}
