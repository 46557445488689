<div class="prompt">
  <user-chat-icon />
  <p class="Lexend-16 regular">{{ message.prompt }}</p>
</div>

<div class="text">
  <bot-chat-icon />
  <div class="wrap-text">
    <span class="Lexend-12 light blue" [ngClass]="message.type | lowercase">
      <database-icon /> From database
    </span>
    <p class="Lexend-16 regular">{{ message.text }}</p>
  </div>

  <div class="tools">
    <pre class="query" *ngIf="message.database_query">
    <code [highlight]="message.database_query" [languages]="['sql']" ></code></pre>

    <data-base-table
      [database_result]="database_result"
      *ngIf="database_result_exist"
    />
  </div>
</div>
