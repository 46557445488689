import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public getDate(data: number) {
    return new Date(data * 1000).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  public conditionalValidator(
    predicate: () => boolean,
    validator: ValidatorFn,
    errorNamespace?: string,
  ): ValidatorFn {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      let error = null;
      if (predicate()) {
        error = validator(formControl);
      }
      if (errorNamespace && error) {
        const customError: any = {};
        customError[errorNamespace] = error;
        error = customError;
      }
      return error;
    };
  }
}

export function conditionalValidator(
  predicate: () => boolean,
  validator: ValidatorFn,
  errorNamespace?: string,
): ValidatorFn {
  return formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError: any = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
}
