import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConstructorService } from '../constructor.service';
import { environment } from 'src/environments/environment';

@Pipe({
  standalone: true,
  name: 'getImageUrlByPhotoID',
})
export class GetImageUrlByPhotoIDPipe implements PipeTransform {
  constructor() { }

  transform(file_id?: string | null, file?: File, css: boolean = false): string {
    const placeholder_img = '/assets/company-default-logo/default.png';
    const time = new Date().getTime().toString();
    const image_url = environment.S3_URL + file_id + `?${time}`;

    const url = file instanceof File ? URL.createObjectURL(file) : null;

    const image_link = (() => {
      if (file_id) return image_url
      else if (url) return url
      return placeholder_img
    })()

    if (css) return `url(${image_link})`

    return image_link
  }
}
