import { gql } from "apollo-angular";
import { WorkizJob } from "src/app/entity/workiz-job.entity";

export const GET_ALL_JOBS_WORKIZ = gql`
  query getAllJobsWorkiz($offset: Int!, $records: Int!) {
    getAllJobsWorkiz(offset: $offset, records: $records) {
      items {
        UUID
        Address
        City
        State
        Phone
        JobType
        CreatedBy
        FirstName
        LastName
        CreatedDate
        JobEndDateTime
      }
    has_more
    }
  }
`;

export type IGetAllJobsWorkizDto = {
  offset: number;
  records: number;
}

export type IGetAllJobsWorkizResponse = {
  getAllJobsWorkiz: {
    items: WorkizJob[];
    has_more: boolean;
  }
}
