import { gql } from "apollo-angular";
import { Transcription } from "src/app/entity/transcription.entity";

export const SPEECH_TO_TEXT = gql`
  mutation speechToText($call_id: String!) {
    speechToText(call_id:$call_id){
      ctm_call_id
      id
      text
    }
  }
`
export type ISpeechToTextDto = {
  call_id: string;
}

export type ISpeechToTextResponse = {
  speechToText: Transcription
}
