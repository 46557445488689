<details class="details" *ngIf="has_items" [open]="open">
  <summary class="summary Lexend-14 medium">
    {{ accordion_title }}
    <arrow-icon class="icon" />
  </summary>
  <div class="content">
    <ng-container *ngTemplateOutlet="contentTemplate" />
  </div>
</details>

<div class="content" *ngIf="!has_items">
  <ng-container *ngTemplateOutlet="contentTemplate" />
</div>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
