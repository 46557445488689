import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss']
})
export class TranscriptComponent {
  @Output() clickCross = new EventEmitter<void>();
  @Output() clickBack = new EventEmitter<void>();
  @Input() text: string;


  public onBack(){
    this.clickBack.emit();
  }

  public onClose(){
    this.clickCross.emit();
  }

}
