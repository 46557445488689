import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserWithScheduleFormatted } from 'src/app/entity/schedule/user-formatted.entity';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { UserRole } from 'src/app/enum/user-role.enum';
import { DayAbstractService } from '../day-abstract.service';

export interface ISchedulePicker {
  work_from: string;
  work_to: string;
  id?: string;
  user_id?: string;
  status?: string;
}
@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Output() createPeriod = new EventEmitter();
  @Output() requestedTime = new EventEmitter();

  @Input() editTemplate: TimeTemplate | undefined;
  @Output() editTemplateChange = new EventEmitter();

  @Output() onSavePicker = new EventEmitter();

  @Input() dayName: string;
  @Input() dayIndex: number;
  @Input() day: UserWithScheduleFormatted[''] = [];
  @Input() user: UserWithSchedule;

  public colors: { [key: string]: string } = {
    Orange: "color: #83602E;  background: #FCF6E6;",
    Light: "color: #006FDC;  background: #DAF1FB;",
    'Dark blue': "color: #FFFFFF;  background: #2D5B70;",
    Blue: "color: #325BB1;  background: #DBE6FD;",
    Pink: "color: #CE6E81;  background: #F9E0E5;",
    Violet: "color: #8872D2;  background: #ECE5FC;",
    Green: "color: #448884;  background: #D5F0F0;",
    Brown: "color: #A96549;  background: #ECE0DB;",
  };

  private readonly default_work_day: ISchedulePicker[] = [{ work_from: "12:00 am", work_to: "11:59 pm" }]
  public working_hours: ISchedulePicker[];
  public isWork = false;
  public time_templates: TimeTemplate[];
  public menuTopLeftPosition = { x: '0', y: '0' }

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private readonly scheduleService: DayAbstractService,
  ) { }

  ngOnInit(): void {
    this.isWork = this.day.length > 0;
    this.working_hours = [...this.day];

    this.scheduleService.getAllTimeTemplates()
      .subscribe((data) => {
        this.time_templates = data
      })

  }

  public get roles_show_time_template(): UserRole[] {
    return [UserRole.ADMIN, UserRole.DISPATCHER];
  }

  public get roles_show_requests(): UserRole[] {
    return [UserRole.MAIN_DISPATCHER, UserRole.ADMIN]
  }

  public get quantity_request_schedules(): string {
    return this.user.schedule_requests_formatted?.[this.dayName].length ? '+1' : '0'
  }

  public opeContextMenu(event: MouseEvent, period: TimeTemplate) {
    event.preventDefault();

    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    if (this.trigger) {
      this.trigger.menuData = { period }
      this.trigger.openMenu();
    }
  }

  public deleteTimeTemplate(period: TimeTemplate) {
    this.scheduleService.deleteTimeTemplate?.(period.id);
  }

  public setTimeTemplate(period: TimeTemplate) {
    this.editTemplate = period
    this.editTemplateChange.emit(period)
    this.createPeriod.emit()
  }

  public newPeriod() {
    this.createPeriod.emit();
  }

  public reviewRequestTime() {
    this.requestedTime.emit();
  }

  public checkIsSelectedTimePeriod(period: TimeTemplate) {
    const customTime: ISchedulePicker[] = JSON.parse(period.value);

    const check_range = customTime.reduce((check: boolean[], time) => {
      const exist_range = this.working_hours.some((selected_time) => selected_time.work_from === time.work_from && selected_time.work_to === time.work_to);
      check.push(exist_range);
      return check;
    }, [])

    return !check_range.includes(false)
  }

  public setIsWork() {
    this.isWork = !this.isWork;
    if (this.isWork) {
      if (this.day.length > 0) {
        this.working_hours = this.day
      } else {
        this.working_hours = this.default_work_day
      }
    }
  }

  public timeChange(e: Event, period: TimeTemplate) {
    const checked = (e.target as HTMLInputElement).checked
    const customTime: ISchedulePicker[] = JSON.parse(period.value);
    if (checked) {
      this.working_hours = [...this.working_hours, ...customTime]
    } else {
      const removeTime = this.working_hours.filter((time) => !customTime.some(({ work_from, work_to }) => work_from === time.work_from && work_to === time.work_to));
      this.working_hours = removeTime
    }
  }

  public onSave() {
    const format_request = this.working_hours.map((time) => ({
      id: time?.id,
      work_from: this.scheduleService.formatDate(time.work_from, this.dayIndex),
      work_to: this.scheduleService.formatDate(time.work_to, this.dayIndex),
      user_id: this.user.id,
    }))

    const filterRemovedTimes = this.day.filter(
      (day) => !format_request.some((time) => time.id && time.id === day.id)
    );

    if (filterRemovedTimes.length > 0) {
      this.scheduleService.deleteManyUserSchedules(filterRemovedTimes.map(time => time.id), this.user)
    }

    if (this.isWork) {
      this.scheduleService.createOrUpdateManyUserSchedules(format_request, this.user)
    } else {
      this.scheduleService.deleteManyUserSchedules(this.day.map(time => time.id), this.user)
    }
    this.onSavePicker.emit();
  }

}
