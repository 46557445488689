<div class="wrap">
  <table mat-table [dataSource]="difference" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" data-name="name">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef>SKU</th>
      <td mat-cell *matCellDef="let element" data-name="sku">
        {{ element.sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="uhs_sku">
      <th mat-header-cell *matHeaderCellDef>UHS SKU</th>
      <td mat-cell *matCellDef="let element" data-name="uhs_sku">
        {{ element.uhs_sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="template_quantity">
      <th mat-header-cell *matHeaderCellDef>Template Quantity</th>
      <td mat-cell *matCellDef="let element" data-name="template_quantity">
        {{ element.template_quantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actual_quantity">
      <th mat-header-cell *matHeaderCellDef>Actual Quantity</th>
      <td mat-cell *matCellDef="let element" data-name="actual_quantity">
        {{ element.actual_quantity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="difference">
      <th mat-header-cell *matHeaderCellDef>Difference</th>
      <td mat-cell *matCellDef="let element" data-name="Difference">
        {{ element.difference }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns_names"></tr>
    <tr mat-row *matRowDef="let row; columns: columns_names"></tr>
  </table>
</div>
