import { NgModule } from '@angular/core';
import { BreadCrumbsComponent } from './component/bread-crumbs.component';
import { CommonModule } from '@angular/common';
import { IconsModule } from '../../icons/icons.module';


@NgModule({
  imports: [CommonModule, IconsModule],
  declarations: [BreadCrumbsComponent],
  exports: [BreadCrumbsComponent],
})
export class BreadCrumbsModule {}
