import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';
import { ConstructedPageType } from 'src/app/enum/constructed-page-type.enum';
import { environment } from 'src/environments/environment';
import { ConstructorAbstractService } from '../../../constructor.abstract.service';
import {
  IGetConstructedPagesDto,
  IGetConstructedPagesResponse,
} from 'src/app/pages/slk-admin/apollo/query/get-constructed-pages';
import { QueryRef } from 'apollo-angular';

@Component({
  selector: 'constructed-pages',
  templateUrl: './constructed-pages.component.html',
  styleUrls: ['./constructed-pages.component.scss'],
})
export class ConstructedPagesComponent implements OnInit {
  public readonly TAKE = 100;
  @Input({ required: true, alias: 'posted' }) is_posted: boolean;
  public items: ConstructedPage[] = [];
  public load_more = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly constructorAbstractService: ConstructorAbstractService
  ) {}

  public get loading() {
    return this.constructorAbstractService.loading;
  }
  private get type() {
    return this.constructorAbstractService.type.toLocaleLowerCase();
  }
  private get company_id() {
    return this.constructorAbstractService.company_id;
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (params) => {
      this.constructorAbstractService.loading = true;
      const type = params.get('type') as ConstructedPageType;
      const page_id = params.get('page-id');
      const company_id = params.get('company-id');

      this.constructorAbstractService.configPageOnLoad(
        page_id,
        type,
        company_id
      );

      this.getAllItems(this.constructorAbstractService.type);
    });
  }

  public getAllItems(
    type: ConstructedPageType,
    pagination?: { skip: number; take: number },
    callback?: (items: ConstructedPage[]) => void
  ) {
    const { skip = 0, take = this.TAKE } = pagination || {};

    // this.load_more = false;
    this.constructorAbstractService
      .getAllItems({
        pagination: { skip, take },
        type,
        constructed_page_company_id: this.constructorAbstractService.company_id,
      })
      .valueChanges.subscribe(({ data, loading }) => {
        this.constructorAbstractService.loading = loading;
        const filtered_data = this.filter(data.getConstructedPages);
        const length = this.items.length;

        if (callback) callback(filtered_data);
        else this.items = filtered_data;

        this.load_more =
          length !== this.items.length &&
          data.getConstructedPages.length === this.TAKE;
      });
  }

  public removeItem({ id }: { id: string }) {
    this.items = this.items.filter((item) => !(item.id === id));
  }

  public loadMoreItems() {
    this.getAllItems(
      this.constructorAbstractService.type,
      { skip: this.items.length, take: this.TAKE },
      (i) => {
        this.addItems(i);
      }
    );
  }

  public addItems(items: ConstructedPage[]) {
    this.items = [...this.items, ...items];
  }

  public publish({ id, posted }: { id: string; posted: boolean }) {
    this.constructorAbstractService.publish(posted, id);
  }

  public filter(items: ConstructedPage[]) {
    return items.filter((item) => item.is_posted === this.is_posted);
  }

  public get edit_path() {
    return `/slk-admin/${this.company_id}/${this.type.toLowerCase()}/edit/`;
  }
}
