<div class="wrap">
  <div class="column">
    <info-field name="Phone">{{ company.phone }}</info-field>
    <info-field name="E-mail">{{ company.mail }}</info-field>
    <info-field name="Website">
      <a [href]="company.link_to_site" target="_blank" class="link">
        {{ company.link_to_site }}
      </a>
    </info-field>
    <info-field name="Address">
      {{ company.address[0] }}
    </info-field>
    <services [company]="company" />
  </div>
  <div class="column">
    <schedule [schedule]="company.schedules" />
    <company-logo [logo]="company_logo" />
  </div>
</div>
