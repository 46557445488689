<div class="wrap">
  <table mat-table [dataSource]="filtered_items" class="mat-elevation-z8">
    <caption class="caption Lexend-18 darck-blue start">
      {{
        date | date : "EEEE, MMMM d, y, h:mm:ss a"
      }}
    </caption>
    <ng-container matColumnDef="uhs_sku">
      <th mat-header-cell *matHeaderCellDef>UHS SKU</th>
      <td mat-cell *matCellDef="let element" data-name="UHS SKU">
        {{ element.item_template.uhs_sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="slk_sku">
      <th mat-header-cell *matHeaderCellDef>S.L.K SKU</th>
      <td mat-cell *matCellDef="let element" data-name="S.L.K SKU">
        {{ element.item_template.sku }}
      </td>
    </ng-container>

    <ng-container matColumnDef="difference">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element" data-name="Quantity">
        <mat-form-field>
          <input
            matInput
            type="number"
            [value]="element.requested_quantity"
            placeholder="Type quantity here"
            ariaAutoComplete="off"
            (focus)="focus(element.id)"
            (clickOutside)="changeValue(element.id, $event)"
          />
          <!--  -->
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="Approved">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>Actions</th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        data-name="Actions"
        class="actions"
      >
        <delete-cross-icon
          class="pointer cross"
          (click)="onDelete(element.id)"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="Waiting for consideration">
      <th style="width: 100px" mat-header-cell *matHeaderCellDef>Actions</th>
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        data-name="Actions"
        class="actions"
      >
        <check-icon class="pointer circle" (click)="onApprove(element.id)" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
