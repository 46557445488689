<wrapper-loading [loading]="loading">
  <div class="wrap" scrollEndRoot>
    <template-item *ngFor="let item of items" (publish)="publish($event)" (remove)="removeItem($event)" [templateDto]="{
        name: item.preview.headline,
        url: item.preview.photo?.file_id | getImageUrlByPhotoID,
        description: item.preview.description,
        id: item.id,
        edit_path,
        is_posted,
      }" />
  </div>
  <spinner-loading (scrollEnd)="loadMoreItems()" *ngIf="load_more" />
</wrapper-loading>