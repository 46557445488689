import { gql } from "apollo-angular";
import { Container } from "src/app/entity/container.entity";

export const GET_ALL_CONTAINERS = gql`
  query getAllContainers {
    getAllContainers {
      id
      name
      description
      is_primary
      total_items
      total_unique_items
      total_net_value
      total_value
      isLowStock
      selected @client
    }
  }
`;

export interface IGetAllContainersResponse {
  getAllContainers: Container[];
}
