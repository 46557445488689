import { gql } from "apollo-angular";
import { UserSchedule } from "src/app/entity/schedule/user-schedule.entity";

export const APPROVE_USER_SCHEDULE_REQUEST = gql`
  mutation approveUserScheduleRequest($request_ids: [ID!]!) {
    approveUserScheduleRequest(request_ids: $request_ids) {
      id
      user_id
      work_from
      work_to
    }
  }
`

export interface IApproveUserScheduleRequestDto {
  request_ids: string[];
}

export interface IApproveUserScheduleRequestResponse {
  approveUserScheduleRequest: UserSchedule[];
}
