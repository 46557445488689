import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { reversed_lover_case_constructed_page_type } from 'src/app/enum/constructed-page-type.enum';

@Injectable()
export class AllowedTypeConstructedPageGuard {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedTypes = Object.keys(reversed_lover_case_constructed_page_type);
    const type = route.paramMap.get('type');

    return !!type && allowedTypes.includes(type);
  }
}
