import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { CalendarModule } from 'src/app/modules/ui-kit/calendar/calendar.module';
import { CalendarScheduleModule } from '../../components/calendar/calendar.module';
import { DayModule } from '../../components/day/day.module';
import { NavigationModule } from '../../components/navigation/navigation.module';
import { MyScheduleComponent } from './my-schedule.component';
import { ChangeDateService } from './services/change-date.service';
import { DayMyScheduleService } from './services/day-schedule.service';
import { MyScheduleService } from './services/my-schedule.service';



@NgModule({
  declarations: [
    MyScheduleComponent,
  ],
  imports: [
    CommonModule,
    LoadingModule,
    MatTabsModule,
    IconsModule,
    CalendarModule,
    DayModule.inversion(DayMyScheduleService),
    NavigationModule.inversion(ChangeDateService),
    CalendarScheduleModule.inversion(ChangeDateService),
    RouterModule.forChild([
      {
        path: 'my-schedule',
        loadChildren: async () => (await import('src/app/pages/schedule/pages/my-schedule/my-schedule.module')).MyScheduleModule,
      },
      {
        path: '',
        title: 'My schedules | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: MyScheduleComponent
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions['my-schedule'],
        },
      },
    ])
  ],
  providers: [
    MyScheduleService,
    ChangeDateService,
    // DayMyScheduleService,
    // {
    //   provide: DayAbstractService,
    //   useClass: MyScheduleService
    // }
  ]
})

export class MyScheduleModule { }
