<div class="wrap-notes">
  <p *ngIf="note?.note">{{ note?.note || "" }}</p>
  <edit-icon
    *ifRoles="acceptable_edit_roles"
    class="pointer"
    (click)="openNotes()"
  />
</div>
<app-side-menu
  (onClose)="openNotes()"
  headline="Edit Note"
  [is_open]="open_notes"
  *ngIf="open_notes"
>
  <form class="form" (submit)="onSave()" [formGroup]="form">
    <mat-form-field class="example-full-width">
      <textarea
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        matInput
        formControlName="note"
        placeholder="Start typing..."
        class="textarea"
      ></textarea>
    </mat-form-field>
    <button mat-raised-button>Save</button>
  </form>
</app-side-menu>
