import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[resize]',
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Input() min: number;
  @Input() max: number;

  private id: string = '' + Math.random();
  private node: HTMLDivElement;
  private action: 'none' | 'resizing' = 'none';
  private oldX = 0;
  private oldWidth = 0;

  constructor(@Inject(ElementRef) private element: ElementRef) {
    this.mousemove = this.mousemove.bind(this);
    this.mouseup = this.mouseup.bind(this);
  }


  ngOnInit() {
    if (!this.min) this.min = 0;
    if (!this.max) this.max = 1000000;

    const div = document.createElement('div');
    div.classList.add('resize-icon');
    div.classList.add(this.id);
    div.draggable = false;
    div.addEventListener('mousedown', (e) => { this.mousedown(e) });

    this.element.nativeElement.appendChild(div);

    this.node = div;
  }


  mousedown(e: MouseEvent) {
    e.preventDefault();

    this.action = 'resizing';
    var rect = this.element.nativeElement.getBoundingClientRect();
    this.oldWidth = rect.width;
    this.oldX = e.clientX;
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseup);
  }

  mousemove(e: MouseEvent) {
    if (this.action === 'resizing') {
      const new_width = Math.max(
        Math.min(this.oldWidth + (e.clientX - this.oldX), this.max),
        this.min
      );
      this.element.nativeElement.style.width = new_width + 'px';
    }
  }

  mouseup(e: MouseEvent) {
    this.action = 'none';
    this.oldX = e.clientX;
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
  }

  ngOnDestroy() {
    this.node.remove();
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
    this.node.removeEventListener('mousedown', (e) => { this.mousedown(e) });
  }
}
