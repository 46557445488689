import { gql } from "apollo-angular";
import { UserSchedule } from "src/app/entity/schedule/user-schedule.entity";

export const DECLINE_USER_SCHEDULE_REQUEST = gql`
  mutation declineUserScheduleRequest($request_ids: [ID!]!) {
    declineUserScheduleRequest(request_ids: $request_ids) {
      id
      status
    }
  }
`

export interface IDeclineUserScheduleRequestDto {
  request_ids: string[];
}

export interface IDeclineUserScheduleRequestResponse {
  declineUserScheduleRequest: UserSchedule[];
}
