import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit {
  @Input({ required: true }) accordion_title: string;
  @Input() open: boolean;

  @ContentChildren('link') children: QueryList<HTMLAnchorElement>;

  public has_items: boolean = true;

  ngAfterContentInit() {
    setTimeout(() => {
      const contentCount = this.children.length;
      this.has_items = contentCount > 1;
    }, 0);
  }
}
