<p class="Lexend-18 medium dark-blue">{{ company.name }}</p>

<dots-icon class="dots pointer" mat-icon-button [matMenuTriggerFor]="menu" />
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openUpdateCompanySideMenu()">
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)="deleteCompany(company.id)">
    <span class="red">Delete</span>
  </button>
  <button mat-menu-item (click)="copyId(company.id)">
    <span class="blue">Copy ID</span>
  </button>
</mat-menu>

<div class="subgrid">
  <a [href]="'/slk-admin/' + company.id + '/blog/'" class="Lexend-16">
    Blogs
    <link-icon />
  </a>
  <a [href]="'/slk-admin/' + company.id + '/location/'" class="Lexend-16">
    Locations
    <link-icon />
  </a>
</div>

<side-forma
  class="side-forma"
  [(is_open)]="is_open"
  [company]="company"
  (submitForm)="onUpdate($event)"
  type="Update"
/>
