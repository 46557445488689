import { gql } from "apollo-angular";

export const DELETE_MANY_USER_SCHEDULES = gql`
  mutation deleteManyUserSchedules($ids: [ID!]!){
    deleteManyUserSchedules(ids: $ids)
  }
`


export type IDeleteManyUserSchedulesDto = {
  ids: string[]
}

export type IDeleteManyUserSchedulesResponse = {
  deleteManyUserSchedules: string[]
}
