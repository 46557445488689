import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';

import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { CreateLocksmithDto } from '../../../apollo/mutation/create-locksmith';
import {
  GET_LOCKSMITH_BY_ID,
  IGetLocksmithByIdDto,
  IGetLocksmithByIdResponse,
} from '../../../apollo/query/get-locksmith-by-id';
import { AllLocksmithService } from '../../../common/services/all-locksmith.service';

@Injectable()
export class RequestLocksmithService extends AllLocksmithService {
  constructor(apollo: Apollo, alertService: AlertService, router: Router) {
    super(apollo, alertService, router);
  }

  public getLocksmithById(variables: IGetLocksmithByIdDto) {
    return this.apollo
      .query<IGetLocksmithByIdResponse, IGetLocksmithByIdDto>({
        query: GET_LOCKSMITH_BY_ID,
        variables,
      })
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        if (data) {
          this.locksmith = data.getLocksmithById;
        }
      });
  }

  public deleteLocksmith(id: string) {
    this.deleteItem(id, false).add(() => {
      this.router && this.router.navigate(['/slk-admin/requests']);
    });
  }

  public submitForm(new_locksmith: CreateLocksmithDto['locksmith']) {
    this.updateLocksmith({
      ...new_locksmith,
      confirmed: true,
      id: this.locksmith.id,
    });
  }
}
