<form class="wrap" [formGroup]="nameForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <mat-label
      >Full name
    </mat-label>
    <input matInput formControlName="name" placeholder="Enter name" />

    <mat-error *ngIf="name.hasError('required')">
      Name <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <div class="btn_wrap">
    <button mat-raised-button type="submit">Save changes</button>
    <button mat-button type="button" (click)="resetPasswordChange()">
      Cancel
    </button>
  </div>
</form>
