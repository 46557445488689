import { Component } from '@angular/core';
import { CompaniesService } from '../../companies.service';
import { ICreateConstructedPageCompanyDto } from '../../graphql/mutation/create-constructed-page-company.mutation';

@Component({
  selector: 'create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent {
  public is_open = false;

  constructor(private readonly companiesService: CompaniesService) {}

  public open() {
    this.is_open = true;
  }

  public onSubmit(
    company_dto: ICreateConstructedPageCompanyDto['company_dto']
  ) {
    this.companiesService.createCompagnie({ company_dto });
  }
}
