import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconsModule } from '../../icons/icons.module';
import { CalendarComponent } from './calendar.component';

@NgModule({
  imports: [CommonModule, IconsModule, MatButtonModule,],
  declarations: [CalendarComponent],
  exports: [CalendarComponent],
})
export class CalendarModule { }
