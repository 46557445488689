<!-- <dropdown
  name="Our services"
  [show_remove_icon]="true"
  (removeItem)="removeBlock()"
> -->
<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Description </mat-label>
    <textarea cdkTextareaAutosize matInput placeholder="Type description here" formControlName="description"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> List </mat-label>
    <textarea cdkTextareaAutosize matInput placeholder="Type list here" formControlName="list"></textarea>
  </mat-form-field>
</form>
<!-- </dropdown> -->