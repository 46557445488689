import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { PasswordInputModule } from 'src/app/modules/ui-kit/password-input/password-input.module';
import { AcceptInviteComponent } from './component/accept-invite.component';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'accept-invite',
        title: 'Accept Invite | SLK CRM',
        children: [
          {
            path: '',
            component: AcceptInviteComponent,
          },
        ],
        canActivate: [AuthGuard],
        data: {
          protection: ProtectionLevels.UNAUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.register,
        },
      },
    ]),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    PasswordInputModule,
    CommonModule,
    IconsModule,
  ],
  declarations: [AcceptInviteComponent],
})
export class AcceptInviteModule { }
