import { Component } from '@angular/core';
import { LogsService } from '../../logs.service';
import { HelperService } from 'src/app/modules/helper/helper.service';
import { IGetLogsResponse } from '../../apollo/query/get-logs.query';

@Component({
  selector: 'logs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class LogsTableComponent {
  constructor(
    public readonly helperService: HelperService,
    public readonly logsService: LogsService
  ) { }

  public displayedColumns: string[] = ['number', 'action', 'message', 'user_name', 'type', 'group', 'date'];

  public loading = true;

  public data: IGetLogsResponse['getLogs'] = {
    items: [],
    total: 0,
  };

  ngOnInit() {
    this.logsService.getLogs({ skip: 0, take: 30 })
      .subscribe((res) => {
        this.data = res;
        this.loading = false;
      });
  }

  public loadMore() {
    this.logsService
      .getLogs({ skip: this.data.items.length, take: 30 })
      .subscribe(
        ({ items, total }) => {
          this.data = { items: [...this.data.items, ...items], total }
        }
      );
  }
}
