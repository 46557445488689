import { Component } from '@angular/core';
import { CalendarScheduleAbstractService } from './calendar.abstract.service';

@Component({
  selector: 'schedule-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  public open_calendar = false;
  constructor(
    public readonly abstractService: CalendarScheduleAbstractService
  ) { }


  public get weekStartDate(): string {
    return this.abstractService.weekStartDate
  }

  public get weekEndDate(): string {
    return this.abstractService.weekEndDate
  }

  public get startWeek(): moment.Moment {
    return this.abstractService.startWeek
  }

  public get endWeek(): moment.Moment {
    return this.abstractService.endWeek
  }

  public toggleOpenCalendar(state: boolean) {
    this.open_calendar = state;
  }

  public setCalendarIndex(range: { start: moment.Moment, end: moment.Moment }) {
    this.abstractService.setCalendarIndex(range)
    this.toggleOpenCalendar(false);
  }
}
