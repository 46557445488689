import { gql } from "apollo-angular";
import { Template } from "src/app/entity/template.entity";

export const GET_ALL_TEMPLATES = gql`
  query getAllTemplates {
    getAllTemplates {
      id
      name
    }
  }
`;

export interface IGetTemplatesResponse {
  getAllTemplates: Template[];
}
