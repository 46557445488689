import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutModule } from 'src/app/layout/default/default.module';
import { MainLayoutModule } from 'src/app/layout/main/main.module';

const routes: Routes = [
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    MainLayoutModule,
    DefaultLayoutModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
