import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-one',
  templateUrl: './title-one.component.html',
  styleUrls: ['./title-one.component.scss']
})
export class TitleOneComponent {
  @Input() headline: string;
}
