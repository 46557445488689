<div class="wrap">
  <div class="head">
    <h1 class="Lexend-32 regular">Cars</h1>
    <button mat-raised-button (click)="downloadDifference()">
      Download difference
    </button>
  </div>

  <mat-tab-group animationDuration="0">
    <mat-tab label="Container">
      <wrapper-loading [loading]="loading">
        <cars-table [container_items]="container_items || []" />
      </wrapper-loading>
    </mat-tab>
    <mat-tab label="Difference" *ngIf="difference && difference.length > 0">
      <difference-table [difference]="difference" />
    </mat-tab>
  </mat-tab-group>
</div>
