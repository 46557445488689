import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsService } from './settings.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './component/settings.component';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: 'settings',
        title: 'Settings | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: SettingsComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.settings,
        },
      },
    ])
  ],
  providers: [SettingsService],
  declarations: [SettingsComponent],
})
export class SettingsModule {}
