import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GET_FINE_TUNE_LIST, IFineTuneListResponse,
} from './apollo/query/get-fine-tune-list';
import { map } from 'rxjs';
import { IStartFineTuneResponse, START_FINE_TUNE } from './apollo/mutation/start-fine-tune';
import {
  GET_FULL_LAST_FINE_TUNE, IFullLastFineTuneResponse,
} from './apollo/query/get-full-last-fine-tune';
import { DELETE_FINE_TUNE_ITEM, IDeleteFineTuneItemDto, IDeleteFineTuneItemResponse } from './apollo/mutation/delete-fine-tune-item';
import { IUpdateFineTuneItemDto, IUpdateFineTuneItemResponse, UPDATE_FINE_TUNE_ITEM } from './apollo/mutation/update-fine-tune-item';


@Injectable()
export class TeachingService {
  constructor(private readonly apollo: Apollo) { }

  public getFineTuneList() {
    return this.apollo
      .watchQuery<IFineTuneListResponse, {}>({ query: GET_FINE_TUNE_LIST })
      .valueChanges.pipe(map(({ data }) => data.getFineTuneList));
  }

  public getFullLastFineTune() {
    return this.apollo
      .watchQuery<IFullLastFineTuneResponse, {}>({ query: GET_FULL_LAST_FINE_TUNE })
      .valueChanges.pipe(map(({ data }) => data.getFullLastFineTune));
  }

  public startFineTune() {
    return this.apollo.mutate<IStartFineTuneResponse, {}>({ mutation: START_FINE_TUNE })
  }

  public deleteFineTuneItem(id: string) {
    return this.apollo
      .mutate<IDeleteFineTuneItemResponse, IDeleteFineTuneItemDto>({
        mutation: DELETE_FINE_TUNE_ITEM,
        variables: { id },
        update: (cache) => {
          cache.modify({
            fields: {
              getFineTuneList: (exist: readonly { __ref: string }[]) => {
                return exist.filter((fine_tune) => !fine_tune.__ref.includes(id));
              }
            }
          })
        },
      })
  }

  public updateFineTuneItem(fine_tune_item: IUpdateFineTuneItemDto['fine_tune_item']) {
    return this.apollo
      .mutate<IUpdateFineTuneItemResponse, IUpdateFineTuneItemDto>({
        mutation: UPDATE_FINE_TUNE_ITEM,
        variables: { fine_tune_item },
      })
  }
}
