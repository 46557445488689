import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { UserRole } from 'src/app/enum/user-role.enum';
import { CarsService } from 'src/app/pages/cars/cars.service';
@Injectable()
export class InventoryTechGuard {
  constructor(
    public auth: AuthorizationService,
    public router: Router,
    private carsService: CarsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {
      this.auth.getCurrentUser().subscribe({
        next: ({ loading, data: auth_data }) => {
          const acceptable_roles: UserRole[] = route.data['acceptable_roles'];

          const error_href = {
            [UserRole.ADMIN]: '/chat/',
            [UserRole.DISPATCHER]: '/schedule/',
            [UserRole.MAIN_DISPATCHER]: '/schedule/',
            [UserRole.TECHNICIAN]: '/profile/',
            [UserRole.LOGISTIC]: '/profile/',
            [UserRole.HR]: '/profile/',
            [UserRole.MANAGER]: '/profile/',
            [UserRole.MARKETING]: '/profile/',
          };

          this.carsService
            .findUpdateCarRequests()
            .subscribe((formatted_request) => {
              if (
                Object.keys(formatted_request.consideration).length > 0 ||
                Object.keys(formatted_request.submitted).length > 0
              ) {
                observer.next(true);
              } else {
                this.router.navigate([
                  error_href[auth_data.getCurrentUser.role],
                ]);
                observer.next(false);
              }
              observer.complete();
            });
        },
        error: () => {
          observer.next(false);
        },
      });
    });
  }
}
