import { gql } from "apollo-angular";

export const DELETE_MANY_USER_SCHEDULE_REQUEST = gql`
  mutation deleteManyUserScheduleRequest($ids: [ID!]!) {
    deleteManyUserScheduleRequest(ids: $ids)
  }
`;

export interface IDeleteUserScheduleRequestDto {
  ids: string[];
}
