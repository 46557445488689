import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { IRequest } from 'src/app/entity/requests.entity';
import { Schedule } from 'src/app/entity/schedule.entity';
import { FormAbstractService } from '../../services/form-abstract.service';
import { generateUrl } from './id-link/id-link.component';

export function maskPhoneNumber(e: Event) {
  const { value } = e.target as HTMLInputElement;
  const [_, one, two, three] = value
    .replace(/(\+1 | \( | -)/g, '')
    .replace(/[^0-9\+]/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/) as string[];
  const number = !two
    ? one.length > 0
      ? '+1 (' + one
      : one
    : '+1 (' + one + ') ' + two + (three ? '-' + three : '');
  (e.target as HTMLInputElement).value = number;
}

export function validateSchedule() {
  return (formArray: FormArray): { [key: string]: any } | null => {
    let valid: boolean = true;
    valid = checkSchedule(formArray);
    return valid ? null : { error: true };
  };
}

export function checkSchedule(formArray: FormArray) {
  return formArray.value.some((day: Schedule) => {
    return !!day.open_at && !!day.close_at;
  });
}

export function convertTime(time: string | null) {
  if (time) {
    return `2021-12-09T${moment(time, 'hh:mm A').format('HH:mm')}:00Z`;
  } else {
    return null;
  }
}

export function convertSchedule(days: Schedule[]) {
  return days.map((day) => {
    return {
      ...day,
      close_at: convertTime(day.close_at),
      open_at: convertTime(day.open_at),
    };
  });
}

export function formatTime(time: string | null) {
  if (time) {
    return moment(time).utc().format('hh:mm A');
  } else return null;
}

export type FormCompany =
  | (Locksmith & {
      business_phone?: string;
      business_mail?: string;
      business_name?: string;
    })
  | (IRequest & { phone?: string; mail?: string });

@Component({
  selector: 'company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class companyFormComponent implements OnInit {
  @Input() company?: Locksmith;

  url_pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  service_error = '';
  schedule_error = '';

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    mail: new FormControl('', [Validators.required, Validators.email]),
    link_to_site: new FormControl('', [
      Validators.pattern(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/),
    ]),
    owner_name: new FormControl(''),
    owner_phone: new FormControl(''),
    description: new FormControl(''),
    link_to_map: new FormControl('', [
      Validators.required,
      Validators.pattern(this.url_pattern),
    ]),
    address: new FormArray([]),
    schedules: new FormArray<FormControl<Schedule>>(
      [],
      validateSchedule() as ValidatorFn
    ),
    services: new FormArray<FormControl<string>>([], Validators.required),
    file_url: new FormControl(null),
    file: new FormControl(null),
    // photo: new FormControl(null),
  });

  constructor(public formAbstractService: FormAbstractService) {}

  ngOnInit(): void {
    if (this.company) {
      this.form.patchValue({
        name: this.company.name ?? this.company.name,
        phone: this.company.phone ?? this.company.phone,
        mail: this.company.mail ?? this.company.mail,
        link_to_site: this.company.link_to_site,
        owners_name: this.company.owner_name,
        owners_phone: this.company.owner_phone,
        description: this.company.description,
        link_to_map: this.company.link_to_map,
        // photo:
        // this.company.file_url ?? environment.S3_URL + this.company.file_id,
      });

      this.company.address &&
        this.company.address.forEach((address) => {
          const address_control = new FormControl(address);
          (this.form.get('address') as FormArray).push(address_control);
        });

      this.company.schedules.forEach((day) => {
        const day_group = new FormGroup({
          id: new FormControl(day.id),
          name: new FormControl(day.name),
          open_at: new FormControl(formatTime(day.open_at)),
          close_at: new FormControl(formatTime(day.close_at)),
        });
        (this.form.get('schedules') as FormArray).push(day_group);
      });

      this.company.services.forEach((service) => {
        const service_control = new FormControl(service);
        (this.form.get('services') as FormArray).push(service_control);
      });
    } else {
      this.addAddress();
    }
  }

  onDelete() {
    if (this.company) {
      this.formAbstractService.deleteLocksmith(this.company.id);
    }
  }

  private scrollToFirstInvalidControl() {
    let form = document.getElementById('formId');
    let firstInvalidControl = (form as HTMLFormElement).getElementsByClassName(
      'ng-invalid'
    )[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  handlePhone(e: Event) {
    maskPhoneNumber(e);
  }

  get workingScheduleControls() {
    return (<FormArray>this.form.get('schedules')).controls;
  }

  public addAddress() {
    const address = new FormControl('', [Validators.required]);
    (<FormArray>this.form.get('address')).push(address);
  }

  public submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.formAbstractService.submitForm({
        ...this.form.value!,
        schedules: convertSchedule(this.form.value.schedules as Schedule[]),
        url: generateUrl(this.form),
        confirmed: true,
      });
    } else {
      this.scrollToFirstInvalidControl();
    }
  }
}
