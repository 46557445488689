import { NgModule } from "@angular/core";
import { IconsModule } from "src/app/modules/icons/icons.module";
import { RouterModule, Routes } from "@angular/router";
import { DefaultLayoutComponent } from "./default.component";
import { LoginComponent } from "src/app/pages/login/component/login.component";
import { ProtectionLevels } from "src/app/enum/protection-levels.enum";
import { AuthGuard } from "src/app/modules/authorization/guard/auth.guard";

const routes: Routes = [
  {
    path: '',
    title: 'Login | SLK CRM',
    component: DefaultLayoutComponent,
    children: [{
      path: '',
      component: LoginComponent,
    }],
    canActivate: [AuthGuard],
    data: { protection: ProtectionLevels.UNAUTHORIZED, error_href: '/chat/' }

  }
];


@NgModule({
  imports: [
    IconsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    DefaultLayoutComponent
  ]
})
export class DefaultLayoutModule { }
