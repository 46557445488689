import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { DayAbstractService } from '../../day-abstract.service';
import { ISchedulePicker } from '../time-picker.component';

@Component({
  selector: 'periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss'],
})
export class PeriodsComponent implements OnInit, OnDestroy {
  private readonly default_work_day: ISchedulePicker[] = [{ work_from: "12:00 am", work_to: "11:59 pm" }]

  @Output() changeStep = new EventEmitter();

  @Output() editDateChange = new EventEmitter();
  @Input() editDate: TimeTemplate | undefined;

  public colors = {
    Orange: "color: #83602E;  background: #FCF6E6;",
    Light: "color: #006FDC;  background: #DAF1FB;",
    'Dark blue': "color: #FFFFFF;  background: #2D5B70;",
    Blue: "color: #325BB1;  background: #DBE6FD;",
    Pink: "color: #CE6E81;  background: #F9E0E5;",
    Violet: "color: #8872D2;  background: #ECE5FC;",
    Green: "color: #448884;  background: #D5F0F0;",
    Brown: "color: #A96549;  background: #ECE0DB;",
  };

  public working_hours = this.default_work_day;

  public form = new FormGroup({
    name: new FormControl('', { validators: [Validators.required] }),
    color: new FormControl('', { validators: [Validators.required] }),
  })

  public get nameHasError() {
    return this.form.get('name')?.hasError('required');
  }

  constructor(
    private readonly scheduleService: DayAbstractService
  ) { }

  ngOnDestroy(): void {
    this.editDateChange.emit(undefined);
  }

  ngOnInit(): void {
    if (this.editDate) {
      this.working_hours = JSON.parse(this.editDate.value);
      this.form.patchValue({
        name: this.editDate.name,
        color: this.editDate.color,
      });
    }
  }

  public onSubmit() {
    if (this.editDate) {
      this.scheduleService.updateTimeTemplate?.({
        ...this.form.value,
        value: JSON.stringify(this.working_hours),
        id: this.editDate.id
      } as TimeTemplate)

      this.changeStep.emit();

    } else {
      this.scheduleService.createTimeTemplate?.({
        ...this.form.value,
        value: JSON.stringify(this.working_hours)
      } as TimeTemplate)
      this.changeStep.emit()
    }
  }
}

