import { onError } from '@apollo/client/link/error';
import {
  RefreshTokenStrategy,
  ResolveForward
} from './refresh-token.strategy';
import { fromPromise } from '@apollo/client/link/utils';
import { Operation } from '@apollo/client/link/core';

export enum Errors {
  JWT_EXPIRED = 'Invalid token!',
  REFRESH_TOKEN_NOT_FOUND = 'Invalid refresh token!',
}

const refreshToken = new RefreshTokenStrategy();

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    for (const err of graphQLErrors) {
      switch (err.message) {
        case Errors.JWT_EXPIRED: {
          if (!refreshToken.isRefreshProcessStarted) {
            refreshToken.getNewToken();
          }

          return fromPromise(
            new Promise(
              (resolve: ResolveForward) =>
                (refreshToken.addPendingRequests = { resolve, operation })
            )
          )
            .filter((value) => Boolean(value))
            .flatMap((new_operation: Operation) => forward(new_operation));
        }
        case Errors.REFRESH_TOKEN_NOT_FOUND: {
          return refreshToken.removeInvalidTokens();
        }
      }
    }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
