import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { AllLocksmithService } from '../../common/services/all-locksmith.service';

@Injectable()
export class RequestService extends AllLocksmithService {
  public item_btn_name: string = 'View';

  path: string = 'request-locksmith';

  constructor(apollo: Apollo, alertService: AlertService, router: Router) {
    super(apollo, alertService, router);
  }

  searchLocksmith(value: string) {
    this.setSearchValue(value, false);
  }

  getLocksmith() {
    this.getAllLocksmith(false);
  }

  togglePage(event: PageEvent) {
    this.handlePageChange(event, false);
  }

  deleteLocksmith(id: string) {
    this.deleteItem(id, false);
  }
}
