<div class='aboutUsBg'>
  <a id="aboutUsH" class='anchor' ></a>
  <div class='aboutUs'>
    <div class='secondMainSpan'>
      <h2 [innerHTML]="data?.headline" ></h2>
      <hr />
    </div>
    <div class='textBlock'>
      <p class='text' [innerHTML]="data?.description" ></p>
    </div>
  </div>
</div>
