<div class="wrap">
  <app-bulk-action />
  <table mat-table [dataSource]="users" class="mat-elevation-z8">
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          [checked]="isEveryUserSelected() && users.length > 0"
          (change)="handleChange($event.checked)"
        />
      </th>
      <td mat-cell *matCellDef="let element" data-name="User">
        <mat-checkbox
          [checked]="element.selected"
          (change)="handleChange(!element.selected, element.id)"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element" data-name="User">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="workiz_id">
      <th mat-header-cell *matHeaderCellDef>Workiz ID</th>
      <td mat-cell *matCellDef="let element" data-name="Workiz ID">
        {{ element.workiz_id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element" data-name="Location">
        {{ element.location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let element" data-name="E-mail">
        {{ element.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element" data-name="Role">
        <p [className]="'role ' + element.role | lowercase">
          {{ element.role | remove_underscore }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" data-name="Action">
        <div class="actions" *ngIf="getCurrentTab === 'Users'">
          <edit-icon class="pointer" (click)="onEdit(element)" />
          <delete-icon class="pointer" (click)="onDelete(element.id)" />
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
