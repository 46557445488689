import { Component, OnInit } from '@angular/core';
import { CarsService } from '../../cars.service';
import { Observable } from 'rxjs';
import { Container } from 'src/app/entity/container.entity';
import { Template } from 'src/app/entity/template.entity';
import { CarTemplate } from 'src/app/entity/car_template.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { SendToUpdateComponent } from './send-to-update/send-to-update.component';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.scss']
})
export class ContainersComponent implements OnInit {
  public $containers: Observable<Container[]>;
  public $templates: Observable<Template[]>;
  // public container_id?: string;

  constructor(
    private readonly carsService: CarsService,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.$containers = this.carsService.getAllContainers();
    this.$templates = this.carsService.getAllTemplates();
  }

  public assignTemplate(vars: Omit<CarTemplate, 'id'>) {
    this.carsService.assignManyTemplateToCar([vars]).subscribe({
      complete: () => this.alertService.alert({ message: 'Template successfully assigned', type: 'success' }),
      error: (e) => this.alertService.alert({ message: e.message, type: 'error' })
    });
  }

  public handleSelectContainer(id: string) {
    this.carsService.selectContainer(id);
  }

  public setContainerId(id: string) {
    this.dialog.open(SendToUpdateComponent, {
      data: { id },
      width: '100%',
      maxWidth: '800px',
    })
  }
}
