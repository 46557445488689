<form>
  <mat-form-field>
    <search-icon class="search-icon" />
    <input
      matInput
      formControlName="name"
      placeholder="Enter name"
      (input)="setSearchValue($event)"
    />
  </mat-form-field>
</form>

<div class="title_wrap">
  <h1 class="Lexend-32 regular">Businesses</h1>
  <a
    routerLink="/slk-admin/create-new"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
  >
    <button mat-raised-button>Create new</button>
  </a>
</div>

<wrapper-loading [loading]="loading">
  <div *ngIf="confirmed_locksmith">
    <div class="wrap">
      <company-item
        [btn_name]="btn_name"
        *ngFor="let company of confirmed_locksmith"
        [company]="company"
      />
    </div>

    <div class="noLocksmithWrap" *ngIf="confirmed_locksmith.length === 0">
      <div class="noLocksmith">
        <search-in-circle />
        <p class="Lexend-24 regular dark-blue">No businesses found</p>
        <p class="Lexend-18 regular grey">
          Businesses appear after somebody left them <br />
          on the website
        </p>
      </div>
    </div>

    <mat-paginator
      *ngIf="confirmed_locksmith.length > 0"
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[5, 10, 15]"
      [pageSize]="pageSize"
      (page)="onPageChange($event)"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </div>
</wrapper-loading>
