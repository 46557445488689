import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { FourZeroFourComponent } from './four-zero-four.component';



@NgModule({
  declarations: [
    FourZeroFourComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '404',
        // redirectTo: '404',
        title: 'Teaching | SLK CRM',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: FourZeroFourComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.teaching,
        },
      },
    ])
  ]
})
export class FourZeroFourModule { }
