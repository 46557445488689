import { Component, Input } from '@angular/core';
import { Locksmith } from 'src/app/entity/locksmith.entity';
import { environment } from 'src/environments/environment';

import { Company } from '../company-item.component';
@Component({
  selector: 'full-info',
  templateUrl: './full-info.component.html',
  styleUrls: ['./full-info.component.css'],
})
export class FullInfoComponent {
  @Input() company: Locksmith;

  public get company_logo() {
    return this.company.file_url || this.company.file_url
      ? environment.S3_URL + this.company.file_id
      : '';
  }
}
