import { gql } from 'apollo-angular';
import { SystemSettings } from 'src/app/entity/system-settings.entity';

export const UPDATE_SYSTEM_SETTINGS = gql`
  mutation updateSystemSettings($system_settings: UpdateSystemSettingsDto!) {
    updateSystemSettings(system_settings: $system_settings) {
      id
      max_tokens
      workiz_cookie
      workiz_arizona_cookie
    }
  }
`;

export type IUpdateSystemSettingsDto = {
  system_settings: Pick<SystemSettings, 'max_tokens' | 'workiz_cookie'>;
};

export type IUpdateSystemSettingsResponse = {
  updateSystemSettings: SystemSettings;
};
