import { gql } from "apollo-angular";
import { UserWithSchedule } from "src/app/entity/schedule/user-with-schedules.entity";

export const GET_MY_SCHEDULE = gql`
  query getMySchedule(
    $from: Int!
    $to: Int!
  ){
    getMySchedule(
      from: $from
      to: $to
    )
    {
      id
      email
      name
      info {
        id
        key
        user_id
        value
      }

      schedules_formatted @client
      schedules {
        id
        user_id
        work_from
        work_to
      }

      schedule_requests_formatted @client
      schedule_requests {
        id
        user_id
        work_from
        work_to
        status
      }

      notes {
        id
        note
        user_id
        week_end
        week_start
      }

      # selected @client
      workiz_id
      location
    }
  }
`

export interface IGetMyScheduleDto {
  from: number;
  to: number;
}

export interface IGetMyScheduleResponse {
  getMySchedule: UserWithSchedule
}
