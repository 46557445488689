import { gql } from 'apollo-angular';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';

export const CREATE_CONSTRUCTED_PAGE_COMPANY = gql`
  mutation createConstructedPageCompany(
    $company_dto: CreateConstructedPageCompanyDto!
  ) {
    createConstructedPageCompany(company_dto: $company_dto) {
      id
      name
    }
  }
`;

export interface ICreateConstructedPageCompanyDto {
  company_dto: Omit<ConstructedCompany, 'id'>;
}

export interface ICreateConstructedPageCompanyResponse {
  createConstructedPageCompany: ConstructedCompany;
}
