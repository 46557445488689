import { gql } from "apollo-angular";
import { WorkizItem } from "src/app/entity/workiz-item.entity";

export const GET_CONTAINER_BY_ID = gql`
  query getContainerById ($id: String!) {
    getContainerById (id: $id) {
      container  {
        id
        cost
        item_id
        item_name
        minimum_at_location
        price
        qty
        total_items
        total_unique_items
      }
    }
  }
`;

export interface IGetContainerByIdDto {
  id: string;
}

export interface IGetContainerByIdResponse {
  getContainerById: {
    container: WorkizItem[];
  }
}
