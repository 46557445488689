<div class="head">
  <h1 class="h1">Teaching</h1>

  <div>
    <button (click)="openDialog()">
      View last teaching
      <refresh-icon />
    </button>
    <button class="start" (click)="startFineTune()">Start teaching</button>
  </div>
</div>
<div class="teachingBlocksWrap">
  <teaching-block
    *ngFor="let fineTune of getFineTuneList | async"
    [fineTune]="fineTune"
  />
</div>
