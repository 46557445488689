<div class="page">
  <h1 class="Lexend-32 regular">API</h1>
  <mat-tab-group animationDuration="0">
    <mat-tab label="Workiz">
      <workiz />
    </mat-tab>
    <mat-tab label="CTM">
      <ctm />
    </mat-tab>
  </mat-tab-group>
</div>
