import { Component, Input } from '@angular/core';

@Component({
  selector: 'play-button',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent {
  @Input() pause: boolean = false;
}
