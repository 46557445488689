<form (ngSubmit)="onSubmit()" class="form" [formGroup]="form">

  <mat-form-field>
    <mat-label>
      Name
    </mat-label>

    <mat-error *ngIf="name.hasError('required')">
      Name <strong>required</strong>
    </mat-error>

    <input matInput type="text" placeholder="Type name here" formControlName="name" />
  </mat-form-field>

  <button mat-raised-button type="submit" [disabled]="form.invalid || loading">Save</button>
</form>
