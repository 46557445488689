import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Locksmith } from 'src/app/entity/locksmith.entity';

@Injectable()
export abstract class AllLocksmithAbstractService {
  public abstract item_btn_name: string;
  public abstract path: string;
  public abstract page_size: number;
  public abstract confirmed_locksmith: Locksmith[];
  public abstract loading: boolean;
  public abstract page_index: number;
  public abstract total_length: number;
  public abstract togglePage(event: PageEvent): void;
  public abstract getLocksmith(): void;
  public abstract searchLocksmith(value: string): void;
  public abstract deleteLocksmith(id: string): void;
}
