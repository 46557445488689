import { Component } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-style-fields',
  templateUrl: './style-fields.component.html',
  styleUrls: ['./style-fields.component.scss']
})
export class StyleFieldsComponent {
  constructor(
    public readonly controlContainer: ControlContainer
  ) { }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control
  }

  public get styles() {
    return this.control?.value;
  }

  public getTypeof(value: any) {
    return typeof value;
  }
}
