import { Component } from '@angular/core';
import { IGetAllMessagesResponse } from '../../../apollo/query/get-all-messages.query';
import { ChatService } from '../../../chat.service';

@Component({
  selector: 'answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss']
})
export class AnswersComponent {
  private getMessages: IGetAllMessagesResponse['getMessages'];

  constructor(
    private readonly chatService: ChatService,
  ) { }

  get messages() {
    return this.getMessages?.items || [];
  }

  get check_total() {
    return this.getMessages?.items.length !== this.getMessages?.total
  }

  ngOnInit(): void {
    this.chatService.getAllMessages({ skip: 0, take: 10 }).subscribe(({ data, loading }) => {

      this.getMessages = data.getMessages
    })
  }

  public loadMoreMessages() {
    this.chatService.getAllMessages({ skip: this.getMessages?.items.length, take: 10 }).subscribe(({ data }) => {
      this.getMessages = { ...data.getMessages, items: [...this.getMessages.items, ...data.getMessages.items], }
    })
  }
}
