import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './components/companies.component';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { CompaniesService } from './companies.service';
import { CreateComponent } from './components/create/create.component';
import { MatButtonModule } from '@angular/material/button';
import { SideFormaComponent } from './components/side-forma/side-forma.component';
import { SideMenuModule } from 'src/app/modules/ui-kit/side-menu/side-menu.module';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { MatMenuModule } from '@angular/material/menu';
import { DeleteDialogModule } from 'src/app/modules/ui-kit/delete-item-popup/delete-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CompanyCardComponent } from './components/company-card/company-card.component';
import { AlertModule } from 'src/app/modules/ui-kit/alert/alert.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    SideMenuModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    IconsModule,
    MatMenuModule,
    DeleteDialogModule,
    MatDialogModule,
    AlertModule,
    RouterModule.forChild([
      {
        path: 'slk-admin/companies',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: CompaniesComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [
    CompaniesComponent,
    CreateComponent,
    SideFormaComponent,
    CompanyCardComponent,
  ],
  providers: [CompaniesService],
})
export class CompaniesModule {}
