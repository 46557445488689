<div class="bulk-action-cars">
  <p class="Lexend-14 grey">({{ selected_containers.length }} selected)</p>

  <div class="buttons">
    <button mat-button class="blue-color" [matMenuTriggerFor]="menu">
      Assign Template
    </button>
    <hr />
    <button mat-button (click)="onCancel()">Cancel</button>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let template of $templates | async"
      (click)="assignTemplate(template.id)"
    >
      {{ template.name }}
    </button>
  </mat-menu>
</div>
