<div class="styles-wrap" [formGroup]="$any(control)">
  <p class="title">Styles</p>

  <div class="styles">
    <div class="styles">
      <mat-slide-toggle formControlName="add_top_need_more_info_button">
        add_top_need_more_info_button
      </mat-slide-toggle>
    </div>

    <div class="styles">
      <mat-slide-toggle formControlName="add_bottom_need_more_info_button">
        add_bottom_need_more_info_button
      </mat-slide-toggle>
    </div>
  </div>
</div>
