import { UserRole } from 'src/app/enum/user-role.enum';

const tech_locations = [
  'Connecticut',
  'Texas (Dallas)',
  'Subs Dallas',
  'Texas (Houston)',
  'Subs Houston',
  'Arizona',
  'Arizona Subs',
];

const dispatch_locations = ['Georgia', 'Ukraine'];

export const choose_location_list = {
  [UserRole.DISPATCHER]: dispatch_locations,
  [UserRole.MAIN_DISPATCHER]: dispatch_locations,
  [UserRole.TECHNICIAN]: tech_locations,
  [UserRole.ADMIN]: [],
  [UserRole.LOGISTIC]: [],
  [UserRole.HR]: [],
  [UserRole.MANAGER]: [],
  [UserRole.MARKETING]: [],
};
