import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ReviewCardComponent } from './component/review-card/review-card.component';
import { ReviewsComponent } from './component/reviews.component';

@NgModule({
  imports: [CommonModule, MatTabsModule, MatIconModule],
  declarations: [ReviewsComponent, ReviewsComponent, ReviewCardComponent],
  exports: [ReviewsComponent],
})
export class reviewsModule {}
