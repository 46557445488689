<div class="title_wrap">
  <h1>{{ type }}s</h1>
  <a
    [routerLink]="create_path"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
  >
    <button mat-raised-button>Create {{ type }}</button>
  </a>
</div>
<mat-tab-group>
  <mat-tab label="Published">
    <constructed-pages [posted]="true" />
  </mat-tab>
  <mat-tab label="Created">
    <constructed-pages [posted]="false" />
  </mat-tab>
</mat-tab-group>
