import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItemTemplate } from 'src/app/entity/item_template.entity';
import { CarsService } from 'src/app/pages/cars/cars.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';

@Component({
  selector: 'template-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TemplateTableComponent {
  @Input() template_items: ItemTemplate[];
  @Output() setEditItem = new EventEmitter<Omit<ItemTemplate, 'template_id'>>();

  public displayedColumns: string[] = [
    'uhs_sku',
    'slk_sku',
    'quantity',
    'actions',
  ];

  constructor(
    public dialog: MatDialog,
    private readonly carsService: CarsService,
    private readonly alertService: AlertService
  ) {}

  public deleteItem(id: string, template_id: string) {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'information',
        onDelete: () => {
          this.onDelete(id, template_id);
        },
      },
      maxWidth: '590px',
      width: '100%',
    });
  }

  public selectEditItem(item: Omit<ItemTemplate, 'template_id'>) {
    this.setEditItem.emit(item);
  }

  public onDelete(id: string, template_id: string) {
    this.carsService.deleteManyItemsTemplate([id], template_id).subscribe({
      complete: () => {
        this.alertService.alert({
          type: 'success',
          message: 'Item successfully deleted',
        });
      },
      error: (e) =>
        this.alertService.alert({ type: 'error', message: e.message }),
    });
  }
}
