import { NgModule } from '@angular/core';
import { LogsService } from './logs.service';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { LogsComponent } from './component/logs.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HelperService } from 'src/app/modules/helper/helper.service';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { LogsTableComponent } from './component/table/table.component';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    MatTableModule,
    DirectiveModule,
    MatCheckboxModule,
    LoadingModule,
    RouterModule.forChild([
      {
        path: 'logs',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            title: 'Logs | SLK CRM',
            component: LogsComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.logs,
        },
      },
    ]),
  ],
  declarations: [LogsComponent, LogsTableComponent],
  providers: [LogsService, HelperService],
})
export class LogsModule {}
