import { Apollo } from 'apollo-angular';
import {
  ISystemSettingsResponse,
  GET_SYSTEM_SETTINGS,
} from './apollo/query/get-system-settings';
import {
  UPDATE_SYSTEM_SETTINGS,
  IUpdateSystemSettingsDto,
  IUpdateSystemSettingsResponse,
} from './apollo/mutation/update-system-settings';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Injectable()
export class SettingsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly alertService: AlertService
  ) { }

  public updateSettings(variables: IUpdateSystemSettingsDto) {
    this.apollo
      .mutate<IUpdateSystemSettingsResponse, IUpdateSystemSettingsDto>({
        mutation: UPDATE_SYSTEM_SETTINGS,
        variables,
      })
      .subscribe({
        complete: () => {
          this.alertService.alert({
            message: "Changes have been successfully saved!",
            type: 'success'
          });
        },
        error: (e) => {
          this.alertService.alert({
            message: e.message,
            type: 'error'
          });
        }
      });
  }

  public getSystemSettings() {
    return this.apollo
      .watchQuery<ISystemSettingsResponse, {}>({ query: GET_SYSTEM_SETTINGS })
      .valueChanges.pipe(map(({ data }) => data.getSystemSettings));
  }
}
