<ng-container [formGroup]="$any(controlContainer.control)">
  <div class="drop-down" [ngClass]="{ active: open }">
    <div class="title grey Lexend-16 medium pointer" (click)="toggleOpen()">
      <arrow-icon />
      {{ filters.title }}
    </div>
    <div class="list">
      <label
        *ngFor="let filter_name of filters.items"
        class="Lexend-14 regilar pointer"
      >
        <input type="checkbox" [formControlName]="filter_name" />
        {{ filter_name }}
      </label>
    </div>
  </div>
</ng-container>
