<ng-container *ngFor="let group of form" [ngSwitch]="getTypeByFrom(group)">
  <app-main-block
    [data]="getValuesOfFrom(group)"
    *ngSwitchCase="form_templates.MAIN_BLOCK"
  />
  <app-block-with-image
    [data]="getValuesOfFrom(group)"
    *ngSwitchCase="form_templates.BLOCK_WITH_IMAGE_LEFT"
  />
  <app-prices-block
    [data]="getValuesOfFrom(group)"
    *ngSwitchCase="form_templates.PRICES_BLOCK"
  />
  <app-you-may-also-like
    [data]="getValuesOfFrom(group)"
    *ngSwitchCase="form_templates.YOU_MAY_ALSO_LIKE"
  />

  <!--====================== location ====================== -->
  <app-about-city
    *ngSwitchCase="form_templates.ABOUT_CITY"
    [data]="getValuesOfFrom(group)"
  />
  <app-about-us
    *ngSwitchCase="form_templates.ABOUT_US"
    [data]="getValuesOfFrom(group)"
  />
  <app-hour-twenty-four-service
    *ngSwitchCase="form_templates.TWENTY_FOUR_HOUR"
    [data]="getValuesOfFrom(group)"
  />
  <app-our-services
    *ngSwitchCase="form_templates.OUR_SERVICES"
    [data]="getValuesOfFrom(group)"
  />
  <app-testimonials
    *ngSwitchCase="form_templates.TESTIMONIALS"
    [data]="getValuesOfFrom(group)"
  />
</ng-container>
