import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'logo-loading',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoLoadingComponent {
  @Input() loading: boolean = false;
}
