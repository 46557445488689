import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Observable, map } from 'rxjs';
import { UserWorkiz } from 'src/app/entity/workiz-user.entity';
import {
  UserRole,
  reversed_roles,
  roles,
  workiz_user_role,
} from 'src/app/enum/user-role.enum';
import { conditionalValidator } from 'src/app/modules/helper/helper.service';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import { ManageUsersService } from '../../manage-users.service';
import { CreateUserService } from './factory/craete-user.service';
import { InviteUserAbstractService } from './factory/invite-user.service';
import { UpdateUserService } from './factory/update-user.service';
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
  providers: [
    {
      provide: InviteUserAbstractService,
      useFactory: (manageUsersService: ManageUsersService, alertService: AlertService, dialog: MatDialog) => {
        return manageUsersService.getEditUser ?
          new UpdateUserService(manageUsersService, alertService) :
          new CreateUserService(manageUsersService, alertService, dialog);
      },
      deps: [ManageUsersService, AlertService, MatDialog]
    }
  ]
})
export class InviteUserComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter<void>();

  public filteredWorkizUsers: Observable<UserWorkiz[]>;

  public form: FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl<keyof typeof roles | ''>('', [Validators.required]),
    workiz_id: new FormControl(''),
    location: new FormControl('', [conditionalValidator(
      () => this.openStateList,
      Validators.required,
      'required'
    )]),
  });

  constructor(
    private readonly manageUsersService: ManageUsersService,
    private readonly inviteUserService: InviteUserAbstractService,
  ) { }

  get editUser() {
    return this.manageUsersService.getEditUser;
  }

  get openStateList(): boolean {
    const role = this.form.value.role as keyof typeof roles;
    return [UserRole.DISPATCHER, UserRole.MAIN_DISPATCHER, UserRole.TECHNICIAN].includes(roles[role]);
  }

  get email() {
    return this.form.get('email')!;
  }

  get role() {
    return this.form.get('role')!;
  }

  get loading() {
    return this.inviteUserService.loading;
  }

  ngOnInit(): void {
    if (this.editUser) {
      this.form.patchValue({ ...this.editUser, role: reversed_roles[this.editUser.role] })
      this.form.removeControl('email');
    } else {
      this.manageUsersService.getAllUsersWorkiz().subscribe({
        next: (r) => {
          this.filteredWorkizUsers = this.form
            .get('email')!
            .valueChanges.pipe(
              map((value) =>
                this._filterWorkizUsers(value || '', r.getAllUsersWorkiz)
              )
            );
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.manageUsersService.setEditUser = undefined;
  }

  public onRoleChange(e: MatSelectChange): void {
    e.source.close();
    this.form.patchValue({ location: '' });
  }

  public onAutocompleteChange(workiz_user: UserWorkiz): void {
    this.form.patchValue({
      name: workiz_user.name,
      workiz_id: workiz_user.id,
      email: workiz_user.email,
      role: reversed_roles[workiz_user_role[workiz_user.role as keyof typeof workiz_user_role]],
      location: '',
    });
  }

  public onSubmit() {
    const role = roles[this.form.value.role as keyof typeof roles];
    this.inviteUserService.onSubmit({ ...this.form.value, id: this.editUser?.id, role }).subscribe({
      complete: () => {
        this.onCompleted();
      },
    })
  }

  private onCompleted() {
    this.onClose.emit();
    this.form.reset();
  }

  private _filterWorkizUsers(value: string, users: UserWorkiz[]): UserWorkiz[] {
    const filterValue = value.toLowerCase();

    return users.filter((option) => option.email.toLowerCase().includes(filterValue));
  }
}
