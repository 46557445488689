import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

interface MainBlockData {
  headline: string;
  description: string;
  photo: {
    file: File;
    file_id: string;
  }
}

@Component({
  selector: 'app-main-block',
  templateUrl: './main-block.component.html',
  styleUrls: ['./main-block.component.scss']
})
export class MainBlockComponent {
  @Input() data: MainBlockData;

  get description() {
    return this.data.description?.split('\n') || [];
  }

}
