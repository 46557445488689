import { gql } from "apollo-angular";
import { CarTemplate } from "src/app/entity/car_template.entity";

export const ASSIGN_MANY_TEMPLATES_TO_CAR = gql`
mutation assignManyTemplateToCar ($createCarTemplateDto: [CreateCarTemplateDto!]!) {
  assignManyTemplateToCar(createCarTemplateDto: $createCarTemplateDto) {
    id
    template_id
    workiz_id
  }
}`;

export interface IAssignManyTemplatesToCarDto {
  createCarTemplateDto: Omit<CarTemplate, 'id'>[];
}

export interface IAssignManyTemplatesToCarResponse {
  assignManyTemplateToCar: CarTemplate[];
}
