<div class="head">
  <h1 class="darck-blue">Chat</h1>

  <parse-data />
</div>

<mat-tab-group animationDuration="0">
  <mat-tab label="Chat">
    <chat />
  </mat-tab>
  <mat-tab label="Current database example">
    <database-table />
  </mat-tab>
</mat-tab-group>
