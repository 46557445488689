import { gql } from "apollo-angular";
import { ItemTemplate } from "src/app/entity/item_template.entity";
import { ConfirmInventoryTabs } from "../../component/confirm-inventory/confirm-inventory.component";

export const FIND_UPDATE_CAR_REQUESTS = gql`
  query findUpdateCarRequests {
    findUpdateCarRequests {
      id
      item_template_id
      approved_at
      approved_quantity
      requested_at
      requested_quantity
      submitted_at
      submitted_quantity
      tab @client
      item_template {
        id
        sku
        template_id
        uhs_sku
      }
    }
  }
`
export interface IFormatUpdateCarRequest {
  [key: number]: IFindUpdateCarRequests[]
}
export interface IFindUpdateCarRequests {
  id: string;
  item_template_id: string;
  approved_at: number;
  requested_at: number;
  submitted_at: number;
  requested_quantity: number;
  submitted_quantity: number;
  approved_quantity: number;
  item_template: ItemTemplate;
  tab: ConfirmInventoryTabs;
}

export interface IFindUpdateCarRequestsResponse {
  findUpdateCarRequests: IFindUpdateCarRequests[];
}
