import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { LoadingModule } from 'src/app/modules/loading/loading.module';
import { CompanyFormModule } from '../../../common/company-form/company-form.module';
import { RequestLocksmithComponent } from './component/request-locksmith.component';
import { RequestLocksmithService } from './request-locksmith.service';

@NgModule({
  imports: [
    LoadingModule,
    CommonModule,
    CompanyFormModule.inversion(RequestLocksmithService),
    RouterModule.forChild([
      {
        path: 'slk-admin/request-locksmith/:locksmith-id',
        loadChildren: () =>
          import('./request-locksmith.module').then(m => m.RequestLocksmithModule),
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: RequestLocksmithComponent,
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [RequestLocksmithComponent],
  providers: [RequestLocksmithService],
})
export class RequestLocksmithModule { }
