<div class="wrap">
  <div class="title">
    <p class="Lexend-18">Choose block for blog</p>
    <div (click)="close()" class="cross">
      <cross-icon />
    </div>
  </div>
  <div class="blocks_wrap">
    <img
      *ngFor="let block of blocks"
      [src]="block.src"
      alt="block"
      class="img"
      (click)="createBlock(block)"
    />
  </div>
</div>
