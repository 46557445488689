import { gql } from "apollo-angular";
import { Template } from "src/app/entity/template.entity";

export const UPDATE_TEMPLATE = gql`
mutation updateTemplate ($updateTemplateDto: UpdateTemplateDto!) {
  updateTemplate(updateTemplateDto: $updateTemplateDto) {
    car_templates {
      id
      template_id
      workiz_id
    }
    id
    items {
      id
      quantity
      sku
      template_id
      uhs_sku
    }
    name
  }
}
`

export interface IUpdateTemplatesResponse {
  updateTemplate: Template;
}

export interface IUpdateTemplatesDto {
  updateTemplateDto: Pick<Template, 'name' | 'id'>;
}
