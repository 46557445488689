import { gql } from "apollo-angular";
import { IFindUpdateCarRequests } from "./find-update-car-requests.query";

export const FIND_SUBMITTED_UPDATE_CAR_REQUESTS = gql`
  query findSubmittedUpdateCarRequests {
    findSubmittedUpdateCarRequests {
      id
      item_template_id
      approved_at
      approved_quantity
      requested_at
      requested_quantity
      submitted_at
      submitted_quantity

      item_template {
        id
        sku
        template_id
        uhs_sku
      }
    }
  }
`

export interface IFindSubmittedUpdateCarRequestsResponse {
  findSubmittedUpdateCarRequests: IFindUpdateCarRequests[];
}

