import { Observable } from "rxjs";
import { UserWithSchedule } from "src/app/entity/schedule/user-with-schedules.entity";
import { TimeTemplate } from "src/app/entity/time-template.entity";
import { UserRole } from "src/app/enum/user-role.enum";
import { IApproveUserScheduleRequestDto } from "../../apollo/mutation/approve-user-schedule-request.mutation";
import { ICreateOrUpdateManyUsersSchedulesDto } from "../../apollo/mutation/create-or-update-many-users-schedules.mutation";
import { ICreateTimeTemplateDto } from "../../apollo/mutation/create-time-template.mutation";
import { IDeclineUserScheduleRequestDto } from "../../apollo/mutation/decline-user-schedule-request.mutation";

export abstract class DayAbstractService {
  public abstract startWeek: moment.Moment;
  public abstract get acceptable_edit_roles(): UserRole[];

  public abstract formatDate(time: string, dayIndex: number): number;

  public abstract createOrUpdateManyUserSchedules(user_schedules: ICreateOrUpdateManyUsersSchedulesDto['user_schedules'], user: UserWithSchedule): void;

  public abstract deleteManyUserSchedules(ids: string[], user: UserWithSchedule): void;

  public abstract getAllTimeTemplates(): Observable<TimeTemplate[]>;

  public abstract updateTimeTemplate?(time_template: TimeTemplate): void;
  public abstract deleteTimeTemplate?(id: string): void
  public abstract createTimeTemplate?(time_template: ICreateTimeTemplateDto['time_template']): void;

  public abstract approveUserScheduleRequest?(request_ids: IApproveUserScheduleRequestDto['request_ids']): void
  public abstract declineUserScheduleRequest?(request_ids: IDeclineUserScheduleRequestDto['request_ids']): void
}
