import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';

@Component({
  selector: 'services-chips',
  templateUrl: './services-chips.component.html',
  styleUrls: ['./services-chips.component.css'],
})
export class ServiceChipsComponent {
  @Input() form: FormGroup;

  options = [
    'Mobile',
    'Automotive',
    'Car key duplication',
    'Security',
    'Commercial',
    'Safes',
    'Emergency',
    'Residental',
  ];

  constructor(public readonly controlContainer: ControlContainer) {}

  addService(service: string, services: FormArray) {
    const new_service = new FormControl(service, []);
    services.push(new_service);
  }
  checkSelected(service: string) {
    return this.form.get('services')?.value.includes(service);
  }

  removeService(service: string, services: FormArray) {
    const index = services.value.findIndex((item: string) => item === service);
    services.removeAt(index);
  }

  toggleService(service: string) {
    const services = <FormArray>this.form.get('services');
    if (services.value.includes(service)) {
      this.removeService(service, services);
    } else {
      this.addService(service, services);
    }
  }
}
